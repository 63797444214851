import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, platformAdministratorsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    platformAdministrators: [],
  },
  getters: {
  },
  mutations: {
    setPlatformAdministratorData(state, platformAdministratorsData) {
      state.platformAdministrators = platformAdministratorsData?.sort((pa1, pa2) => (pa1.UserDisplayName > pa2.UserDisplayName ? 1 : -1)) ?? [];
    },
    _addPlatformAdministrator(state, platformAdministrator) {
      const platformAdministratorsData = _.cloneDeep(state.platformAdministrators) ?? [];
      platformAdministratorsData.push(platformAdministrator);
      state.platformAdministrators = platformAdministratorsData.sort((pa1, pa2) => (pa1.UserDisplayName > pa2.UserDisplayName ? 1 : -1)) ?? [];
    },
    _updatePlatformAdministrator(state, updatePlatformAdministrator) {
      const id = Number(updatePlatformAdministrator?.Id ?? 0);
      if (id > 0) {
        let platformAdministratorsData = _.cloneDeep(state.platformAdministrators) ?? [];
        platformAdministratorsData = [...platformAdministratorsData.filter((platformAdministrator) => Number(platformAdministrator?.Id ?? 0) !== id), _.cloneDeep(updatePlatformAdministrator)] ?? [];
        state.platformAdministrators = platformAdministratorsData.sort((pa1, pa2) => (pa1.UserDisplayName > pa2.UserDisplayName ? 1 : -1)) ?? [];
      }
    },
    _removePlatformAdministrator(state, deletePlatformAdministrator) {
      const id = Number(deletePlatformAdministrator?.Id ?? 0);
      if (id > 0) {
        let platformAdministratorsData = _.cloneDeep(state.platformAdministrators) ?? [];
        platformAdministratorsData = [...platformAdministratorsData.filter((platformAdministrator) => Number(platformAdministrator?.Id ?? 0) !== id)] ?? [];
        state.platformAdministrators = platformAdministratorsData.sort((pa1, pa2) => (pa1.UserDisplayName > pa2.UserDisplayName ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getPlatformAdministrators(context) {
      return timeOperation(async () => {
        const formattedUrl = `${platformAdministratorsUrl}?userrootid=0&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setPlatformAdministratorData", data);
        return data;
      }, "platformAdministrators - getPlatformAdministrators");
    },
    async addPlatformAdministrator(context, platformAdministrator) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: platformAdministratorsUrl, Payload: platformAdministrator });
        if (data?.Id > 0) {
          platformAdministrator.Id = data.Id;
          context.commit("_addPlatformAdministrator", data);
        }
        return data;
      }, "platformAdministrators - addPlatformAdministrator");
    },
    async updatePlatformAdministrator(context, platformAdministrator) {
      return timeOperation(async () => {
        const formattedUrl = `${platformAdministratorsUrl}/${Number(platformAdministrator?.Id ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: platformAdministrator});
        context.commit("_updatePlatformAdministrator", data);
        return data;
      }, "platformAdministrators - updatePlatformAdministrator");
    },
    async removePlatformAdministrator(context, platformAdministrator) {
      return timeOperation(async () => {
        const formattedUrl = `${platformAdministratorsUrl}/${Number(platformAdministrator?.Id ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removePlatformAdministrator", platformAdministrator);
        return data
      }, "platformAdministrators - removePlatformAdministrator");
    },
  },
};
