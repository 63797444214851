import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationDataSourceFieldsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDataSourceFieldDataSourceFields: {},
  },
  getters: {
    getOrganizationDataSourceFieldByRootId: (state) => (rootId) => {
      const organizationDataSourceFields = _.reduce(
        state.organizationDataSourceFieldDataSourceFields,
        (result, listOfOrganizationDataSourceFields) => {
          if (listOfOrganizationDataSourceFields?.length > 0) {
            listOfOrganizationDataSourceFields.forEach((organizationDataSourceField) => {
              result.push(organizationDataSourceField);
            });
          }
          return result;
        },
        []
      );
      const organizationDataSourceField =
        organizationDataSourceFields.find((organizationDataSourceField) => Number(organizationDataSourceField?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
      return organizationDataSourceField?.RootId > 0 ? _.cloneDeep(organizationDataSourceField) : null;
    },
    getOrganizationDataSourceFieldsCopy:
      (state) =>
      ({ OrganizationDataSourceRootId, OrganizationRootId }) => {
        OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        if (state.organizationDataSourceFieldDataSourceFields.hasOwnProperty(OrganizationDataSourceRootId)) {
          return (
            _.cloneDeep(state.organizationDataSourceFieldDataSourceFields[OrganizationDataSourceRootId])?.filter((organizationDataSourceField) => {
              return (
                Number(organizationDataSourceField?.OrganizationDataSourceRootId ?? 0) === OrganizationDataSourceRootId &&
                Number(organizationDataSourceField?.OrganizationRootId ?? 0) === OrganizationRootId
              );
            }) ?? []
          );
        }
        return [];
      },
  },
  mutations: {
    setOrganizationDataSourceFields(state, { OrganizationDataSourceRootId, Data }) {
      state.organizationDataSourceFieldDataSourceFields[OrganizationDataSourceRootId] = Data?.sort((odsf1, odsf2) => (odsf1.Name > odsf2.Name ? 1 : -1)) ?? [];
    },
    clearOrganizationDataSourceFieldDataSourceFields(state) {
      state.organizationDataSourceFieldDataSourceFields = {};
    },
    _addOrganizationDataSourceField(state, organizationDataSourceField) {
      if (organizationDataSourceField?.RootId > 0) {
        const organizationDataSourceRootId = Number(organizationDataSourceField?.OrganizationDataSourceRootId ?? 0);
        if (state.organizationDataSourceFieldDataSourceFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationDataSourceFieldsData = _.cloneDeep(state.organizationDataSourceFieldDataSourceFields[organizationDataSourceRootId]) ?? [];
          organizationDataSourceFieldsData.push(organizationDataSourceField);
          organizationDataSourceFieldsData = organizationDataSourceFieldsData.sort((odsf1, odsf2) => (odsf1.Name > odsf2.Name ? 1 : -1)) ?? [];
          state.organizationDataSourceFieldDataSourceFields[organizationDataSourceRootId] = organizationDataSourceFieldsData;
        } else {
          state.organizationDataSourceFieldDataSourceFields[organizationDataSourceRootId] = [organizationDataSourceField];
        }
      }
    },
    _updateOrganizationDataSourceField(state, updateOrganizationDataSourceField) {
      const rootId = Number(updateOrganizationDataSourceField?.RootId ?? 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationDataSourceField?.OrganizationDataSourceRootId ?? 0);
        if (state.organizationDataSourceFieldDataSourceFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationDataSourceFieldsData = _.cloneDeep(state.organizationDataSourceFieldDataSourceFields[organizationDataSourceRootId]) ?? [];
          organizationDataSourceFieldsData =
            [
              ...organizationDataSourceFieldsData.filter((organizationDataSourceField) => Number(organizationDataSourceField?.RootId ?? 0) !== rootId),
              _.cloneDeep(updateOrganizationDataSourceField),
            ] ?? [];
          state.organizationDataSourceFieldDataSourceFields[organizationDataSourceRootId] =
            (
              _.map(organizationDataSourceFieldsData, (organizationDataSourceField) => {
                return { ...organizationDataSourceField, CanEdit: Number(organizationDataSourceField.OrganizationDataSourceRootId ?? 0) < 1 };
              }) ?? []
            ).sort((odsf1, odsf2) => (odsf1.Name > odsf2.Name ? 1 : -1)) ?? [];
        } else {
          state.organizationDataSourceFieldDataSourceFields[organizationDataSourceRootId] = [updateOrganizationDataSourceField];
        }
      }
    },
    _removeOrganizationDataSourceField(state, deleteOrganizationDataSourceField) {
      const rootId = Number(deleteOrganizationDataSourceField?.RootId ?? 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(deleteOrganizationDataSourceField?.OrganizationDataSourceRootId ?? 0);
        if (state.organizationDataSourceFieldDataSourceFields.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationDataSourceFieldsData = _.cloneDeep(state.organizationDataSourceFieldDataSourceFields[organizationDataSourceRootId]) ?? [];
          organizationDataSourceFieldsData =
            [...organizationDataSourceFieldsData.filter((organizationDataSourceField) => Number(organizationDataSourceField?.RootId ?? 0) !== rootId)] ?? [];
          state.organizationDataSourceFieldDataSourceFields[organizationDataSourceRootId] =
            (
              _.map(organizationDataSourceFieldsData, (organizationDataSourceField) => {
                return { ...organizationDataSourceField, CanEdit: Number(organizationDataSourceField.OrganizationDataSourceRootId ?? 0) < 1 };
              }) ?? []
            ).sort((odsf1, odsf2) => (odsf1.Name > odsf2.Name ? 1 : -1)) ?? [];
        }
      }
    },
  },
  actions: {
    async getOrganizationDataSourceFields(context, { ProductRootId, OrganizationRootId, OrganizationDataSourceRootId, UseCache, ValidateRecords, CanUseServerCache }) {
      UseCache = UseCache ?? false;
      ValidateRecords = ValidateRecords ?? false;
      ProductRootId = Number(ProductRootId ?? 0);
      OrganizationRootId = Number(OrganizationRootId ?? 0);
      OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
      CanUseServerCache = CanUseServerCache ?? true;
      const isCached = UseCache && context.state.organizationDataSourceFieldDataSourceFields?.hasOwnProperty(OrganizationDataSourceRootId);
      const hasOrganizationsDataSourceFields =
        isCached &&
        context.state.organizationDataSourceFieldDataSourceFields[OrganizationDataSourceRootId]?.find(
          (organizationDataSourceField) => Number(organizationDataSourceField?.OrganizationRootId ?? 0) === Number(OrganizationRootId ?? 0)
        );
      if (!hasOrganizationsDataSourceFields) {
        return timeOperation(async () => {
          context.commit("setOrganizationDataSourceFields", {OrganizationDataSourceRootId, Data: []});
          const formattedUrl = `${organizationDataSourceFieldsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&validaterecords=${ValidateRecords}&canusecache=${CanUseServerCache}&includemetadata=true`;
          const Data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
          context.commit("setOrganizationDataSourceFields", {OrganizationDataSourceRootId, Data});
          return Data;
        }, "organizationDataSourceFields - getOrganizationDataSourceFields");
      }
    },
    async addOrganizationDataSourceField(context, organizationDataSourceField) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDataSourceFieldsUrl, Payload: organizationDataSourceField });
        if (data?.RootId > 0) {
          organizationDataSourceField.RootId = data.RootId;
          context.commit("_addOrganizationDataSourceField", data);
        }
        return data;
      }, "organizationDataSourceFields - addOrganizationDataSourceField");
    },
    async updateOrganizationDataSourceField(context, organizationDataSourceField) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationDataSourceFieldsUrl}/${Number(organizationDataSourceField?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDataSourceField });
        context.commit("_updateOrganizationDataSourceField", data);
        return data;
      }, "organizationDataSourceFields - updateOrganizationDataSourceField");
    },
    async enableOrganizationDataSourceField(context, organizationDataSourceField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourceFieldsUrl}/${Number(organizationDataSourceField?.RootId ?? 0)}/updateenabledstatus`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationDataSourceField, Enabled: true } });
      }, "organizationDataSourceFields - enableOrganizationDataSourceField");
    },
    async disableOrganizationDataSourceField(context, organizationDataSourceField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourceFieldsUrl}/${Number(organizationDataSourceField?.RootId ?? 0)}/updateenabledstatus`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationDataSourceField, Enabled: false } });
      }, "organizationDataSourceFields - disableOrganizationDataSourceField");
    },
    async removeOrganizationDataSourceField(context, organizationDataSourceField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourceFieldsUrl}/${Number(organizationDataSourceField?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationDataSourceField", organizationDataSourceField);
      }, "organizationDataSourceFields - removeOrganizationDataSourceField");
    },
  },
};
