import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, groupMembersUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    groupMembers: [],
  },
  getters: {
  },
  mutations: {
    setGroupMemberData(state, groupMembersData) {
      state.groupMembers = groupMembersData?.sort((gm1, gm2) => (gm1.GroupName > gm2.GroupName ? 1 : -1)) ?? [];
    },
    _addGroupMember(state, groupMember) {
      const groupMembersData = _.cloneDeep(state.groupMembers) ?? [];
      groupMembersData.push(groupMember);
      state.groupMembers = groupMembersData.sort((gm1, gm2) => (gm1.GroupName > gm2.GroupName ? 1 : -1)) ?? [];
    },
    _updateGroupMember(state, updateGroupMember) {
      const rootId = Number(updateGroupMember?.RootId ?? 0);
      if (rootId > 0) {
        let groupMembersData = _.cloneDeep(state.groupMembers) ?? [];
        groupMembersData = [...groupMembersData.filter((groupMember) => Number(groupMember?.RootId ?? 0) !== rootId), _.cloneDeep(updateGroupMember)] ?? [];
        state.groupMembers = groupMembersData.sort((gm1, gm2) => (gm1.GroupName > gm2.GroupName ? 1 : -1)) ?? [];
      }
    },
    _removeGroupMember(state, deleteGroupMember) {
      const rootId = Number(deleteGroupMember?.RootId ?? 0);
      if (rootId > 0) {
        let groupMembersData = _.cloneDeep(state.groupMembers) ?? [];
        groupMembersData = [...groupMembersData.filter((groupMember) => Number(groupMember?.RootId ?? 0) !== rootId)] ?? [];
        state.groupMembers = groupMembersData.sort((gm1, gm2) => (gm1.GroupName > gm2.GroupName ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getGroupMembers(context, { OrganizationRootId, GroupRootId, UserRootId, MemberType, IncludePossible, IncludeMetadata }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        GroupRootId = Number(GroupRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        MemberType = Number(MemberType ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        context.commit("setGroupMemberData", []);
        const formattedUrl = `${groupMembersUrl}?organizationrootid=${OrganizationRootId}&grouprootid=${GroupRootId}&userrootid=${UserRootId}&membertype=${MemberType}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setGroupMemberData", data);
        return data;
      }, "groupMembers - getGroupMembers");
    },
    async addGroupMember(context, groupMember) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: groupMembersUrl, Payload: groupMember });
        if (data?.RootId > 0) {
          groupMember.RootId = data.RootId;
          context.commit("_addGroupMember", data);
        }
        return data;
      }, "groupMembers - addGroupMember");
    },
    async updateGroupMember(context, groupMember) {
      return timeOperation(async () => {
        const formattedUrl = `${groupMembersUrl}/${Number(groupMember?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupMember});
        context.commit("_updateGroupMember", data);
        return data;
      }, "groupMembers - updateGroupMember");
    },
    async removeGroupMember(context, groupMember) {
      return timeOperation(async () => {
        const formattedUrl = `${groupMembersUrl}/${Number(groupMember?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeGroupMember", groupMember);
        return data
      }, "groupMembers - removeGroupMember");
    },
  },
};
