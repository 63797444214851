// noinspection UnnecessaryLocalVariableJS
import { ApiRequest, GetVerb, PostVerb, formEntriesUrl } from "@/lib/api";
import _ from "lodash";

import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { FORM_DATA_VALUE_KEY, FORM_DATA_FORM_LAYOUT_ROOT_ID_KEY, FORM_DATA_FORM_LAYOUT_CONTROL_ROOT_ID_KEY } from "@/types/formDataTypes";
import { SELECTED_DATASOURCE_ITEMS } from "@/types/formLayoutConfigurationTypes";

// TODO: just use FORM_DATA_FORM_LAYOUT_CONTROL_ROOT_ID_KEY
export function getValuesFromKey(key) {
  const keys = key.split("_");
  const formLayoutRootId = keys.length > 0 ? keys[0] : null;
  const formLayoutControlRootId = keys.length > 1 ? keys[1] : null;
  return {
    [FORM_DATA_FORM_LAYOUT_ROOT_ID_KEY]: formLayoutRootId,
    [FORM_DATA_FORM_LAYOUT_CONTROL_ROOT_ID_KEY]: formLayoutControlRootId,
  };
}
export function getKeyForControl(formLayoutRootId, formLayoutControlRootId) {
  return `${formLayoutRootId}_${formLayoutControlRootId}`;
}
export function convertFromFormDataToTemplateData(formData) {
  const result = formData.reduce((result, formDataItem) => {
    const value = formDataItem[FORM_DATA_VALUE_KEY];
    const formLayoutRootId = formDataItem[FORM_DATA_FORM_LAYOUT_ROOT_ID_KEY];
    const formLayoutControlRootId = formDataItem[FORM_DATA_FORM_LAYOUT_CONTROL_ROOT_ID_KEY];

    if (!formLayoutRootId || !formLayoutControlRootId) {
      console.error("invalid form data");
      return result;
    }
    result[getKeyForControl(formLayoutRootId, formLayoutControlRootId)] = value;

    return result;
  }, {});
  return result;
}

export function convertFromTemplateDataToFormData(formLayoutRootId, templateData) {
  const keys = Object.keys(templateData);
  if (keys?.length < 1) {
    console.log("no template data");
    return [];
  }
  const formControlRootIdRegistry = [];
  const formData = [];
  keys.forEach((key) => {
    const foundComplexKey = key?.includes("_");
    if (key?.length > 0) {
      const value = templateData[key];
      let currentFormLayoutRootId = "";
      let currentFormLayoutControlRootId = "";
      if (foundComplexKey) {
        const keys = key.split("_");
        currentFormLayoutRootId = keys.length > 0 ? keys[0] : null;
        currentFormLayoutControlRootId = keys.length > 1 ? keys[1] : null;
      } else {
        currentFormLayoutRootId = String(formLayoutRootId ?? "");
        currentFormLayoutControlRootId = String(key ?? "");
      }
      if (currentFormLayoutRootId?.length > 0 && currentFormLayoutControlRootId?.length > 0) {
        const controlFormLayoutRootId = Number(currentFormLayoutRootId ?? 0);
        const controlFormLayoutControlRootId = Number(currentFormLayoutControlRootId ?? 0);
        if (controlFormLayoutRootId > 0 && controlFormLayoutControlRootId > 0) {
          let updated = false;
          if (foundComplexKey && formControlRootIdRegistry.includes(controlFormLayoutControlRootId)) {
            let foundFormData = formData.find(
              (formDataRecord) =>
                Number(formDataRecord[FORM_DATA_FORM_LAYOUT_ROOT_ID_KEY] ?? 0) === Number(controlFormLayoutRootId) &&
                Number(formDataRecord[FORM_DATA_FORM_LAYOUT_CONTROL_ROOT_ID_KEY] ?? 0) === Number(controlFormLayoutControlRootId)
            );
            if (foundFormData != null) {
              foundFormData[FORM_DATA_VALUE_KEY] = value;
              updated = true;
            }
          }
          if (!updated) {
            formControlRootIdRegistry.push(controlFormLayoutControlRootId);
            formData.push({
              [FORM_DATA_FORM_LAYOUT_ROOT_ID_KEY]: controlFormLayoutRootId,
              [FORM_DATA_FORM_LAYOUT_CONTROL_ROOT_ID_KEY]: controlFormLayoutControlRootId,
              [FORM_DATA_VALUE_KEY]: value,
            });
          }
        }
      }
    }
  });
  return formData;
}

export function getSelectedFormContextRecordEntries(configurationData) {
  let result =
    _.map(configurationData?.[SELECTED_DATASOURCE_ITEMS], (item, key) => {
      return { OrganizationDataSourceRootId: Number(key), RecordRootId: item?.RootId };
    }) ?? [];
  return result;
}

export default {
  state: {
    relatedData: "",
  },
  getters: {},
  mutations: {
    setRelatedData(state, data) {
      state.relatedData = data?.RelatedData ?? "";
      // console.log(`Log: ${data?.Log ?? ""}`);
    },
    clearRelatedData(state) {
      state.relatedData = "";
    },
  },
  actions: {
    async getFormData(context, { FormLayoutData, IncludeDebugData }) {
      return timeOperation(async () => {
        IncludeDebugData = IncludeDebugData ?? false;
        const formLayoutDataJson = FormLayoutData?.length > 0 ? JSON.stringify(FormLayoutData) : "";
        const formattedUrl = `${formEntriesUrl}?timezone=${encodeURIComponent(getLocalTimezone())}&includedebugdata=${IncludeDebugData}&filters=${formLayoutDataJson}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (IncludeDebugData) {
          context.commit("setRelatedData", data);
        } else {
          context.commit("clearRelatedData");
        }
        return data;
      }, "formData - getFormData");
    },
    async saveFormData(context, updateFormData) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formEntriesUrl, Payload: updateFormData });
      }, "formData - saveFormData");
    },
    async updateBooleanField(
      context,
      {
        ProductDataSourceRootId,
        StatusProductDataSourceFieldRootId,
        StatusUpdatedDateTimeProductDataSourceFieldRootId,
        StatusUpdatedByUserRootIdProductDataSourceFieldRootId,
        OrganizationDataSourceRootId,
        StatusOrganizationDataSourceFieldRootId,
        StatusUpdatedDateTimeOrganizationDataSourceFieldRootId,
        StatusUpdatedByUserRootIdOrganizationDataSourceFieldRootId,
        RecordRootId,
        Status,
      }
    ) {
      return timeOperation(async () => {
        (ProductDataSourceRootId = Number(ProductDataSourceRootId ?? 0)),
          (StatusProductDataSourceFieldRootId = Number(StatusProductDataSourceFieldRootId ?? 0)),
          (StatusUpdatedDateTimeProductDataSourceFieldRootId = Number(StatusUpdatedDateTimeProductDataSourceFieldRootId ?? 0)),
          (StatusUpdatedByUserRootIdProductDataSourceFieldRootId = Number(StatusUpdatedByUserRootIdProductDataSourceFieldRootId ?? 0)),
          (OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0)),
          (StatusOrganizationDataSourceFieldRootId = Number(StatusOrganizationDataSourceFieldRootId ?? 0)),
          (StatusUpdatedDateTimeOrganizationDataSourceFieldRootId = Number(StatusUpdatedDateTimeOrganizationDataSourceFieldRootId ?? 0)),
          (StatusUpdatedByUserRootIdOrganizationDataSourceFieldRootId = Number(StatusUpdatedByUserRootIdOrganizationDataSourceFieldRootId ?? 0)),
          (RecordRootId = Number(RecordRootId ?? 0)),
          (Status = Status ?? false);
        const formattedUrl = `${formEntriesUrl}/updatestatus?timezone=${encodeURIComponent(getLocalTimezone())}`;
        return await context.dispatch(ApiRequest, {
          Verb: PostVerb,
          FormattedUrl: formattedUrl,
          Payload: {
            ProductDataSourceRootId,
            StatusProductDataSourceFieldRootId,
            StatusUpdatedDateTimeProductDataSourceFieldRootId,
            StatusUpdatedByUserRootIdProductDataSourceFieldRootId,
            OrganizationDataSourceRootId,
            StatusOrganizationDataSourceFieldRootId,
            StatusUpdatedDateTimeOrganizationDataSourceFieldRootId,
            StatusUpdatedByUserRootIdOrganizationDataSourceFieldRootId,
            RecordRootId,
            Status,
          },
        });
      }, "formData - updateBooleanField");
    },
    async getTemporaryOrder(context, { FormLayoutRootId }) {
      return timeOperation(async () => {
        // FIXME: add the correct endpoint once created
        let implemented = false;
        if (implemented) {
          FormLayoutRootId = Number(FormLayoutRootId ?? 0);
          const formattedUrl = `${formEntriesUrl}/${FormLayoutRootId}/createorder?timezone=${encodeURIComponent(getLocalTimezone())}`;
          const result = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl });
          return result;
        }
      }, "formData - cancelFormDataOrder");
    },
    async createFormDataOrder(context, { FormLayoutRootId, FormData }) {
      return timeOperation(async () => {
        FormLayoutRootId = Number(FormLayoutRootId ?? 0);
        const formattedUrl = `${formEntriesUrl}/${FormLayoutRootId}/createorder?timezone=${encodeURIComponent(getLocalTimezone())}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: FormData });
      }, "formData - createFormDataOrder");
    },
    async cancelFormDataOrder(context, { FormLayoutRootId, FormData }) {
      return timeOperation(async () => {
        FormLayoutRootId = Number(FormLayoutRootId ?? 0);
        const formattedUrl = `${formEntriesUrl}/${FormLayoutRootId}/cancelorder?timezone=${encodeURIComponent(getLocalTimezone())}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: FormData });
      }, "formData - cancelFormDataOrder");
    },
    async getConfirmCreateFormDataOrderDetails(context, { FormLayoutRootId, FormData }) {
      return timeOperation(async () => {
        FormLayoutRootId = Number(FormLayoutRootId ?? 0);
        const formattedUrl = `${formEntriesUrl}/${FormLayoutRootId}/createorderdetails?timezone=${encodeURIComponent(getLocalTimezone())}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: FormData });
      }, "formData - getConfirmCreateFormDataOrderDetails");
    },
    async placeFormDataServiceOrder(context, { FormLayoutRootId, FormData }) {
      return timeOperation(async () => {
        FormLayoutRootId = Number(FormLayoutRootId ?? 0);
        const formattedUrl = `${formEntriesUrl}/${FormLayoutRootId}/placeorder?timezone=${encodeURIComponent(getLocalTimezone())}`;
        const result = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: FormData });
        if (result?.hasOwnProperty("log")) {
          console.log(result["log"]);
        }
        return result;
      }, "formData - placeFormDataServiceOrder");
    },
    async getConfirmPlaceFormDataServiceOrderDetails(context, { FormLayoutRootId, FormData }) {
      return timeOperation(async () => {
        FormLayoutRootId = Number(FormLayoutRootId ?? 0);
        const formattedUrl = `${formEntriesUrl}/${FormLayoutRootId}/placeorderdetails?timezone=${encodeURIComponent(getLocalTimezone())}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: FormData });
      }, "formData - getConfirmPlaceFormDataServiceOrderDetails");
    },
    async deliverProductsFromForm(context, { FormLayoutRootId, FormData }) {
      return timeOperation(async () => {
        FormLayoutRootId = Number(FormLayoutRootId ?? 0);
        const formattedUrl = `${formEntriesUrl}/${FormLayoutRootId}/deliverproduct?timezone=${encodeURIComponent(getLocalTimezone())}`;
        const result = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: FormData });
        if (result?.hasOwnProperty("log")) {
          console.log(result["log"]);
        }
        return result;
      }, "formData - deliverProductsFromForm");
    },
    async getConfirmDeliverDocumentDetails(context, { FormLayoutRootId, FormData }) {
      return timeOperation(async () => {
        FormLayoutRootId = Number(FormLayoutRootId ?? 0);
        const formattedUrl = `${formEntriesUrl}/${FormLayoutRootId}/deliverproductdetails?timezone=${encodeURIComponent(getLocalTimezone())}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: FormData });
      }, "formData - getConfirmDeliverDocumentDetails");
    },
  },
};
