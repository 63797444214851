import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productCustomFieldsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDataSourceCustomFields: {},
  },
  getters: {
    getProductCustomFieldByRootId: (state) => (rootId) => {
      const productCustomFields = _.reduce(
        state.productDataSourceCustomFields,
        (result, listOfProductCustomFields) => {
          if (listOfProductCustomFields?.length > 0) {
            listOfProductCustomFields.forEach((productCustomField) => {
              result.push(productCustomField);
            });
          }
          return result;
        },
        []
      );
      const productCustomField = productCustomFields.find((productCustomField) => Number(productCustomField?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
      return productCustomField?.RootId > 0 ? _.cloneDeep(productCustomField) : null;
    },
  },
  mutations: {
    setProductDataSourceCustomFields(state, { ProductDataSourceRootId, Data }) {
      if (ProductDataSourceRootId > 0) {
        state.productDataSourceCustomFields[ProductDataSourceRootId] = Data?.sort((pcf1, pcf2) => (pcf1.CustomFieldIndex > pcf2.CustomFieldIndex ? 1 : -1)) ?? [];
      } else {
        let productDataSourceCustomFields = {};
        Data?.forEach((productCustomField) => {
          const productDataSourceRootId = Number(productCustomField?.ProductDataSourceRootId ?? 0);
          if (productDataSourceRootId > 0) {
            if (!productDataSourceCustomFields.hasOwnProperty(productDataSourceRootId)) {
              productDataSourceCustomFields[productDataSourceRootId] = [];
            }
            productDataSourceCustomFields[productDataSourceRootId].push(_.cloneDeep(productCustomField));
          }
        });
        state.productDataSourceCustomFields = productDataSourceCustomFields;
      }
    },
    clearProductDataSourceCustomFields(state) {
      state.productDataSourceCustomFields = {};
    },
    _addProductCustomField(state, productCustomField) {
      if (productCustomField?.RootId > 0) {
        const productDataSourceRootId = Number(productCustomField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomFields.hasOwnProperty(productDataSourceRootId)) {
          state.productDataSourceCustomFields[productDataSourceRootId].push(productCustomField);
          state.productDataSourceCustomFields[productDataSourceRootId] =
            state.productDataSourceCustomFields[productDataSourceRootId].sort((pcf1, pcf2) => (pcf1.CustomFieldIndex > pcf2.CustomFieldIndex ? 1 : -1)) ?? [];
        } else {
          state.productDataSourceCustomFields[productDataSourceRootId] = [productCustomField];
        }
      }
    },
    _updateProductCustomField(state, updateProductCustomField) {
      const rootId = Number(updateProductCustomField?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(updateProductCustomField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomFields.hasOwnProperty(productDataSourceRootId)) {
          let productCustomFieldsData = _.cloneDeep(state.productDataSourceCustomFields[productDataSourceRootId]) ?? [];
          productCustomFieldsData = [...productCustomFieldsData.filter((productCustomField) => Number(productCustomField?.RootId ?? 0) !== rootId), _.cloneDeep(updateProductCustomField)] ?? [];
          state.productDataSourceCustomFields[productDataSourceRootId] = (_.map(productCustomFieldsData, (productCustomField) => { return { ...productCustomField, CanEdit: Number(productCustomField.ProductCustomFieldRootId ?? 0) < 1 }}) ?? []).sort((pcf1, pcf2) => (pcf1.CustomFieldIndex > pcf2.CustomFieldIndex ? 1 : -1)) ?? [];
        } else {
          state.productDataSourceCustomFields[productDataSourceRootId] = [updateProductCustomField];
        }
      }
    },
    _updateProductCustomFieldEnabled(state, updateProductCustomField) {
      const rootId = Number(updateProductCustomField?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(updateProductCustomField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomFields.hasOwnProperty(productDataSourceRootId)) {
          let productCustomField = state.productDataSourceCustomFields[productDataSourceRootId]?.find((productCustomField) => Number(productCustomField?.RootId ?? 0) === rootId) ?? null;
          if (productCustomField?.RootId > 0) {
            productCustomField.Enabled = updateProductCustomField.Enabled;
          }
        }
      }
    },
    _removeProductCustomField(state, deleteProductCustomField) {
      const rootId = Number(deleteProductCustomField?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(deleteProductCustomField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomFields.hasOwnProperty(productDataSourceRootId)) {
          let productCustomFieldsData = _.cloneDeep(state.productDataSourceCustomFields[productDataSourceRootId]) ?? [];
          productCustomFieldsData = [...productCustomFieldsData.filter((productCustomField) => Number(productCustomField?.RootId ?? 0) !== rootId)] ?? [];
          state.productDataSourceCustomFields[productDataSourceRootId] =
            (
              _.map(productCustomFieldsData, (productCustomField) => {
                return { ...productCustomField, CanEdit: Number(productCustomField.ProductCustomFieldRootId ?? 0) < 1 };
              }) ?? []
            ).sort((pcf1, pcf2) => (pcf1.CustomFieldIndex > pcf2.CustomFieldIndex ? 1 : -1)) ?? [];
        }
      }
    },
  },
  actions: {
    async getProductCustomFields(context, { ProductRootId, ProductDataSourceRootId, UseCache, CanUseServerCache }) {
      CanUseServerCache = CanUseServerCache ?? true;
      const isCached = UseCache && context.state.productDataSourceCustomFields?.hasOwnProperty(ProductDataSourceRootId);
      const hasProductsCustomFields =
        isCached &&
        context.state.productDataSourceCustomFields[ProductDataSourceRootId]?.find(
          (productCustomField) => Number(productCustomField?.ProductRootId ?? 0) === Number(ProductRootId ?? 0)
        );
      if (!hasProductsCustomFields) {
        await timeOperation(async () => {
          context.commit("setProductDataSourceCustomFields", { ProductDataSourceRootId, Data: [] });
          const formattedUrl = `${productCustomFieldsUrl}?productrootid=${ProductRootId}&productdatasourcerootid=${ProductDataSourceRootId}&canusecache=${CanUseServerCache}&includemetadata=true`;
          const Data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setProductDataSourceCustomFields", { ProductDataSourceRootId, Data });
        }, "productCustomFields - getProductCustomFields");
      }
    },
    async addProductCustomField(context, productCustomField) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productCustomFieldsUrl, Payload: productCustomField });
        if (data?.RootId > 0) {
          productCustomField.RootId = data.RootId;
          context.commit("_addProductCustomField", data);
        }
        return data;
      }, "productCustomFields - addProductCustomField");
    },
    async updateProductCustomField(context, productCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldsUrl}/${Number(productCustomField?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productCustomField });
        context.commit("_updateProductCustomField", data);
      }, "productCustomFields - updateProductCustomField");
    },
    async enableProductCustomField(context, productCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldsUrl}/${Number(productCustomField?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...productCustomField, Enabled: true } });
        context.commit("_updateProductCustomFieldEnabled", data);
      }, "productCustomFields - enableProductCustomField");
    },
    async disableProductCustomField(context, productCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldsUrl}/${Number(productCustomField?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...productCustomField, Enabled: false } });
        context.commit("_updateProductCustomFieldEnabled", data);
      }, "productCustomFields - disableProductCustomField");
    },
    async removeProductCustomField(context, productCustomField) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldsUrl}/${Number(productCustomField?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductCustomField", productCustomField);
      }, "productCustomFields - removeProductCustomField");
    },
    async applyProductCustomField(context, appliedCustomField) {
      let res = null;
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldsUrl}/setcustomfield`;
        res = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: appliedCustomField });
      }, "productCustomFields - applyProductCustomField");
      return res?.Result ?? false;
    },
  },
};
