import {
  ApiRequest,
  GetVerb,
  PostVerb,
  formGroupsUrl,
} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    formGroups: [],
  },
  getters: {
    getFormGroupByName: (state) => (name) => {
      let result = null;
      const formGroup = state.formGroups.find((formGroup) => String(formGroup?.Name ?? "") === String(name ?? "")) ?? null;
      if (formGroup?.RootId > 0) {
        result = _.cloneDeep(formGroup);
      }
      return result;
    },
  },
  mutations: {
    setFormGroups(state, formGroups) {
      state.formGroups = formGroups;
    },
    _addFormGroup(state, formGroup) {
      state.formGroups.push(formGroup);
    },
  },
  actions: {
    async getFormGroups(context, { ProductRootId, OrganizationRootId, UserRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        const formattedUrl = `${formGroupsUrl}?productrootid=${Number(ProductRootId ?? 0)}&organizationrootid=${OrganizationRootId ?? 0}&userrootid=${UserRootId ?? 0}&includemetadata=${IncludeMetadata ?? true}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setFormGroups", data);
      }, "formGroups - getFormGroups");
    },
    async AddFormGroup(context, { Name, Position, ParentFormGroupRootId, FormLayoutRootId }) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formGroupsUrl, Payload: { Name, Position, ParentFormGroupRootId, FormLayoutRootId } });
        if (data?.RootId > 0) {
          context.commit("_addFormGroup", _.cloneDeep(data));
        }
      }, "formGroups - AddFormGroup");
    },
  },
};
