import {ApiRequest, GetVerb, PostVerb, groupsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    groupTemplate: null,
    selectedGroupGroupAccessProductRootId: 0,
  },
  mutations: {
    setGroupTemplateData(state, groupTemplate) {
      state.groupTemplate = groupTemplate;
    },
    setSelectedGroupGroupAccessProductRootId(state, productRootId) {
      state.selectedGroupGroupAccessProductRootId = Number(productRootId ?? 0);
    },
  },
  actions: {
    async getGroupTemplate(context, params) {
      await timeOperation(async () => {
        if (Number(params?.OrganizationRootId ?? 0) > 0) {
          const formattedUrl = `${groupsUrl
            }?organizationrootid=${Number(params?.OrganizationRootId ?? 0)
            }&template=true&includemetadata=${params?.IncludeMetadata ?? true
            }&includemembership=${params?.IncludeMembership ?? false
            }&includeaccesssettings=${params?.IncludeAccessSettings ?? false}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setGroupTemplateData", data);
        } else {
          context.commit("setGroupTemplateData", null);
        }
      }, "groups - getGroupTemplate");
    },
    async cloneGroup(context, {
      RootId,
      IncludeGroupMembership,
      IncludeDataSourceFieldAccessSetting,
      IncludeAccessSettings,
      IncludeSearches
    }) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        IncludeGroupMembership = IncludeGroupMembership ?? true;
        IncludeDataSourceFieldAccessSetting = IncludeDataSourceFieldAccessSetting ?? true;
        IncludeAccessSettings = IncludeAccessSettings ?? true;
        IncludeSearches = IncludeSearches ?? true;
        const formattedUrl = `${groupsUrl}/${RootId
        }/clone?includegroupmembership=${IncludeGroupMembership
        }&includedatasourcefieldaccesssetting=${IncludeDataSourceFieldAccessSetting
        }&includeaccesssettings=${IncludeAccessSettings
        }&includesearches=${IncludeSearches}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_addSelectedAdminOrganizationGroup", data);
        context.commit("_addOrganizationGroup", data);
        return data;
      }, "groups - cloneGroup");
    },
    async exportGroup(context, {
      RootId,
      IncludeGroupMembership,
      IncludeDataSourceFieldAccessSetting,
      IncludeAccessSettings,
      IncludeSearches
    }) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        IncludeGroupMembership = IncludeGroupMembership ?? true;
        IncludeDataSourceFieldAccessSetting = IncludeDataSourceFieldAccessSetting ?? true;
        IncludeAccessSettings = IncludeAccessSettings ?? true;
        IncludeSearches = IncludeSearches ?? true;
        const formattedUrl = `${groupsUrl}/${RootId
          }/export?includegroupmembership=${IncludeGroupMembership
          }&includedatasourcefieldaccesssetting=${IncludeDataSourceFieldAccessSetting
          }&includeaccesssettings=${IncludeAccessSettings
          }&includesearches=${IncludeSearches}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "groups - exportGroup");
    },
    async importGroup(context, {
      OrganizationRootId,
      IncludeGroupMembership,
      IncludeDataSourceFieldAccessSetting,
      IncludeAccessSettings,
      IncludeSearches,
      UpdateExisting,
      FileContents
    }) {
      if (!(FileContents?.length > 0)) {
        console.log("invalid import data found");
        return null;
      }
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludeGroupMembership = IncludeGroupMembership ?? true;
        IncludeDataSourceFieldAccessSetting = IncludeDataSourceFieldAccessSetting ?? true;
        IncludeAccessSettings = IncludeAccessSettings ?? true;
        IncludeSearches = IncludeSearches ?? true;
        UpdateExisting = UpdateExisting ?? false;
        let encodedData = { Base64Encoded: btoa(FileContents) };
        const formattedUrl = `${groupsUrl}/import?organizationrootid=${OrganizationRootId
          }&includegroupmembership=${IncludeGroupMembership
          }&includedatasourcefieldaccesssetting=${IncludeDataSourceFieldAccessSetting
          }&includeaccesssettings=${IncludeAccessSettings
          }&includesearches=${IncludeSearches
          }&updateexisting=${UpdateExisting}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: encodedData });
        context.commit("_addSelectedAdminOrganizationGroup", data);
        context.commit("_addOrganizationGroup", data);
        return data;
      }, "groups - importGroup");
    },
    async resetGroupSettings(context, {
      RootId,
      GroupTemplateRootId,
      IncludeGroupMembership,
      IncludeDataSourceFieldAccessSetting,
      IncludeAccessSettings,
      IncludeSearches
    }) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        GroupTemplateRootId = Number(GroupTemplateRootId ?? 0);
        IncludeGroupMembership = IncludeGroupMembership ?? true;
        IncludeDataSourceFieldAccessSetting = IncludeDataSourceFieldAccessSetting ?? true;
        IncludeAccessSettings = IncludeAccessSettings ?? true;
        IncludeSearches = IncludeSearches ?? true;
        const formattedUrl = `${groupsUrl}/${RootId
        }/resetgroupsettings?grouptemplaterootid=${GroupTemplateRootId
          }&includegroupmembership=${IncludeGroupMembership
          }&includedatasourcefieldaccesssetting=${IncludeDataSourceFieldAccessSetting
          }&includeaccesssettings=${IncludeAccessSettings
          }&includesearches=${IncludeSearches}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: null });
        if (data?.Result ?? false) {
          context.commit("_updateSelectedAdminOrganizationGroup", data);
          context.commit("_updateOrganizationGroup", data);
        }
        return data;
      }, "organizations - resetGroupSettings");
    },
  },
};
