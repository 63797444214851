import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationDataSourcesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";
import { OrdersRecordType, DocumentsRecordType, VendorOrdersRecordType, WorkflowTasksRecordType, WorkflowRulesRecordType, WorkflowNotificationsRecordType } from "@/lib/settings";

const store = {
  state: {
    organizationDataSourcesByRootId: {},
    cachedOrganizationDataSourcesOrganizationRootId: -1,
  },
  getters: {
    getAllOrganizationDataSources: (state) => {
      return Object.values(state.organizationDataSourcesByRootId)?.sort((ods1, ods2) => (ods1.Name > ods2.Name ? 1 : -1)) ?? [];
    },
    getOrganizationDataSourceByRootId: (state) => (rootId) => {
      const organizationDataSource = state.organizationDataSourcesByRootId[rootId];
      return organizationDataSource?.RootId > 0 ? organizationDataSource : null;
    },
    getFilteredOrganizationDataSources:
      (state) =>
      ({ ProductRootId, DocumentTypesOnly }) => {
        const includedTypes = [OrdersRecordType, VendorOrdersRecordType, DocumentsRecordType, WorkflowTasksRecordType, WorkflowRulesRecordType, WorkflowNotificationsRecordType];

        return Object.values(state.organizationDataSourcesByRootId)
          .filter((organizationDataSource) => {
            const matchesProductOrDocument =
              (organizationDataSource.ProductRootId === ProductRootId && !DocumentTypesOnly) ??
              (organizationDataSource.Type === DocumentsRecordType && !organizationDataSource.Name?.toLowerCase().includes("dropbox"));

            return matchesProductOrDocument && includedTypes.includes(organizationDataSource.Type);
          })
          .map((source) => _.cloneDeep(source));
      },
  },
  mutations: {
    setOrganizationDataSources(state, Data) {
      state.organizationDataSourcesByRootId = _.keyBy(Data ?? [], "RootId");
    },
    setCachedOrganizationDataSourcesOrganizationRootId(state, cachedOrganizationDataSourcesOrganizationRootId) {
      state.cachedOrganizationDataSourcesOrganizationRootId = cachedOrganizationDataSourcesOrganizationRootId;
    },
    clearOrganizationDataSources(state) {
      state.organizationDataSourcesByRootId = {};
      state.currentOrganizationDataSourceOrganizationRootId = 0;
    },
    _addOrganizationDataSource(state, organizationDataSource) {
      if (!organizationDataSource?.RootId) return;
      state.organizationDataSourcesByRootId[organizationDataSource.RootId] = organizationDataSource;
    },
    _updateOrganizationDataSource(state, organizationDataSource) {
      state.organizationDataSourcesByRootId[organizationDataSource.RootId] = organizationDataSource;
    },
    _updateOrganizationDataSourceIsStandardDataSource(state, organizationDataSource) {
      if (!organizationDataSource?.RootId) return;
      if (!state.organizationDataSourcesByRootId[organizationDataSource?.RootId]) return;
      state.organizationDataSourcesByRootId[organizationDataSource?.RootId].IsStandardDataSource = organizationDataSource.IsStandardDataSource ?? false;
    },
    _removeOrganizationDataSource(state, organizationDataSource) {
      if (!organizationDataSource?.RootId) return;
      delete state.organizationDataSourcesByRootId[organizationDataSource?.RootId];
    },
  },
  actions: {
    async getOrganizationDataSources(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        IncludeMetadata,
        IncludeExtendedMetadata,
        IncludeCustomFields,
        ExcludeBlobs,
        ValidateRecords,
        UseCache,
        CanUseServerCache
      }
    ) {
      await timeOperation(async () => {
        UseCache = UseCache ?? false;
        const cachedOrganizationDataSourcesOrganizationRootId = Number(context.state.cachedOrganizationDataSourcesOrganizationRootId ?? 0);
        const dirtyCache = cachedOrganizationDataSourcesOrganizationRootId !== OrganizationRootId;
        if (!UseCache || dirtyCache) {
          const params = new URLSearchParams({
            productrootid: ProductRootId ?? 0,
            organizationrootid: OrganizationRootId ?? 0,
            productdatasourcerootid: 0,
            validaterecords: ValidateRecords ?? false,
            canusecache: CanUseServerCache ?? true,
            includemetadata: IncludeMetadata ?? true,
            includeextendedmetadata: IncludeExtendedMetadata ?? false,
            excludeblobs: ExcludeBlobs ?? false,
            includecustomfields: IncludeCustomFields ?? true,
          });
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: `${organizationDataSourcesUrl}?${params.toString()}`, Payload: null });
          context.commit("setOrganizationDataSources", data);
        }
        return context.getters.getAllOrganizationDataSources;
      }, "organizationDataSources - getOrganizationDataSources");
    },
    async getOrganizationDataSource(
      context,
      { RootId, IncludeMetadata, IncludeExtendedMetadata, IncludeCustomFields, ExcludeBlobs, ValidateRecords, UseCache, CanUseServerCache }
    ) {
      await timeOperation(async () => {
        if (!UseCache) {
          const params = new URLSearchParams({
            validaterecords: ValidateRecords ?? false,
            canusecache: CanUseServerCache ?? true,
            includemetadata: IncludeMetadata ?? true,
            includeextendedmetadata: IncludeExtendedMetadata ?? false,
            excludeblobs: ExcludeBlobs ?? false,
            includecustomfields: IncludeCustomFields,
          });
          const formattedUrl = `${organizationDataSourcesUrl}/${RootId ?? 0}?${params.toString()}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setOrganizationDataSourceDataSources", data);
        }
      }, "organizationDataSources - getOrganizationDataSources");
    },
    async getOrganizationDataSourceTypes(context, { OrganizationRootId, ProductRootId }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        const formattedUrl = `${organizationDataSourcesUrl}/${OrganizationRootId}/datasourcetypes?productrootid=${ProductRootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        //context.commit("setOrganizationDataSourceTypes", data);
        return data;
      }, "organizationDataSources - getOrganizationDataSourceTypes");
    },
    async addOrganizationDataSource(context, organizationDataSource) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDataSourcesUrl, Payload: organizationDataSource });
        if (data?.RootId > 0) {
          organizationDataSource.RootId = data.RootId;
          context.commit("_addOrganizationDataSource", data);
        }
        return data;
      }, "organizationDataSources - addOrganizationDataSource");
    },
    async updateOrganizationDataSource(context, organizationDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDataSource });
        context.commit("_updateOrganizationDataSource", data);
      }, "organizationDataSources - updateOrganizationDataSource");
    },
    async updateOrganizationDataSourceUpdateStandardDataSourceFlag(context, organizationDataSource) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId ?? 0)}/updatestandarddatasourceflag`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDataSource });
        context.commit("_updateOrganizationDataSourceIsStandardDataSource", data);
        return data;
      }, "organizationDataSources - updateOrganizationDataSourceUpdateStandardDataSourceFlag");
    },
    async enableOrganizationDataSource(context, organizationDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId ?? 0)}/updateenabledstatus`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationDataSource, Enabled: true } });
      }, "organizationDataSources - enableOrganizationDataSource");
    },
    async disableOrganizationDataSource(context, organizationDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId ?? 0)}/updateenabledstatus`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationDataSource, Enabled: false } });
      }, "organizationDataSources - disableOrganizationDataSource");
    },
    async removeOrganizationDataSource(context, organizationDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDataSourcesUrl}/${Number(organizationDataSource?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationDataSource", organizationDataSource);
      }, "organizationDataSources - removeOrganizationDataSource");
    },
  },
};

function deepFreeze(obj) {
  Object.freeze(obj);
  Object.getOwnPropertyNames(obj).forEach((key) => {
    const val = obj[key];
    if (val && typeof val === "object" && !Object.isFrozen(val)) {
      deepFreeze(val);
    }
  });
  return obj;
}

Object.defineProperty(store.state, "organizationDataSources", {
  get() {
    // Clone so you aren’t freezing the original data
    // and deep-freeze the clone to make it fully immutable
    const cloned = _.cloneDeep(store?.getters?.getAllOrganizationDataSources?.(store.state) ?? []);
    return deepFreeze(cloned);
  },
  set() {
    throw new Error("Cannot mutate organizationDataSources property");
  },
  enumerable: true,
});

export default store;
