import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  DeleteVerb,
  organizationDocumentTypesUrl,
  organizationDocumentTypePartiesUrl,
  organizationDocumentTypePartyRolesUrl,
  organizationDocumentTypeCategoriesUrl,
} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDocumentTypes: [],
    selectedAdminOrganizationDocumentType: {},
    organizationDocumentTypesCachedOrganizationRootId: 0,
    organizationDocumentTypesCachedProductRootId: 0,
  },
  getters: {
    getOrganizationDocumentTypeByRootId: (state) => (rootId) => {
      return state.organizationDocumentTypes.find((organizationDocumentType) => Number(organizationDocumentType?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getOrganizationDocumentTypeByName: (state) => (name) => {
      return state.organizationDocumentTypes.find((organizationDocumentType) => String(organizationDocumentType?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getOrganizationDocumentTypesForProduct: (state) => (productRootId) => {
      let result = [];
      state.organizationDocumentTypes.forEach((organizationDocumentType) => {
        if (Number(organizationDocumentType.ProductRootId) === Number(productRootId)) {
          result.push(_.cloneDeep(organizationDocumentType));
        }
      });
      return result.sort((odt1, odt2) => (odt1.DocumentTypeName > odt2.DocumentTypeName ? 1 : -1)) ?? [];
    },
    getNextOrganizationDocumentTypeRootId: (state) => {
      let result = 1;
      state.organizationDocumentTypes.forEach((organizationDocumentType) => {
        if (Number(organizationDocumentType.RootId) >= result) {
          result = Number(organizationDocumentType.RootId) + 1;
        }
      });
      return result;
    },
    areOrganizationDocumentTypesLoaded:
      (state) =>
        ({ ProductRootId, OrganizationRootId }) => {
          ProductRootId = Number(ProductRootId ?? 0);
          OrganizationRootId = Number(OrganizationRootId ?? 0);
          return (
            ProductRootId > 0 &&
            OrganizationRootId > 0 &&
            state.organizationDocumentTypes?.find(
              (organizationDocumentType) =>
                Number(organizationDocumentType?.ProductRootId ?? 0) === ProductRootId && Number(organizationDocumentType?.OrganizationRootId ?? 0) === OrganizationRootId
            ) != null
          );
        },
    isOrganizationDocumentTypeNameTaken: (state) => (currentOrganizationDocumentType) => {
      const { organizationDocumentTypes } = state;
      if (organizationDocumentTypes?.length < 1) {
        return false;
      }
      if (!currentOrganizationDocumentType) {
        return true;
      }
      const { Name: currentName, RootId } = currentOrganizationDocumentType;
      if (!(currentName?.length > 0)) {
        return true;
      }
      if (Number(RootId ?? 0) < 1) {
        return true;
      }
      return !!organizationDocumentTypes.find((organizationDocumentType) => String(organizationDocumentType?.Name ?? "") === String(currentName ?? "") && Number(RootId ?? 0) !== Number(organizationDocumentType?.RootId ?? 0));
    },
  },
  mutations: {
    setOrganizationDocumentTypes(state, organizationDocumentTypesData) {
      state.organizationDocumentTypes = organizationDocumentTypesData?.sort((odt1, odt2) => (odt1.Name > odt2.Name ? 1 : -1)) ?? [];
    },
    setOrganizationDocumentTypesCachedOrganizationRootId(state, organizationDocumentTypesCachedOrganizationRootId) {
      state.organizationDocumentTypesCachedOrganizationRootId = Number(organizationDocumentTypesCachedOrganizationRootId ?? 0);
    },
    setOrganizationDocumentTypesCachedProductRootId(state, organizationDocumentTypesCachedProductRootId) {
      state.organizationDocumentTypesCachedProductRootId = Number(organizationDocumentTypesCachedProductRootId ?? 0);
    },
    setSelectedAdminOrganizationDocumentType(state, newSelectedAdminOrganizationDocumentType) {
      state.selectedAdminOrganizationDocumentType = _.cloneDeep(newSelectedAdminOrganizationDocumentType);
    },
    _addOrganizationDocumentType(state, organizationDocumentType) {
      const organizationDocumentTypesData = _.cloneDeep(state.organizationDocumentTypes) ?? [];
      organizationDocumentTypesData.push(organizationDocumentType);
      state.organizationDocumentTypes = organizationDocumentTypesData.sort((odt1, odt2) => (odt1.Name > odt2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationDocumentType(state, updateOrganizationDocumentType) {
      const rootId = Number(updateOrganizationDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentTypesData = _.cloneDeep(state.organizationDocumentTypes) ?? [];
        organizationDocumentTypesData =
          [
            ...organizationDocumentTypesData.filter((organizationDocumentType) => Number(organizationDocumentType?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationDocumentType),
          ].sort((odt1, odt2) => (odt1.Name > odt2.Name ? 1 : -1)) ?? [];
        state.organizationDocumentTypes = organizationDocumentTypesData;
      }
    },
    _updateOrganizationDocumentTypeEnabled(state, updateOrganizationDocumentType) {
      const rootId = Number(updateOrganizationDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentType = state.organizationDocumentTypesData?.find((organizationDocumentType) => Number(organizationDocumentType?.RootId ?? 0) === rootId) ?? null;
        if (organizationDocumentType?.RootId > 0) {
          organizationDocumentType.Enabled = updateOrganizationDocumentType.Enabled;
        }
      }
    },
    _removeOrganizationDocumentType(state, deleteOrganizationDocumentType) {
      const rootId = Number(deleteOrganizationDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentTypesData = _.cloneDeep(state.organizationDocumentTypes) ?? [];
        organizationDocumentTypesData =
          [...organizationDocumentTypesData.filter((organizationDocumentType) => Number(organizationDocumentType?.RootId ?? 0) !== rootId)].sort((odt1, odt2) =>
            odt1.Name > odt2.Name ? 1 : -1
          ) ?? [];
        state.organizationDocumentTypes = organizationDocumentTypesData;
      }
    },
    _updatedOrganizationDocumentTypeCategories(state, { OrganizationDocumentTypeRootId, OrganizationDocumentTypeCategories }) {
      OrganizationDocumentTypeRootId = Number(OrganizationDocumentTypeRootId ?? 0);
      OrganizationDocumentTypeCategories = OrganizationDocumentTypeCategories ?? [];
      if (
        OrganizationDocumentTypeRootId > 0 &&
        state.selectedAdminOrganizationDocumentType?.RootId > 0 &&
        OrganizationDocumentTypeRootId === Number(state.selectedAdminOrganizationDocumentType.RootId)
      ) {
        state.selectedAdminOrganizationDocumentType.OrganizationDocumentTypeCategories =
          _.cloneDeep(OrganizationDocumentTypeCategories ?? []).sort((dtc1, dtc2) => (dtc1.Name > dtc2.Name ? 1 : -1)) ?? [];
        let updateOrganizationDocumentType =
          state.organizationDocumentTypes.find((organizationDocumentType) => Number(organizationDocumentType?.RootId ?? 0) === OrganizationDocumentTypeRootId) ?? null;
        if (updateOrganizationDocumentType?.RootId > 0) {
          updateOrganizationDocumentType.OrganizationDocumentTypeCategories =
            _.cloneDeep(OrganizationDocumentTypeCategories ?? []).sort((dtc1, dtc2) => (dtc1.Name > dtc2.Name ? 1 : -1)) ?? [];
          let organizationDocumentTypesData = _.cloneDeep(state.organizationDocumentTypes) ?? [];
          state.organizationDocumentTypes =
            [
              ...organizationDocumentTypesData.filter((organizationDocumentType) => Number(organizationDocumentType?.RootId ?? 0) !== OrganizationDocumentTypeRootId),
              _.cloneDeep(updateOrganizationDocumentType),
            ].sort((dtt1, dtt2) => (dtt1.Name > dtt2.Name ? 1 : -1)) ?? [];
        }
      }
    },
  },
  actions: {
    async getOrganizationDocumentTypes(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        IncludeMetadata,
        IncludeOrganizationDocumentTypeFields,
        IncludeOrganizationDocumentTypeCategories,
        IncludeOrganizationDocumentTypeTitles,
        IncludeSystemLevelMetadata,
        IncludeProductLevelMetadata,
        IncludeOrganizationLevelMetadata,
        UseCache,
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? false;
        IncludeOrganizationDocumentTypeFields = IncludeOrganizationDocumentTypeFields ?? false;
        IncludeOrganizationDocumentTypeCategories = IncludeOrganizationDocumentTypeCategories ?? false;
        IncludeOrganizationDocumentTypeTitles = IncludeOrganizationDocumentTypeTitles ?? false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata ?? false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata ?? false;
        IncludeOrganizationLevelMetadata = IncludeOrganizationLevelMetadata ?? false;
        UseCache = UseCache ?? false;
        if (UseCache &&
          (context.state.organizationDocumentTypesCachedOrganizationRootId === OrganizationRootId) &&
          (context.state.organizationDocumentTypesCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${organizationDocumentTypesUrl}?organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&includemetadata=${IncludeMetadata}&includeorganizationdocumenttypefields=${IncludeOrganizationDocumentTypeFields}&includeorganizationdocumenttypecategories=${IncludeOrganizationDocumentTypeCategories}&includeorganizationdocumenttypetitles=${IncludeOrganizationDocumentTypeTitles}&includesystemlevelmetadata=${IncludeSystemLevelMetadata}&includeproductlevelmetadata=${IncludeProductLevelMetadata}&includeorganizationlevelmetadata=${IncludeOrganizationLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setOrganizationDocumentTypes", data);
        context.commit("setOrganizationDocumentTypesCachedOrganizationRootId", OrganizationRootId);
        context.commit("setOrganizationDocumentTypesCachedProductRootId", ProductRootId);
        return data;
      }, "organizationDocumentTypes - getOrganizationDocumentTypes");
    },
    async addOrganizationDocumentTypes(context, organizationDocumentTypes) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTypes });
        context.commit("setOrganizationDocumentTypes", data);
      }, "organizations - addOrganizationDocumentType");
    },
    async addOrganizationDocumentType(context, organizationDocumentType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentTypesUrl, Payload: organizationDocumentType });
        context.commit("_addOrganizationDocumentType", data);
      }, "organizationDocumentTypes - addOrganizationDocumentType");
    },
    async updateOrganizationDocumentType(context, organizationDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypesUrl}/${Number(organizationDocumentType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentType });
        context.commit("_updateOrganizationDocumentType", data);
      }, "organizationDocumentTypes - updateOrganizationDocumentType");
    },
    async removeOrganizationDocumentType(context, organizationDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypesUrl}/${Number(organizationDocumentType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationDocumentType", organizationDocumentType);
      }, "organizationDocumentTypes - removeOrganizationDocumentType");
    },
    async updateOrganizationDocumentTypePositions(context, organizationDocumentTypes) {
      await timeOperation(async () => {
        if (organizationDocumentTypes?.length > 0) {
          const formattedUrl = `${organizationDocumentTypesUrl}/updatepositions`;
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTypes });
          context.commit("setOrganizationDocumentTypes", data);
        }
      }, "organizationDocumentTypes - updateOrganizationDocumentTypePositions");
    },
    async updateOrganizationDocumentTypeEnabledStatus(context, organizationDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypesUrl}/${Number(organizationDocumentType?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentType });
        context.commit("_updateOrganizationDocumentTypeEnabled", data);
      }, "organizationDocumentTypes - enableOrganizationDocumentType");
    },
    async getOrganizationDocumentTypePartiesAndPartyRoles(context, organizationDocumentType) {
      await timeOperation(async () => {
        if (organizationDocumentType?.RootId > 0) {
          await context.dispatch("getOrganizationDocumentTypeParties", organizationDocumentType);
          await context.dispatch("getOrganizationDocumentTypePartyRoles", organizationDocumentType);
        }
      }, "organizationDocumentTypes - getOrganizationDocumentTypePartiesAndPartyRoles");
    },
    async getOrganizationDocumentTypeParties(context, organizationDocumentType) {
      await timeOperation(async () => {
        if (organizationDocumentType?.RootId > 0) {
          const formattedUrl = `${organizationDocumentTypePartiesUrl}?organizationdocumenttyperootid=${Number(organizationDocumentType.RootId ?? 0)}&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          organizationDocumentType.OrganizationDocumentTypeParties = data ?? [];
        }
      }, "organizationDocumentTypes - getOrganizationDocumentTypeParties");
    },
    async addOrganizationDocumentTypeParty(context, organizationDocumentTypeParty) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentTypePartiesUrl, Payload: organizationDocumentTypeParty });
      }, "organizationDocumentTypes - addOrganizationDocumentTypeParty");
    },
    async removeOrganizationDocumentTypeParty(context, organizationDocumentTypeParty) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypePartiesUrl}/${Number(organizationDocumentTypeParty?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizationDocumentTypes - removeOrganizationDocumentTypeParty");
    },
    async getOrganizationDocumentTypePartyRoles(context, organizationDocumentType) {
      await timeOperation(async () => {
        if (organizationDocumentType?.RootId > 0) {
          const formattedUrl = `${organizationDocumentTypePartyRolesUrl}?organizationdocumenttyperootid=${Number(organizationDocumentType?.RootId ?? 0)}&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          organizationDocumentType.OrganizationDocumentTypePartyRoles = data ?? [];
        }
      }, "organizationDocumentTypes - getOrganizationDocumentTypePartyRoles");
    },
    async addOrganizationDocumentTypePartyRole(context, organizationDocumentTypePartyRole) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentTypePartyRolesUrl, Payload: organizationDocumentTypePartyRole });
      }, "organizationDocumentTypes - addOrganizationDocumentTypePartyRole");
    },
    async removeOrganizationDocumentTypePartyRole(context, organizationDocumentTypePartyRole) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypePartyRolesUrl}/${Number(organizationDocumentTypePartyRole?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizationDocumentTypes - removeOrganizationDocumentTypePartyRole");
    },
    async saveOrganizationDocumentTypeCategories(context, { OrganizationDocumentTypeRootId, OrganizationDocumentTypeCategories }) {
      await timeOperation(async () => {
        OrganizationDocumentTypeRootId = Number(OrganizationDocumentTypeRootId ?? 0);
        OrganizationDocumentTypeCategories = OrganizationDocumentTypeCategories ?? [];
        const formattedUrl = `${organizationDocumentTypeCategoriesUrl}/${OrganizationDocumentTypeRootId}/updatecategories`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: OrganizationDocumentTypeCategories });
        context.commit("_updatedOrganizationDocumentTypeCategories", { OrganizationDocumentTypeRootId, OrganizationDocumentTypeCategories });
      }, "organizationDocumentTypes - saveOrganizationDocumentTypeCategories");
    },
  },
};
