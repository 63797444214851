import {ApiRequest, GetVerb, PostVerb, usersUrl, userProductCodeBooksUrl, userAvatarsUrl, validateNamesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    users: [],
    userTemplate: null,
    userAvatar: {},
    userNameSearchData: [],
    selectedUserUserCodeBookProductRootId: 0,
    userProductRequirementCodeBookCodes: [],
    userProductExceptionCodeBookCodes: [],
    getUsersDataOrganizationRootId: -1,
  },
  getters: {
    getUserByDisplayName: (state) => (displayName) => {
      return state.users?.find((user) => String(user.DisplayName) === String(displayName)) ?? null;
    },
    getUserProductRequirementCodeBookCodeByCode: (state) => (code) => {
      return (
        state.userProductRequirementCodeBookCodes?.find((userProductRequirementCodeBookCode) => String(userProductRequirementCodeBookCode?.Code ?? "") === String(code ?? "")) ??
        null
      );
    },
    getUserProductExceptionCodeBookCodeByCode: (state) => (code) => {
      return (
        state.userProductExceptionCodeBookCodes?.find((userProductExceptionCodeBookCode) => String(userProductExceptionCodeBookCode?.Code ?? "") === String(code ?? "")) ?? null
      );
    },
    isUserNameTaken: (state) => (userRootId) => {
      let nameSearchResult = state.userNameSearchData?.find((nameSearchResult) => Number(nameSearchResult.RootId) !== Number(userRootId)) ?? null;
      return nameSearchResult !== null;
    },
  },
  mutations: {
    setGetUsersDataOrganizationRootId(state, organizationRootId) {
      state.getUsersDataOrganizationRootId = organizationRootId;
    },
    setUserData(state, users) {
      users?.forEach((user) => {
        if (user.LastActivityDateTime) {
          if (String(user.LastActivityDateTime).includes("T") && !String(user.LastActivityDateTime ?? "").includes("Z")) {
            user.LastActivityDateTime = `${user.LastActivityDateTime}.000Z`;
          }
          user.LastActivityDateTime = new Date(user.LastActivityDateTime);
          if (user.LastActivityDateTime.getFullYear() < 1901) {
            user.LastActivityDateTime = null;
          }
        } else {
          user.LastActivityDateTime = null;
        }
      });
      state.users = users?.sort((user1, user2) => (user1.DisplayName > user2.DisplayName ? 1 : -1)) ?? [];
    },
    setUserProductCodeBookCodesData(state, userProductCodeBookCodes) {
      let userProductRequirementCodeBookCodes = [];
      let userProductExceptionCodeBookCodes = [];
      if (userProductCodeBookCodes?.length > 0) {
        userProductCodeBookCodes.forEach((userProductCodeBookCode) => {
          if (
            String(userProductCodeBookCode.Type ?? "")
              .toLowerCase()
              .includes("requirement")
          ) {
            userProductRequirementCodeBookCodes.push(_.cloneDeep(userProductCodeBookCode));
          }
          if (
            String(userProductCodeBookCode.Type ?? "")
              .toLowerCase()
              .includes("exception")
          ) {
            userProductExceptionCodeBookCodes.push(_.cloneDeep(userProductCodeBookCode));
          }
        });
      }
      state.userProductRequirementCodeBookCodes = userProductRequirementCodeBookCodes?.sort((upcbc1, upcbc2) => (upcbc1.Code > upcbc2.Code ? 1 : -1)) ?? [];
      state.userProductExceptionCodeBookCodes = userProductExceptionCodeBookCodes?.sort((upcbc1, upcbc2) => (upcbc1.Code > upcbc2.Code ? 1 : -1)) ?? [];
    },
    setUserAvatarData(state, userAvatar) {
      state.userAvatar = userAvatar ?? {};
    },
    clearUserAvatarData(state) {
      state.userAvatar = {};
    },
    clearUserData(state) {
      state.users = [];
    },
    setUserNameSearchData(state, nameSearchData) {
      state.userNameSearchData = nameSearchData ?? [];
    },
    setSelectedUserUserCodeBookProductRootId(state, productRootId) {
      state.selectedUserUserCodeBookProductRootId = productRootId ?? 0;
    },
    _updateUser(state, updateUser) {
      const rootId = Number(updateUser?.RootId ?? 0);
      if (rootId > 0) {
        let users = _.cloneDeep(state.users) ?? [];
        users = [...users.filter((user) => Number(user?.RootId ?? 0) !== rootId), _.cloneDeep(updateUser)] ?? [];
        state.users = users.sort((user1, user2) => (user1.DisplayName > user2.DisplayName ? 1 : -1)) ?? [];
      }
    },
    _updateUserGroupMember(state, updatedUserGroupMember) {
      if ((state.users?.length > 0) && (updatedUserGroupMember != null) && (Number(updatedUserGroupMember.MemberType ?? 0) === 1)) {
        let foundUser = state.users.find((user) => Number(user?.RootId ?? 0) === Number(updatedUserGroupMember?.MemberRootId ?? 0));
        foundUser?.GroupMembers?.forEach((userGroupMember) => {
          if (Number(userGroupMember.RootId) === Number(updatedUserGroupMember.RootId)) {
            userGroupMember.IsSelected = updatedUserGroupMember.IsSelected;
          }
        });
      }
    },
  },
  actions: {
    async getUserGeoLocationDefault(context, { UserRootId }) {
      return timeOperation(async () => {
        if (!UserRootId) {
          console.error("no user id");
          return;
        }
        const formattedUrl = `${usersUrl}/usergeolocationdefault?userrootid=${Number(UserRootId)}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "users - getUserGeoLocationDefault");
    },
    async getUsersData(
      context,
      {
        UseCache,
        OrganizationRootId,
        IncludeMetadata,
        IncludeMembership,
        IncludeAccessSettings,
        IncludeConfigSettings,
        IncludeOrganizationSettings,
        IncludeProducts,
        IncludeSearches,
        IncludeBranches,
        IncludeParties,
        IncludeUserTags,
        IncludeCodeBooks,
        IncludeAvatar,
      }
    ) {
      await timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        if (OrganizationRootId > 0) {
          UseCache = UseCache ?? false;
          if (UseCache) {
            if (OrganizationRootId === context.state.getUsersDataOrganizationRootId) {
              return;
            }
          }
          IncludeMetadata = IncludeMetadata ?? false;
          IncludeMembership = IncludeMembership ?? false;
          IncludeAccessSettings = IncludeAccessSettings ?? false;
          IncludeConfigSettings = IncludeConfigSettings ?? false;
          IncludeOrganizationSettings = IncludeOrganizationSettings ?? false;
          IncludeProducts = IncludeProducts ?? false;
          IncludeSearches = IncludeSearches ?? false;
          IncludeBranches = IncludeBranches ?? false;
          IncludeParties = IncludeParties ?? false;
          IncludeUserTags = IncludeUserTags ?? false;
          IncludeCodeBooks = IncludeCodeBooks ?? false;
          IncludeAvatar = IncludeAvatar ?? false;
          context.commit("setGetUsersDataOrganizationRootId", OrganizationRootId);
          const formattedUrl = `${usersUrl
            }?organizationrootid=${OrganizationRootId
            }&includemetadata=${IncludeMetadata
            }&includemembership=${IncludeMembership
            }&includeaccesssettings=${IncludeAccessSettings
            }&includeconfigsettings=${IncludeConfigSettings
            }&includeorganizationsettings=${IncludeOrganizationSettings
            }&includeproducts=${IncludeProducts
            }&includesearches=${IncludeSearches
            }&includebranches=${IncludeBranches
            }&includeparties=${IncludeParties
            }&includeusertags=${IncludeUserTags
            }&includecodebooks=${IncludeCodeBooks
            }&includeavatar=${IncludeAvatar}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setUserData", data);
          return data;
        } else {
          context.commit("setUserData", []);
          return [];
        }
      }, "users - getUsersData");
    },
    async getUserDetails(
      context,
      {
        RootId,
        IncludeMetadata,
        IncludeMembership,
        IncludeAccessSettings,
        IncludeConfigSettings,
        IncludeOrganizationSettings,
        IncludeProducts,
        IncludeSearches,
        IncludeBranches,
        IncludeParties,
        IncludeUserTags,
        IncludeCodeBooks,
        IncludeAvatar,
      }
    ) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        if (RootId > 0) {
          IncludeMetadata = IncludeMetadata ?? false;
          IncludeMembership = IncludeMembership ?? false;
          IncludeAccessSettings = IncludeAccessSettings ?? false;
          IncludeConfigSettings = IncludeConfigSettings ?? false;
          IncludeOrganizationSettings = IncludeOrganizationSettings ?? false;
          IncludeProducts = IncludeProducts ?? false;
          IncludeSearches = IncludeSearches ?? false;
          IncludeBranches = IncludeBranches ?? false;
          IncludeParties = IncludeParties ?? false;
          IncludeUserTags = IncludeUserTags ?? false;
          IncludeCodeBooks = IncludeCodeBooks ?? false;
          IncludeAvatar = IncludeAvatar ?? false;
          const formattedUrl = `${usersUrl
          }/${RootId
          }?includemetadata=${IncludeMetadata
          }&includemembership=${IncludeMembership
          }&includeaccesssettings=${IncludeAccessSettings
          }&includeconfigsettings=${IncludeConfigSettings
          }&includeorganizationsettings=${IncludeOrganizationSettings
          }&includeproducts=${IncludeProducts
          }&includesearches=${IncludeSearches
          }&includebranches=${IncludeBranches
          }&includeparties=${IncludeParties
          }&includeusertags=${IncludeUserTags
          }&includecodebooks=${IncludeCodeBooks
          }&includeavatar=${IncludeAvatar}`;
          const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
          context.commit("_updateUser", data);
          context.commit("_updateOrganizationUser", data);
          return data;
        } else {
          return [];
        }
      }, "users - getUserDetails");
    },
    async getUserProductCodeBookCodesData(context, params) {
      await timeOperation(async () => {
        const userRootId = Number(params?.UserRootId ?? 0);
        if (
          !(
            (params?.UseCache ?? false) &&
            context.state.userProductRequirementCodeBookCodes.length > 0 &&
            Number(context.state.userProductRequirementCodeBookCodes[0]?.UserRootId ?? 0) === userRootId &&
            context.state.userProductExceptionCodeBookCodes.length > 0 &&
            Number(context.state.userProductExceptionCodeBookCodes[0]?.UserRootId ?? 0) === userRootId
          )
        ) {
          const formattedUrl = `${userProductCodeBooksUrl}/userproductcodes?userrootid=${userRootId}&productrootid=${Number(params?.ProductRootId ?? 0)}&includemetadata=${
            params?.IncludeMetadata ?? false
          }&includemembership=${params?.IncludeMembership ?? false}&includeaccesssettings=${params?.IncludeAccessSettings ?? false}&includeconfigsettings=${
            params?.IncludeConfigSettings ?? false
          }&includeorganizationsettings=${params?.IncludeOrganizationSettings ?? false}&includeproducts=${params?.IncludeProducts ?? false}&includesearches=${
            params?.IncludeSearches ?? false
          }&includebranches=${params?.IncludeBranches ?? false}&includeparties=${params?.IncludeParties ?? false}&includeusertags=${
            params?.IncludeUserTags ?? false
          }&includecodebooks=${params?.IncludeCodeBooks ?? false}&includeavatar=${params?.IncludeAvatar ?? false}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setUserProductCodeBookCodesData", data);
        }
      }, "users - getUserProductCodeBookCodesData");
    },
    async getUserSimpleSearchData(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${usersUrl}?searchtext=${String(params?.SearchText ?? "")}&includeallorganizations=${
          params?.IncludeAllOrganizations ?? true
        }&includemetadata=false&includemembership=false&includeaccesssettings=false&includeconfigsettings=false&includeorganizationsettings=false&includeproducts=false&includesearches=false&includebranches=false&includeparties=false&includeusertags=false&includecodebooks=false&includeavatar=false`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setUserData", data);
      }, "users - getUserSimpleSearchData");
    },
    async userNameSearch(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${validateNamesUrl}?typename=user&email=${params?.Email}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setUserNameSearchData", data);
      }, "users - userNameSearch");
    },
    async cloneUser(context, {
      RootId,
      IncludeProducts,
      IncludeGroupMembership,
      IncludeDataSourceFieldAccessSetting,
      IncludeAccessSettings,
      IncludeConfigSettings,
      IncludeProductCodeBooks,
      IncludeTags,
      IncludeBranches,
      IncludeSearches,
      IncludeFormLayouts
    }) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        IncludeProducts = IncludeProducts ?? true;
        IncludeGroupMembership = IncludeGroupMembership ?? true;
        IncludeDataSourceFieldAccessSetting = IncludeDataSourceFieldAccessSetting ?? true;
        IncludeAccessSettings = IncludeAccessSettings ?? true;
        IncludeConfigSettings = IncludeConfigSettings ?? true;
        IncludeProductCodeBooks = IncludeProductCodeBooks ?? true;
        IncludeTags = IncludeTags ?? true;
        IncludeBranches = IncludeBranches ?? true;
        IncludeSearches = IncludeSearches ?? true;
        IncludeFormLayouts = IncludeFormLayouts ?? true;
        const formattedUrl = `${usersUrl}/${RootId
          }/clone?includeproducts=${IncludeProducts
          }&includegroupmembership=${IncludeGroupMembership
          }&includedatasourcefieldaccesssetting=${IncludeDataSourceFieldAccessSetting
          }&includeaccesssettings=${IncludeAccessSettings
          }&includeconfigsettings=${IncludeConfigSettings
          }&includeproductcodebooks=${IncludeProductCodeBooks
          }&includetags=${IncludeTags
          }&includebranches=${IncludeBranches
          }&includesearches=${IncludeSearches
          }&includeformlayouts=${IncludeFormLayouts}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_addSelectedAdminOrganizationUser", data);
        context.commit("_addOrganizationUser", data);
        return data;
      }, "users - cloneUser");
    },
    async exportUser(context, {
      RootId,
      IncludeProducts,
      IncludeGroupMembership,
      IncludeDataSourceFieldAccessSetting,
      IncludeAccessSettings,
      IncludeConfigSettings,
      IncludeProductCodeBooks,
      IncludeTags,
      IncludeBranches,
      IncludeSearches,
      IncludeFormLayouts
    }) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        IncludeProducts = IncludeProducts ?? true;
        IncludeGroupMembership = IncludeGroupMembership ?? true;
        IncludeDataSourceFieldAccessSetting = IncludeDataSourceFieldAccessSetting ?? true;
        IncludeAccessSettings = IncludeAccessSettings ?? true;
        IncludeConfigSettings = IncludeConfigSettings ?? true;
        IncludeProductCodeBooks = IncludeProductCodeBooks ?? true;
        IncludeTags = IncludeTags ?? true;
        IncludeBranches = IncludeBranches ?? true;
        IncludeSearches = IncludeSearches ?? true;
        IncludeFormLayouts = IncludeFormLayouts ?? true;
        const formattedUrl = `${usersUrl}/${RootId
          }/export?includeproducts=${IncludeProducts
          }&includegroupmembership=${IncludeGroupMembership
          }&includedatasourcefieldaccesssetting=${IncludeDataSourceFieldAccessSetting
          }&includeaccesssettings=${IncludeAccessSettings
          }&includeconfigsettings=${IncludeConfigSettings
          }&includeproductcodebooks=${IncludeProductCodeBooks
          }&includetags=${IncludeTags
          }&includebranches=${IncludeBranches
          }&includesearches=${IncludeSearches
          }&includeformlayouts=${IncludeFormLayouts}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "users - exportUser");
    },
    async importUser(context, {
      OrganizationRootId,
      IncludeProducts,
      IncludeGroupMembership,
      IncludeDataSourceFieldAccessSetting,
      IncludeAccessSettings,
      IncludeConfigSettings,
      IncludeProductCodeBooks,
      IncludeTags,
      IncludeBranches,
      IncludeSearches,
      IncludeFormLayouts,
      UpdateExisting,
      FileContents
    }) {
      if (!(FileContents?.length > 0)) {
        console.log("invalid import data found");
        return null;
      }
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludeProducts = IncludeProducts ?? true;
        IncludeGroupMembership = IncludeGroupMembership ?? true;
        IncludeDataSourceFieldAccessSetting = IncludeDataSourceFieldAccessSetting ?? true;
        IncludeAccessSettings = IncludeAccessSettings ?? true;
        IncludeConfigSettings = IncludeConfigSettings ?? true;
        IncludeProductCodeBooks = IncludeProductCodeBooks ?? true;
        IncludeTags = IncludeTags ?? true;
        IncludeBranches = IncludeBranches ?? true;
        IncludeSearches = IncludeSearches ?? true;
        IncludeFormLayouts = IncludeFormLayouts ?? true;
        UpdateExisting = UpdateExisting ?? false;
        let encodedData = { Base64Encoded: btoa(FileContents) };
        const formattedUrl = `${usersUrl}/import?organizationrootid=${OrganizationRootId
          }&includeproducts=${IncludeProducts
          }&includegroupmembership=${IncludeGroupMembership
          }&includedatasourcefieldaccesssetting=${IncludeDataSourceFieldAccessSetting
          }&includeaccesssettings=${IncludeAccessSettings
          }&includeconfigsettings=${IncludeConfigSettings
          }&includeproductcodebooks=${IncludeProductCodeBooks
          }&includetags=${IncludeTags
          }&includebranches=${IncludeBranches
          }&includesearches=${IncludeSearches
          }&includeformlayouts=${IncludeFormLayouts
          }&updateexisting=${UpdateExisting}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: encodedData });
        context.commit("_addSelectedAdminOrganizationUser", data);
        context.commit("_addOrganizationUser", data);
        return data;
      }, "users - importUser");
    },
    async resetUserSettings(context, {
      RootId,
      UserTemplateRootId,
      IncludeProducts,
      IncludeGroupMembership,
      IncludeDataSourceFieldAccessSetting,
      IncludeAccessSettings,
      IncludeConfigSettings,
      IncludeProductCodeBooks,
      IncludeTags,
      IncludeBranches,
      IncludeSearches,
      IncludeFormLayouts
    }) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        UserTemplateRootId = Number(UserTemplateRootId ?? 0);
        IncludeProducts = IncludeProducts ?? true;
        IncludeGroupMembership = IncludeGroupMembership ?? true;
        IncludeDataSourceFieldAccessSetting = IncludeDataSourceFieldAccessSetting ?? true;
        IncludeAccessSettings = IncludeAccessSettings ?? true;
        IncludeConfigSettings = IncludeConfigSettings ?? true;
        IncludeProductCodeBooks = IncludeProductCodeBooks ?? true;
        IncludeTags = IncludeTags ?? true;
        IncludeBranches = IncludeBranches ?? true;
        IncludeSearches = IncludeSearches ?? true;
        IncludeFormLayouts = IncludeFormLayouts ?? true;
        const formattedUrl = `${usersUrl}/${RootId
          }/resetusersettings?usertemplaterootid=${UserTemplateRootId
          }&includeproducts=${IncludeProducts
          }&includegroupmembership=${IncludeGroupMembership
          }&includedatasourcefieldaccesssetting=${IncludeDataSourceFieldAccessSetting
          }&includeaccesssettings=${IncludeAccessSettings
          }&includeconfigsettings=${IncludeConfigSettings
          }&includeproductcodebooks=${IncludeProductCodeBooks
          }&includetags=${IncludeTags
          }&includebranches=${IncludeBranches
          }&includesearches=${IncludeSearches
          }&includeformlayouts=${IncludeFormLayouts}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: null });
        if (data?.Result ?? false) {
          context.commit("_updateSelectedAdminOrganizationUser", data);
          context.commit("_updateOrganizationUser", data);
        }
        return data;
      }, "organizations - resetUserSettings");
    },
    async getUserAvatarData(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${userAvatarsUrl}?userrootid=${Number(params?.UserRootId ?? 0)}&includemetadata=${params?.IncludeMetadata ?? false}&includepresignedurl=${
          params?.IncludePresignedUrl ?? false
        }&includebase64encoding=${params?.IncludeBase64Encoding ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setUserAvatarData", data);
      }, "users - getUserAvatarData");
    },
    async addUserAvatar(context, userAvatar) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userAvatarsUrl, Payload: userAvatar });
        context.commit("setUserAvatarData", data);
      }, "users - addUserAvatar");
    },
    async clearUserAvatar(context) {
      await timeOperation(async () => {
        const formattedUrl = `${userAvatarsUrl}/clear`;
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("clearUserAvatarData");
      }, "users - clearUserAvatar");
    },
  },
};
