import _ from "lodash";
import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  DeleteVerb,
  organizationsUrl,
  organizationProductsUrl,
  organizationDocumentTemplateTypesUrl,
  organizationOrderDocumentTypesUrl,
  organizationSettingsUrl,
  organizationAccessSettingsUrl,
  userProductCodeBooksUrl,
  userBranchesUrl,
  userAccessSettingsUrl,
  userConfigSettingsUrl,
  groupMembersUrl,
  groupAccessSettingsUrl,
  groupsUrl,
  usersUrl,
  validateNamesUrl,
  organizationBranchesUrl,
  userSearchesUrl,
  userFormLayoutsUrl,
  groupSearchesUrl,
  groupFormLayoutsUrl,
} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import { GroupTypeAny } from "@/lib/settings";

export default {
  state: {
    organizations: [],
    organizationProducts: [],
    organizationGroups: [],
    organizationUsers: [],
    organizationGroupMembers: [],
    organizationDocumentTemplateTypes: [],
    organizationOrderDocumentTypes: [],
    organizationBranches: [],
    organizationSettings: [],
    organizationAccessSettings: [],
    organizationNameSearchData: [],
    organizationUserFormLayouts: [],
    organizationGroupSearches: [],
    organizationGroupFormLayouts: [],
    showOrganizationEditorTabs: true,
    isEditingOrganization: false,
    selectedAdminUser: {},
    selectedAdminGroup: {},
    selectedAdminOrganizationEditorRoute: "",
    selectedAdminOrganization: {},
    cachedOrganizationSettingsOrganizationRootId: -1,
    organizationVendorsList: [],
    userOrganizationUsers: [],
  },
  getters: {
    getOrganizationByRootId: (state) => (rootId) => {
      return state.organizations.find((organization) => Number(organization?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getOrganizationByDisplayName: (state) => (displayName) => {
      return state.organizations.find((organization) => String(organization?.DisplayName ?? "") === String(displayName ?? "")) ?? null;
    },
    getOrganizationBranchByRootId: (state) => (rootId) => {
      return state.organizationBranches.find((organizationBranch) => Number(organizationBranch?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getNewOrganizationGroupTemplate: (state) => (organizationRootId) => {
      let groupTemplate = { Id: 0, RootId: 0, OrganizationRootId: 0, Name: "", Description: "", GroupMembers: [], GroupAccessSettings: [] };
      if (organizationRootId > 0 && state.selectedAdminOrganization?.RootId > 0 && Number(state.selectedAdminOrganization.RootId) === Number(organizationRootId)) {
        // Set Organization Root Id
        groupTemplate.OrganizationRootId = Number(organizationRootId);
        // Set Group Root Id
        let groupTemplateRootId = 1;
        if (state.selectedAdminOrganization.OrganizationGroups?.length > 0) {
          state.selectedAdminOrganization.OrganizationGroups.forEach((organizationGroup) => {
            if (Number(organizationGroup.RootId) >= groupTemplateRootId) {
              groupTemplateRootId = Number(organizationGroup.RootId) + 1;
            }
          });
        }
        groupTemplate.RootId = groupTemplateRootId;
        // Set Possible Group Memberships
        let groupMembers = [];
        if (state.selectedAdminOrganization.OrganizationGroups?.length > 0) {
          let nextGroupMemberRootId = 1;
          state.selectedAdminOrganization.OrganizationGroups.forEach((organizationGroup) => {
            groupMembers.push({
              Id: 0,
              RootId: nextGroupMemberRootId++,
              OrganizationRootId: Number(organizationRootId),
              GroupRootId: Number(organizationGroup.RootId),
              MemberRootId: Number(groupTemplate.RootId),
              MemberType: 0,
              GroupName: organizationGroup.Name ?? "",
              GroupDescription: organizationGroup.Description ?? "",
              UserDisplayName: "",
              UserUsername: "",
              MemberGroupName: "",
              MemberGroupDescription: "",
              IsSelected: false,
            });
          });
        }
        groupTemplate.GroupMembers = groupMembers;
        // Set Group Access Settings
        let groupAccessSettings = [];
        if (state.selectedAdminOrganization.OrganizationProducts?.length > 0) {
          let nextGroupAccessSettingRootId = 1;
          state.selectedAdminOrganization.OrganizationProducts.forEach((organizationProduct) => {
            if (organizationProduct.ProductAccessSettings?.length > 0) {
              organizationProduct.ProductAccessSettings.forEach((productAccessSetting) => {
                groupAccessSettings.push({
                  Id: 0,
                  RootId: nextGroupAccessSettingRootId++,
                  OrganizationRootId: Number(organizationRootId),
                  GroupRootId: Number(groupTemplate.RootId),
                  ProductRootId: Number(organizationProduct.RootId),
                  ProductAccessSettingRootId: Number(productAccessSetting.RootId),
                  SettingValue: Boolean(productAccessSetting.DefaultValue),
                  ProductAccessSettingName: productAccessSetting.Name ?? "",
                  ProductAccessSettingDescription: productAccessSetting.Description ?? "",
                });
              });
            }
          });
        }
        groupTemplate.GroupAccessSettings = groupAccessSettings;
      }
      return groupTemplate;
    },
    getOrganizationDocumentTemplateTypeByRootId: (state) => (rootId) => {
      return (
        state.organizationDocumentTemplateTypes.find((organizationDocumentTemplateType) => Number(organizationDocumentTemplateType?.RootId ?? 0) === Number(rootId ?? 0)) ?? null
      );
    },
    getOrganizationOrderDocumentTypeByRootId: (state) => (rootId) => {
      return state.organizationOrderDocumentTypes.find((organizationOrderDocumentType) => Number(organizationOrderDocumentType?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getOrganizationOrderDocumentTypeByName: (state) => (name) => {
      return state.organizationOrderDocumentTypes.find((organizationOrderDocumentType) => String(organizationOrderDocumentType?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextOrganizationOrderDocumentTypeRootId:
      (state) =>
      ({ OrganizationRootId, ProductRootId }) => {
        let result = 1;
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        if (OrganizationRootId > 0 && ProductRootId > 0) {
          state.organizationOrderDocumentTypes.forEach((organizationOrderDocumentType) => {
            if (
              Number(organizationOrderDocumentType?.OrganizationRootId ?? 0) === OrganizationRootId &&
              Number(organizationOrderDocumentType?.ProductRootId ?? 0) === ProductRootId
            ) {
              if (Number(organizationOrderDocumentType?.RootId ?? 0) >= result) {
                result = Number(organizationOrderDocumentType.RootId) + 1;
              }
            }
          });
        }
        return result;
      },
    getOrganizationConfigSettingByKey: (state) => (params) => {
      const productRootId = Number(params?.ProductRootId ?? 0);
      const settingName = String(params?.SettingName ?? "");
      return (
        (
          state.organizationConfigSettings?.find(
            (organizationConfigSetting) =>
              Number(organizationConfigSetting?.ProductRootId ?? 0) === productRootId && String(organizationConfigSetting?.ProductConfigSettingName ?? "") === settingName
          ) ?? null
        )?.SettingValue ?? ""
      );
    },
    getOrganizationAccessSettingByKey: (state) => (params) => {
      const productRootId = Number(params?.ProductRootId ?? 0);
      const settingName = String(params?.SettingName ?? "");
      return (
        (
          state.organizationAccessSettings?.find(
            (organizationAccessSetting) =>
              Number(organizationAccessSetting?.ProductRootId ?? 0) === productRootId && String(organizationAccessSetting?.ProductConfigSettingName ?? "") === settingName
          ) ?? null
        )?.SettingValue ?? ""
      );
    },
    getOrganizationGroupByRootId: (state) => (rootId) => {
      return state.organizationGroups.find((organizationGroup) => Number(organizationGroup?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getOrganizationUserByRootId: (state) => (rootId) => {
      return state.organizationUsers.find((organizationUser) => Number(organizationUser?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getOrganizationUserByEmail: (state) => (email) => {
      return state.organizationUsers.find((organizationUser) => String(organizationUser?.Email ?? "") === String(email ?? "")) ?? null;
    },
    getSelectedAdminUserAccessSetting: (state) => (rootId) => {
      return state.selectedAdminUser?.UserAccessSettings?.find((userAccessSetting) => Number(userAccessSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getSelectedAdminUserConfigSetting: (state) => (rootId) => {
      return state.selectedAdminUser?.UserConfigSettings?.find((userConfigSetting) => Number(userConfigSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getSelectedAdminUserCodeBook: (state) => (rootId) => {
      return state.selectedAdminUser?.UserProductCodeBooks?.find((userProductCodeBook) => Number(userProductCodeBook?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getSelectedAdminUserProduct: (state) => (rootId) => {
      return state.selectedAdminUser?.UserProducts?.find((userProduct) => Number(userProduct?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getSelectedAdminUserBranch: (state) => (rootId) => {
      return state.selectedAdminUser?.UserBranches?.find((userBranch) => Number(userBranch?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getSelectedAdminUserGroupMember: (state) => (rootId) => {
      return state.selectedAdminUser?.GroupMembers?.find((groupMember) => Number(groupMember?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getSelectedAdminGroupAccessSetting: (state) => (rootId) => {
      return state.selectedAdminGroup?.GroupAccessSettings?.find((groupAccessSetting) => Number(groupAccessSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getSelectedAdminGroupGroupMember: (state) => (rootId) => {
      return state.selectedAdminGroup?.GroupMembers?.find((groupMember) => Number(groupMember?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getOrganizationEditorGeneralRoute: (state) => {
      return `/admin/organizations/organizations/edit/${state.selectedAdminOrganization?.RootId ?? 0}/general`;
    },
    isOrganizationNameTaken: (state) => (rootId) => {
      return (state.organizationNameSearchData?.find((organizationNameSearch) => Number(organizationNameSearch?.RootId ?? 0) !== Number(rootId ?? 0)) ?? null) != null;
    },
    getSelectedOrganizationProducts: (state) => {
      let result = [];
      state.organizationProducts.forEach((organizationProduct) => {
        if (organizationProduct.IsSelected) {
          result.push(_.cloneDeep(organizationProduct));
        }
      });
      return result.sort((op1, op2) => (op1.ProductDisplayName > op2.ProductDisplayName ? 1 : -1)) ?? [];
    },
    getOrganizationDocumentTemplateTypesForProduct: (state) => (productRootId) => {
      let result = [];
      state.organizationDocumentTemplateTypes.forEach((organizationDocumentTemplateType) => {
        if (Number(organizationDocumentTemplateType.ProductRootId) === Number(productRootId)) {
          result.push(_.cloneDeep(organizationDocumentTemplateType));
        }
      });
      return result.sort((odtt1, odtt2) => (odtt1.ProductDocumentTemplateTypeName > odtt2.ProductDocumentTemplateTypeName ? 1 : -1)) ?? [];
    },
    getOrganizationOrderDocumentTypesForProduct: (state) => (productRootId) => {
      let result = [];
      state.organizationOrderDocumentTypes.forEach((organizationOrderDocumentType) => {
        if (Number(organizationOrderDocumentType.ProductRootId) === Number(productRootId)) {
          result.push(_.cloneDeep(organizationOrderDocumentType));
        }
      });
      return result.sort((odtt1, odtt2) => (odtt1.ProductDocumentTemplateTypeName > odtt2.ProductDocumentTemplateTypeName ? 1 : -1)) ?? [];
    },
  },
  mutations: {
    setOrganizationVendorsList(state, organizationVendorsList) {
      state.organizationVendorsList = organizationVendorsList ?? [];
    },
    setSelectedAdminUser(state, selectedAdminUser) {
      state.selectedAdminUser = selectedAdminUser ?? {};
    },
    setSelectedAdminGroup(state, selectedAdminGroup) {
      state.selectedAdminGroup = selectedAdminGroup ?? {};
    },
    setSelectedAdminOrganizationEditorRoute(state, selectedAdminOrganizationEditorRoute) {
      state.selectedAdminOrganizationEditorRoute = selectedAdminOrganizationEditorRoute ?? "";
    },
    setShowOrganizationEditorTabs(state, showOrganizationEditorTabs) {
      state.showOrganizationEditorTabs = showOrganizationEditorTabs ?? false;
    },
    setCachedOrganizationSettingsOrganizationRootId(state, cachedOrganizationSettingsOrganizationRootId) {
      state.cachedOrganizationSettingsOrganizationRootId = Number(cachedOrganizationSettingsOrganizationRootId ?? -1);
    },
    clearOrganizationEditorData(state) {
      state.organizationProducts = [];
      state.organizationGroups = [];
      state.organizationUsers = [];
      state.organizationDocumentTemplateTypes = [];
      state.organizationOrderDocumentTypes = [];
      state.organizationSettings = [];
      state.organizationAccessSettings = [];
      state.organizationUserFormLayouts = [];
      state.organizationGroupSearches = [];
      state.organizationGroupFormLayouts = [];
    },
    setOrganizationBranches(state, organizationBranches) {
      state.organizationBranches = organizationBranches.sort((ob1, ob2) => (ob1.Name > ob2.Name ? 1 : -1)) ?? [];
    },
    _addOrganizationBranch(state, organizationBranch) {
      const organizationBranchesData = _.cloneDeep(state.organizationBranches) ?? [];
      organizationBranchesData.push(organizationBranch);
      state.organizationBranches = organizationBranchesData.sort((ob1, ob2) => (ob1.Name > ob2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationBranch(state, updateOrganizationBranch) {
      const rootId = Number(updateOrganizationBranch?.RootId ?? 0);
      if (rootId > 0) {
        let organizationBranchesData = _.cloneDeep(state.organizationBranches) ?? [];
        organizationBranchesData =
          [...organizationBranchesData.filter((organizationBranch) => Number(organizationBranch?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationBranch)].sort((ob1, ob2) =>
            ob1.Name > ob2.Name ? 1 : -1
          ) ?? [];
        state.organizationBranches = organizationBranchesData;
      }
    },
    _removeOrganizationBranch(state, deleteOrganizationBranch) {
      const rootId = Number(deleteOrganizationBranch?.RootId ?? 0);
      if (rootId > 0) {
        let organizationBranchesData = _.cloneDeep(state.organizationBranches) ?? [];
        organizationBranchesData =
          [...organizationBranchesData.filter((organizationBranch) => Number(organizationBranch?.RootId ?? 0) !== rootId)].sort((ob1, ob2) => (ob1.Name > ob2.Name ? 1 : -1)) ?? [];
        state.organizationBranches = organizationBranchesData;
      }
    },
    _addSelectedAdminOrganizationDocumentCategory(state, selectedAdminOrganizationDocumentCategory) {
      const selectedAdminOrganizationOrganizationDocumentCategoriesData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationDocumentCategories) ?? [];
      selectedAdminOrganizationOrganizationDocumentCategoriesData.push(selectedAdminOrganizationDocumentCategory);
      state.selectedAdminOrganization.OrganizationDocumentCategories =
        selectedAdminOrganizationOrganizationDocumentCategoriesData.sort((saodc1, saodc2) => (saodc1.Name > saodc2.Name ? 1 : -1)) ?? [];
    },
    _updateSelectedAdminOrganizationDocumentCategory(state, updateOrganizationDocumentCategory) {
      const rootId = Number(updateOrganizationDocumentCategory?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationDocumentCategories != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationDocumentCategoriesData = _.cloneDeep(state.selectedAdminOrganization.OrganizationDocumentCategories) ?? [];
        selectedAdminOrganizationOrganizationDocumentCategoriesData =
          [
            ...selectedAdminOrganizationOrganizationDocumentCategoriesData.filter((organizationDocumentCategory) => Number(organizationDocumentCategory?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationDocumentCategory),
          ].sort((saodc1, saodc2) => (saodc1.Name > saodc2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationDocumentCategories = selectedAdminOrganizationOrganizationDocumentCategoriesData;
      }
    },
    _removeSelectedAdminOrganizationDocumentCategory(state, deleteOrganizationDocumentCategory) {
      const rootId = Number(deleteOrganizationDocumentCategory?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationDocumentCategories != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationDocumentCategoriesData = _.cloneDeep(state.selectedAdminOrganization.OrganizationDocumentCategories) ?? [];
        selectedAdminOrganizationOrganizationDocumentCategoriesData =
          [
            ...selectedAdminOrganizationOrganizationDocumentCategoriesData.filter((organizationDocumentCategory) => Number(organizationDocumentCategory?.RootId ?? 0) !== rootId),
          ].sort((saodc1, saodc2) => (saodc1.Name > saodc2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationDocumentCategories = selectedAdminOrganizationOrganizationDocumentCategoriesData;
      }
    },
    _addSelectedAdminOrganizationDocumentType(state, selectedAdminOrganizationDocumentType) {
      const selectedAdminOrganizationOrganizationDocumentTypesData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationDocumentTypes) ?? [];
      selectedAdminOrganizationOrganizationDocumentTypesData.push(selectedAdminOrganizationDocumentType);
      state.selectedAdminOrganization.OrganizationDocumentTypes =
        selectedAdminOrganizationOrganizationDocumentTypesData.sort((saodt1, saodt2) => (saodt1.Name > saodt2.Name ? 1 : -1)) ?? [];
    },
    _updateSelectedAdminOrganizationDocumentType(state, updateOrganizationDocumentType) {
      const rootId = Number(updateOrganizationDocumentType?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationDocumentTypes != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationDocumentTypesData = _.cloneDeep(state.selectedAdminOrganization.OrganizationDocumentTypes) ?? [];
        selectedAdminOrganizationOrganizationDocumentTypesData =
          [
            ...selectedAdminOrganizationOrganizationDocumentTypesData.filter((organizationDocumentType) => Number(organizationDocumentType?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationDocumentType),
          ].sort((saodt1, saodt2) => (saodt1.Name > saodt2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationDocumentTypes = selectedAdminOrganizationOrganizationDocumentTypesData;
      }
    },
    _removeSelectedAdminOrganizationDocumentType(state, deleteOrganizationDocumentType) {
      const rootId = Number(deleteOrganizationDocumentType?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationDocumentTypes != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationDocumentTypesData = _.cloneDeep(state.selectedAdminOrganization.OrganizationDocumentTypes) ?? [];
        selectedAdminOrganizationOrganizationDocumentTypesData =
          [...selectedAdminOrganizationOrganizationDocumentTypesData.filter((organizationDocumentType) => Number(organizationDocumentType?.RootId ?? 0) !== rootId)].sort(
            (saodt1, saodt2) => (saodt1.Name > saodt2.Name ? 1 : -1)
          ) ?? [];
        state.selectedAdminOrganization.OrganizationDocumentTypes = selectedAdminOrganizationOrganizationDocumentTypesData;
      }
    },
    _addSelectedAdminOrganizationDocumentTemplateType(state, selectedAdminOrganizationDocumentTemplateType) {
      const selectedAdminOrganizationOrganizationDocumentTemplateTypesData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationDocumentTemplateTypes) ?? [];
      selectedAdminOrganizationOrganizationDocumentTemplateTypesData.push(selectedAdminOrganizationDocumentTemplateType);
      state.selectedAdminOrganization.OrganizationDocumentTemplateTypes =
        selectedAdminOrganizationOrganizationDocumentTemplateTypesData.sort((saodtt1, saodtt2) => (saodtt1.Name > saodtt2.Name ? 1 : -1)) ?? [];
    },
    _updateSelectedAdminOrganizationDocumentTemplateType(state, updateOrganizationDocumentTemplateType) {
      const rootId = Number(updateOrganizationDocumentTemplateType?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationDocumentTemplateTypes != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationDocumentTemplateTypesData = _.cloneDeep(state.selectedAdminOrganization.OrganizationDocumentTemplateTypes) ?? [];
        selectedAdminOrganizationOrganizationDocumentTemplateTypesData =
          [
            ...selectedAdminOrganizationOrganizationDocumentTemplateTypesData.filter(
              (organizationDocumentTemplateType) => Number(organizationDocumentTemplateType?.RootId ?? 0) !== rootId
            ),
            _.cloneDeep(updateOrganizationDocumentTemplateType),
          ].sort((saodtt1, saodtt2) => (saodtt1.Name > saodtt2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationDocumentTemplateTypes = selectedAdminOrganizationOrganizationDocumentTemplateTypesData;
      }
    },
    _removeSelectedAdminOrganizationDocumentTemplateType(state, deleteOrganizationDocumentTemplateType) {
      const rootId = Number(deleteOrganizationDocumentTemplateType?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationDocumentTemplateTypes != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationDocumentTemplateTypesData = _.cloneDeep(state.selectedAdminOrganization.OrganizationDocumentTemplateTypes) ?? [];
        selectedAdminOrganizationOrganizationDocumentTemplateTypesData =
          [
            ...selectedAdminOrganizationOrganizationDocumentTemplateTypesData.filter(
              (organizationDocumentTemplateType) => Number(organizationDocumentTemplateType?.RootId ?? 0) !== rootId
            ),
          ].sort((saodtt1, saodtt2) => (saodtt1.Name > saodtt2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationDocumentTemplateTypes = selectedAdminOrganizationOrganizationDocumentTemplateTypesData;
      }
    },
    setOrganizationDocumentTemplateTypes(state, organizationDocumentTemplateTypesData) {
      state.organizationDocumentTemplateTypes = organizationDocumentTemplateTypesData?.sort((odtt1, odtt2) => (odtt1.Position > odtt2.Position ? 1 : -1)) ?? [];
    },
    _addOrganizationDocumentTemplateType(state, organizationDocumentTemplateType) {
      const organizationDocumentTemplateTypesData = _.cloneDeep(state.organizationDocumentTemplateTypes) ?? [];
      organizationDocumentTemplateTypesData.push(organizationDocumentTemplateType);
      state.organizationDocumentTemplateTypes = organizationDocumentTemplateTypesData.sort((odtt1, odtt2) => (odtt1.Position > odtt2.Position ? 1 : -1)) ?? [];
    },
    _updateOrganizationDocumentTemplateType(state, updateOrganizationDocumentTemplateType) {
      const rootId = Number(updateOrganizationDocumentTemplateType?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentTemplateTypesData = _.cloneDeep(state.organizationDocumentTemplateTypes) ?? [];
        organizationDocumentTemplateTypesData =
          [
            ...organizationDocumentTemplateTypesData.filter((organizationDocumentTemplateType) => Number(organizationDocumentTemplateType?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationDocumentTemplateType),
          ].sort((odtt1, odtt2) => (odtt1.Position > odtt2.Position ? 1 : -1)) ?? [];
        state.organizationDocumentTemplateTypes = organizationDocumentTemplateTypesData;
      }
    },
    _removeOrganizationDocumentTemplateType(state, deleteOrganizationDocumentTemplateType) {
      const rootId = Number(deleteOrganizationDocumentTemplateType?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentTemplateTypesData = _.cloneDeep(state.organizationDocumentTemplateTypes) ?? [];
        organizationDocumentTemplateTypesData =
          [...organizationDocumentTemplateTypesData.filter((organizationDocumentTemplateType) => Number(organizationDocumentTemplateType?.RootId ?? 0) !== rootId)].sort(
            (odtt1, odtt2) => (odtt1.Position > odtt2.Position ? 1 : -1)
          ) ?? [];
        state.organizationDocumentTemplateTypes = organizationDocumentTemplateTypesData;
      }
    },
    _addSelectedAdminOrganizationOrderDocumentType(state, selectedAdminOrganizationOrderDocumentType) {
      const selectedAdminOrganizationOrganizationOrderDocumentTypesData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationOrderDocumentTypes) ?? [];
      selectedAdminOrganizationOrganizationOrderDocumentTypesData.push(selectedAdminOrganizationOrderDocumentType);
      state.selectedAdminOrganization.OrganizationOrderDocumentTypes =
        selectedAdminOrganizationOrganizationOrderDocumentTypesData.sort((saodtt1, saodtt2) => (saodtt1.Name > saodtt2.Name ? 1 : -1)) ?? [];
    },
    _updateSelectedAdminOrganizationOrderDocumentType(state, params) {
      const oldRootId = Number(params?.RootId ?? 0);
      const updateOrganizationOrderDocumentType = params?.Data ?? {};
      const rootId = Number(updateOrganizationOrderDocumentType.RootId ?? 0);
      if (oldRootId > 0 && rootId > 0) {
        let selectedAdminOrganizationOrganizationOrderDocumentTypesData = _.cloneDeep(state.selectedAdminOrganization.OrganizationOrderDocumentTypes) ?? [];
        selectedAdminOrganizationOrganizationOrderDocumentTypesData =
          [
            ...selectedAdminOrganizationOrganizationOrderDocumentTypesData.filter(
              (organizationOrderDocumentType) => Number(organizationOrderDocumentType?.RootId ?? 0) !== oldRootId
            ),
            _.cloneDeep(updateOrganizationOrderDocumentType),
          ].sort((saodtt1, saodtt2) => (saodtt1.Name > saodtt2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationOrderDocumentTypes = selectedAdminOrganizationOrganizationOrderDocumentTypesData;
      }
    },
    _removeSelectedAdminOrganizationOrderDocumentType(state, deleteOrganizationOrderDocumentType) {
      const rootId = Number(deleteOrganizationOrderDocumentType?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationOrderDocumentTypes != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationOrderDocumentTypesData = _.cloneDeep(state.selectedAdminOrganization.OrganizationOrderDocumentTypes) ?? [];
        selectedAdminOrganizationOrganizationOrderDocumentTypesData =
          [
            ...selectedAdminOrganizationOrganizationOrderDocumentTypesData.filter((organizationOrderDocumentType) => Number(organizationOrderDocumentType?.RootId ?? 0) !== rootId),
          ].sort((saodtt1, saodtt2) => (saodtt1.Name > saodtt2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationOrderDocumentTypes = selectedAdminOrganizationOrganizationOrderDocumentTypesData;
      }
    },
    setOrganizationOrderDocumentTypes(state, organizationOrderDocumentTypesData) {
      state.organizationOrderDocumentTypes = organizationOrderDocumentTypesData?.sort((odtt1, odtt2) => (odtt1.Name > odtt2.Name ? 1 : -1)) ?? [];
    },
    _addOrganizationOrderDocumentType(state, organizationOrderDocumentType) {
      const organizationOrderDocumentTypesData = _.cloneDeep(state.organizationOrderDocumentTypes) ?? [];
      organizationOrderDocumentTypesData.push(organizationOrderDocumentType);
      state.organizationOrderDocumentTypes = organizationOrderDocumentTypesData.sort((odtt1, odtt2) => (odtt1.Name > odtt2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationOrderDocumentType(state, params) {
      const oldRootId = Number(params?.RootId ?? 0);
      const updateOrganizationOrderDocumentType = params?.Data ?? {};
      const rootId = Number(updateOrganizationOrderDocumentType.RootId ?? 0);
      if (oldRootId > 0 && rootId > 0) {
        let organizationOrderDocumentTypesData = _.cloneDeep(state.organizationOrderDocumentTypes) ?? [];
        organizationOrderDocumentTypesData =
          [
            ...organizationOrderDocumentTypesData.filter((organizationOrderDocumentType) => Number(organizationOrderDocumentType?.RootId ?? 0) !== oldRootId),
            _.cloneDeep(updateOrganizationOrderDocumentType),
          ].sort((odtt1, odtt2) => (odtt1.Name > odtt2.Name ? 1 : -1)) ?? [];
        state.organizationOrderDocumentTypes = organizationOrderDocumentTypesData;
      }
    },
    _removeOrganizationOrderDocumentType(state, deleteOrganizationOrderDocumentType) {
      const rootId = Number(deleteOrganizationOrderDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let organizationOrderDocumentTypesData = _.cloneDeep(state.organizationOrderDocumentTypes) ?? [];
        organizationOrderDocumentTypesData =
          [...organizationOrderDocumentTypesData.filter((organizationOrderDocumentType) => Number(organizationOrderDocumentType?.RootId ?? 0) !== rootId)].sort((odtt1, odtt2) =>
            odtt1.Name > odtt2.Name ? 1 : -1
          ) ?? [];
        state.organizationOrderDocumentTypes = organizationOrderDocumentTypesData;
      }
    },
    _addSelectedAdminOrganizationSetting(state, selectedAdminOrganizationSetting) {
      const selectedAdminOrganizationOrganizationSettingsData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationSettings) ?? [];
      selectedAdminOrganizationOrganizationSettingsData.push(selectedAdminOrganizationSetting);
      state.selectedAdminOrganization.OrganizationSettings = selectedAdminOrganizationOrganizationSettingsData.sort((saos1, saos2) => (saos1.Name > saos2.Name ? 1 : -1)) ?? [];
    },
    _updateSelectedAdminOrganizationSetting(state, updateOrganizationSetting) {
      const rootId = Number(updateOrganizationSetting?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationSettings != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationSettingsData = _.cloneDeep(state.selectedAdminOrganization.OrganizationSettings) ?? [];
        selectedAdminOrganizationOrganizationSettingsData =
          [
            ...selectedAdminOrganizationOrganizationSettingsData.filter((organizationSetting) => Number(organizationSetting?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationSetting),
          ].sort((saos1, saos2) => (saos1.Name > saos2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationSettings = selectedAdminOrganizationOrganizationSettingsData;
      }
    },
    _removeSelectedAdminOrganizationSetting(state, deleteOrganizationSetting) {
      const rootId = Number(deleteOrganizationSetting?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationSettings != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationSettingsData = _.cloneDeep(state.selectedAdminOrganization.OrganizationSettings) ?? [];
        selectedAdminOrganizationOrganizationSettingsData =
          [...selectedAdminOrganizationOrganizationSettingsData.filter((organizationSetting) => Number(organizationSetting?.RootId ?? 0) !== rootId)].sort((saos1, saos2) =>
            saos1.Name > saos2.Name ? 1 : -1
          ) ?? [];
        state.selectedAdminOrganization.OrganizationSettings = selectedAdminOrganizationOrganizationSettingsData;
      }
    },
    setOrganizationSettings(state, organizationSettingsData) {
      state.organizationSettings = organizationSettingsData?.sort((os1, os2) => (os1.Name > os2.Name ? 1 : -1)) ?? [];
    },
    _addOrganizationSetting(state, organizationSetting) {
      const organizationSettingsData = _.cloneDeep(state.organizationSettings) ?? [];
      organizationSettingsData.push(organizationSetting);
      state.organizationSettings = organizationSettingsData.sort((os1, os2) => (os1.Name > os2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationSetting(state, updateOrganizationSetting) {
      const rootId = Number(updateOrganizationSetting?.RootId ?? 0);
      if (rootId > 0) {
        let organizationSettingsData = _.cloneDeep(state.organizationSettings) ?? [];
        organizationSettingsData =
          [...organizationSettingsData.filter((organizationSetting) => Number(organizationSetting?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationSetting)].sort(
            (os1, os2) => (os1.Name > os2.Name ? 1 : -1)
          ) ?? [];
        state.organizationSettings = organizationSettingsData;
      }
    },
    _removeOrganizationSetting(state, deleteOrganizationSetting) {
      const rootId = Number(deleteOrganizationSetting?.RootId ?? 0);
      if (rootId > 0) {
        let organizationSettingsData = _.cloneDeep(state.organizationSettings) ?? [];
        organizationSettingsData =
          [...organizationSettingsData.filter((organizationSetting) => Number(organizationSetting?.RootId ?? 0) !== rootId)].sort((os1, os2) => (os1.Name > os2.Name ? 1 : -1)) ??
          [];
        state.organizationSettings = organizationSettingsData;
      }
    },
    _addSelectedAdminOrganizationAccessSetting(state, selectedAdminOrganizationAccessSetting) {
      const selectedAdminOrganizationOrganizationAccessSettingsData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationAccessSettings) ?? [];
      selectedAdminOrganizationOrganizationAccessSettingsData.push(selectedAdminOrganizationAccessSetting);
      state.selectedAdminOrganization.OrganizationAccessSettings =
        selectedAdminOrganizationOrganizationAccessSettingsData.sort((saoas1, saoas2) => (saoas1.Name > saoas2.Name ? 1 : -1)) ?? [];
    },
    _updateSelectedAdminOrganizationAccessSetting(state, updateOrganizationAccessSetting) {
      const rootId = Number(updateOrganizationAccessSetting?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationAccessSettings != null && rootId > 0) {
        let organizationAccessSetting =
          state.selectedAdminOrganization.OrganizationAccessSettings.find((organizationAccessSetting) => Number(organizationAccessSetting?.RootId ?? 0) === rootId) ?? null;
        if (organizationAccessSetting?.RootId > 0) {
          organizationAccessSetting.SettingValue = updateOrganizationAccessSetting.SettingValue;
        }
      }
    },
    _removeSelectedAdminOrganizationAccessSetting(state, deleteOrganizationAccessSetting) {
      const rootId = Number(deleteOrganizationAccessSetting?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationAccessSettings != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationAccessSettingsData = _.cloneDeep(state.selectedAdminOrganization.OrganizationAccessSettings) ?? [];
        selectedAdminOrganizationOrganizationAccessSettingsData =
          [...selectedAdminOrganizationOrganizationAccessSettingsData.filter((organizationAccessSetting) => Number(organizationAccessSetting?.RootId ?? 0) !== rootId)].sort(
            (saoas1, saoas2) => (saoas1.Name > saoas2.Name ? 1 : -1)
          ) ?? [];
        state.selectedAdminOrganization.OrganizationAccessSettings = selectedAdminOrganizationOrganizationAccessSettingsData;
      }
    },
    setOrganizationAccessSettings(state, organizationAccessSettingsData) {
      state.organizationAccessSettings = organizationAccessSettingsData?.sort((oas1, oas2) => (oas1.Name > oas2.Name ? 1 : -1)) ?? [];
    },
    _addOrganizationAccessSetting(state, organizationAccessSetting) {
      const organizationAccessSettingsData = _.cloneDeep(state.organizationAccessSettings) ?? [];
      organizationAccessSettingsData.push(organizationAccessSetting);
      state.organizationAccessSettings = organizationAccessSettingsData.sort((oas1, oas2) => (oas1.Name > oas2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationAccessSetting(state, updateOrganizationAccessSetting) {
      const rootId = Number(updateOrganizationAccessSetting?.RootId ?? 0);
      if (rootId > 0) {
        let organizationAccessSetting = state.organizationAccessSettings?.find((organizationAccessSetting) => Number(organizationAccessSetting?.RootId ?? 0) === rootId) ?? null;
        if (organizationAccessSetting?.RootId > 0) {
          organizationAccessSetting.SettingValue = updateOrganizationAccessSetting.SettingValue;
        }
      }
    },
    _removeOrganizationAccessSetting(state, deleteOrganizationAccessSetting) {
      const rootId = Number(deleteOrganizationAccessSetting?.RootId ?? 0);
      if (rootId > 0) {
        let organizationAccessSettingsData = _.cloneDeep(state.organizationAccessSettings) ?? [];
        organizationAccessSettingsData =
          [...organizationAccessSettingsData.filter((organizationAccessSetting) => Number(organizationAccessSetting?.RootId ?? 0) !== rootId)].sort((oas1, oas2) =>
            oas1.Name > oas2.Name ? 1 : -1
          ) ?? [];
        state.organizationAccessSettings = organizationAccessSettingsData;
      }
    },
    _addSelectedAdminOrganizationGroup(state, selectedAdminOrganizationGroup) {
      if (selectedAdminOrganizationGroup?.RootId > 0) {
        selectedAdminOrganizationGroup.Name = String(selectedAdminOrganizationGroup.Name ?? "");
        const selectedAdminOrganizationOrganizationGroupsData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationGroups) ?? [];
        selectedAdminOrganizationOrganizationGroupsData.push(selectedAdminOrganizationGroup);
        state.selectedAdminOrganization.OrganizationGroups = selectedAdminOrganizationOrganizationGroupsData.sort((saog1, saog2) => (saog1.Name > saog2.Name ? 1 : -1)) ?? [];
      }
    },
    _updateSelectedAdminOrganizationGroup(state, updateOrganizationGroup) {
      const rootId = Number(updateOrganizationGroup?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationGroups != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationGroupsData = _.cloneDeep(state.selectedAdminOrganization.OrganizationGroups) ?? [];
        selectedAdminOrganizationOrganizationGroupsData =
          [
            ...selectedAdminOrganizationOrganizationGroupsData.filter((organizationGroup) => Number(organizationGroup?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationGroup),
          ].sort((saog1, saog2) => (saog1.Name > saog2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationGroups = selectedAdminOrganizationOrganizationGroupsData;
      }
    },
    _removeSelectedAdminOrganizationGroup(state, deleteOrganizationGroup) {
      const rootId = Number(deleteOrganizationGroup?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationGroups != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationGroupsData = _.cloneDeep(state.selectedAdminOrganization.OrganizationGroups) ?? [];
        selectedAdminOrganizationOrganizationGroupsData =
          [...selectedAdminOrganizationOrganizationGroupsData.filter((organizationGroup) => Number(organizationGroup?.RootId ?? 0) !== rootId)].sort((saog1, saog2) =>
            saog1.Name > saog2.Name ? 1 : -1
          ) ?? [];
        state.selectedAdminOrganization.OrganizationGroups = selectedAdminOrganizationOrganizationGroupsData;
      }
    },
    setOrganizationGroupMembers(state, organizationGroupMembers) {
      state.organizationGroupMembers = organizationGroupMembers ?? [];
    },
    _updateOrganizationGroupMember(state, updateOrganizationGroupMember) {
      if (updateOrganizationGroupMember?.RootId > 0) {
        let organizationGroupMembersData = _.cloneDeep(state.organizationGroupMembers) ?? [];
        let foundOrganizationGroupMember = organizationGroupMembersData.find(
          (organizationGroupMember) => Number(updateOrganizationGroupMember.RootId ?? 0) === Number(organizationGroupMember?.RootId ?? 0)
        );
        if (foundOrganizationGroupMember?.RootId > 0) {
          organizationGroupMembersData =
            [
              ...organizationGroupMembersData.filter(
                (organizationGroupMember) => Number(updateOrganizationGroupMember.RootId ?? 0) === Number(organizationGroupMember?.RootId ?? 0)
              ),
              _.cloneDeep(updateOrganizationGroupMember),
            ] ?? [];
        } else {
          organizationGroupMembersData.push(updateOrganizationGroupMember);
        }
        state.organizationGroupMembers = organizationGroupMembersData;
      }
    },
    setOrganizationGroups(state, organizationGroupsData) {
      state.organizationGroups = organizationGroupsData?.sort((og1, og2) => (og1.Name > og2.Name ? 1 : -1)) ?? [];
    },
    _addOrganizationGroup(state, organizationGroup) {
      if (organizationGroup?.RootId > 0) {
        organizationGroup.Name = String(organizationGroup.Name ?? "");
        const organizationGroupsData = _.cloneDeep(state.organizationGroups) ?? [];
        organizationGroupsData.push(organizationGroup);
        state.organizationGroups = organizationGroupsData.sort((og1, og2) => (og1.Name > og2.Name ? 1 : -1)) ?? [];
      }
    },
    _updateOrganizationGroup(state, updateOrganizationGroup) {
      const rootId = Number(updateOrganizationGroup?.RootId ?? 0);
      if (rootId > 0) {
        let organizationGroupsData = _.cloneDeep(state.organizationGroups) ?? [];
        organizationGroupsData =
          [...organizationGroupsData.filter((organizationGroup) => Number(organizationGroup?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationGroup)].sort((og1, og2) =>
            og1.Name > og2.Name ? 1 : -1
          ) ?? [];
        state.organizationGroups = organizationGroupsData;
      }
    },
    _removeOrganizationGroup(state, deleteOrganizationGroup) {
      const rootId = Number(deleteOrganizationGroup?.RootId ?? 0);
      if (rootId > 0) {
        let organizationGroupsData = _.cloneDeep(state.organizationGroups) ?? [];
        organizationGroupsData =
          [...organizationGroupsData.filter((organizationGroup) => Number(organizationGroup?.RootId ?? 0) !== rootId)].sort((og1, og2) => (og1.Name > og2.Name ? 1 : -1)) ?? [];
        state.organizationGroups = organizationGroupsData;
      }
    },
    _addSelectedAdminOrganizationUser(state, selectedAdminOrganizationUser) {
      const selectedAdminOrganizationOrganizationUsersData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationUsers) ?? [];
      selectedAdminOrganizationOrganizationUsersData.push(selectedAdminOrganizationUser);
      state.selectedAdminOrganization.OrganizationUsers =
        selectedAdminOrganizationOrganizationUsersData.sort((saou1, saou2) => (saou1.DisplayName > saou2.DisplayName ? 1 : -1)) ?? [];
    },
    _updateSelectedAdminOrganizationUser(state, updateOrganizationUser) {
      const rootId = Number(updateOrganizationUser?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationUsers != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationUsersData = _.cloneDeep(state.selectedAdminOrganization.OrganizationUsers) ?? [];
        selectedAdminOrganizationOrganizationUsersData =
          [
            ...selectedAdminOrganizationOrganizationUsersData.filter((organizationUser) => Number(organizationUser?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationUser),
          ].sort((saou1, saou2) => (saou1.DisplayName > saou2.DisplayName ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationUsers = selectedAdminOrganizationOrganizationUsersData;
      }
    },
    _removeSelectedAdminOrganizationUser(state, deleteOrganizationUser) {
      const rootId = Number(deleteOrganizationUser?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationUsers != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationUsersData = _.cloneDeep(state.selectedAdminOrganization.OrganizationUsers) ?? [];
        selectedAdminOrganizationOrganizationUsersData =
          [...selectedAdminOrganizationOrganizationUsersData.filter((organizationUser) => Number(organizationUser?.RootId ?? 0) !== rootId)].sort((saou1, saou2) =>
            saou1.DisplayName > saou2.DisplayName ? 1 : -1
          ) ?? [];
        state.selectedAdminOrganization.OrganizationUsers = selectedAdminOrganizationOrganizationUsersData;
      }
    },
    setOrganizationUsers(state, organizationUsersData) {
      state.organizationUsers = organizationUsersData?.sort((ou1, ou2) => (ou1.DisplayName > ou2.DisplayName ? 1 : -1)) ?? [];
    },
    setUserOrganizationUsers(state, userOrganizationUsersData) {
      state.userOrganizationUsers = userOrganizationUsersData?.sort((ou1, ou2) => (ou1.DisplayName > ou2.DisplayName ? 1 : -1)) ?? [];
    },
    _addOrganizationUser(state, organizationUser) {
      const organizationUsersData = _.cloneDeep(state.organizationUsers) ?? [];
      organizationUsersData.push(organizationUser);
      state.organizationUsers = organizationUsersData.sort((ou1, ou2) => (ou1.DisplayName > ou2.DisplayName ? 1 : -1)) ?? [];
    },
    _updateOrganizationUser(state, updateOrganizationUser) {
      const rootId = Number(updateOrganizationUser?.RootId ?? 0);
      if (rootId > 0) {
        let organizationUsersData = _.cloneDeep(state.organizationUsers) ?? [];
        organizationUsersData =
          [...organizationUsersData.filter((organizationUser) => Number(organizationUser?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationUser)].sort((ou1, ou2) =>
            ou1.DisplayName > ou2.DisplayName ? 1 : -1
          ) ?? [];
        state.organizationUsers = organizationUsersData;
      }
    },
    _removeOrganizationUser(state, deleteOrganizationUser) {
      const rootId = Number(deleteOrganizationUser?.RootId ?? 0);
      if (rootId > 0) {
        let organizationUsersData = _.cloneDeep(state.organizationUsers) ?? [];
        organizationUsersData =
          [...organizationUsersData.filter((organizationUser) => Number(organizationUser?.RootId ?? 0) !== rootId)].sort((ou1, ou2) =>
            ou1.DisplayName > ou2.DisplayName ? 1 : -1
          ) ?? [];
        state.organizationUsers = organizationUsersData;
      }
    },
    _addSelectedAdminOrganizationProduct(state, selectedAdminOrganizationProduct) {
      const selectedAdminOrganizationOrganizationProductsData = _.cloneDeep(state.selectedAdminOrganization?.OrganizationProducts) ?? [];
      selectedAdminOrganizationOrganizationProductsData.push(selectedAdminOrganizationProduct);
      state.selectedAdminOrganization.OrganizationProducts = selectedAdminOrganizationOrganizationProductsData.sort((saop1, saop2) => (saop1.Name > saop2.Name ? 1 : -1)) ?? [];
    },
    _updateSelectedAdminOrganizationProduct(state, updateOrganizationProduct) {
      const rootId = Number(updateOrganizationProduct?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationProducts != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationProductsData = _.cloneDeep(state.selectedAdminOrganization.OrganizationProducts) ?? [];
        selectedAdminOrganizationOrganizationProductsData =
          [
            ...selectedAdminOrganizationOrganizationProductsData.filter((organizationProduct) => Number(organizationProduct?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationProduct),
          ].sort((saop1, saop2) => (saop1.Name > saop2.Name ? 1 : -1)) ?? [];
        state.selectedAdminOrganization.OrganizationProducts = selectedAdminOrganizationOrganizationProductsData;
      }
    },
    _removeSelectedAdminOrganizationProduct(state, deleteOrganizationProduct) {
      const rootId = Number(deleteOrganizationProduct?.RootId ?? 0);
      if (state.selectedAdminOrganization?.OrganizationProducts != null && rootId > 0) {
        let selectedAdminOrganizationOrganizationProductsData = _.cloneDeep(state.selectedAdminOrganization.OrganizationProducts) ?? [];
        selectedAdminOrganizationOrganizationProductsData =
          [...selectedAdminOrganizationOrganizationProductsData.filter((organizationProduct) => Number(organizationProduct?.RootId ?? 0) !== rootId)].sort((saop1, saop2) =>
            saop1.Name > saop2.Name ? 1 : -1
          ) ?? [];
        state.selectedAdminOrganization.OrganizationProducts = selectedAdminOrganizationOrganizationProductsData;
      }
    },
    setOrganizationProducts(state, organizationProductsData) {
      state.organizationProducts = organizationProductsData?.sort((op1, op2) => (op1.ProductDisplayName > op2.ProductDisplayName ? 1 : -1)) ?? [];
    },
    _addOrganizationProduct(state, organizationProduct) {
      const organizationProductsData = _.cloneDeep(state.organizationProducts) ?? [];
      organizationProductsData.push(organizationProduct);
      state.organizationProducts = organizationProductsData.sort((op1, op2) => (op1.ProductDisplayName > op2.ProductDisplayName ? 1 : -1)) ?? [];
    },
    _updateOrganizationProduct(state, updateOrganizationProduct) {
      const rootId = Number(updateOrganizationProduct?.RootId ?? 0);
      if (rootId > 0) {
        let organizationProductsData = _.cloneDeep(state.organizationProducts) ?? [];
        organizationProductsData =
          [...organizationProductsData.filter((organizationProduct) => Number(organizationProduct?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationProduct)].sort(
            (op1, op2) => (op1.ProductDisplayName > op2.ProductDisplayName ? 1 : -1)
          ) ?? [];
        state.organizationProducts = organizationProductsData;
      }
    },
    _removeOrganizationProduct(state, deleteOrganizationProduct) {
      const rootId = Number(deleteOrganizationProduct?.RootId ?? 0);
      if (rootId > 0) {
        let organizationProductsData = _.cloneDeep(state.organizationProducts) ?? [];
        organizationProductsData =
          [...organizationProductsData.filter((organizationProduct) => Number(organizationProduct?.RootId ?? 0) !== rootId)].sort((op1, op2) =>
            op1.ProductDisplayName > op2.ProductDisplayName ? 1 : -1
          ) ?? [];
        state.organizationProducts = organizationProductsData;
      }
    },
    setSelectedAdminOrganization(state, selectedAdminOrganization) {
      state.selectedAdminOrganization = selectedAdminOrganization ?? {};
    },
    setShowRequiredValuesError(state, showRequiredValuesError) {
      state.showRequiredValuesError = showRequiredValuesError ?? false;
    },
    setIsEditingOrganization(state, isEditingOrganization) {
      state.isEditingOrganization = isEditingOrganization ?? false;
    },
    setOrganizationData(state, organizations) {
      state.organizations = organizations?.sort((org1, org2) => (org1.DisplayName > org2.DisplayName ? 1 : -1)) ?? [];
    },
    clearOrganizationData(state) {
      state.organizations = [];
    },
    setOrganizationNameSearchData(state, nameSearchData) {
      state.organizationNameSearchData = nameSearchData ?? [];
    },
    _addOrganization(state, organization) {
      const organizationsData = _.cloneDeep(state.organizations) ?? [];
      organizationsData.push(organization);
      state.organizations = organizationsData.sort((org1, org2) => (org1.DisplayName > org2.DisplayName ? 1 : -1)) ?? [];
    },
    _updateOrganization(state, updateOrganization) {
      const rootId = Number(updateOrganization?.RootId ?? 0);
      if (rootId > 0) {
        let organizationsData = _.cloneDeep(state.organizations) ?? [];
        organizationsData =
          [...organizationsData.filter((organization) => Number(organization?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganization)].sort((org1, org2) =>
            org1.DisplayName > org2.DisplayName ? 1 : -1
          ) ?? [];
        state.organizations = organizationsData;
      }
    },
    _removeOrganization(state, deleteOrganization) {
      const rootId = Number(deleteOrganization?.RootId ?? 0);
      if (rootId > 0) {
        let organizationsData = _.cloneDeep(state.organizations) ?? [];
        organizationsData =
          [...organizationsData.filter((organization) => Number(organization?.RootId ?? 0) !== rootId)].sort((org1, org2) => (org1.DisplayName > org2.DisplayName ? 1 : -1)) ?? [];
        state.organizations = organizationsData;
      }
    },
    updateSelectedAdminOrganizationGeneralValues(state, values) {
      state.selectedAdminOrganization.Name = values.Name ?? "";
      state.selectedAdminOrganization.DisplayName = values.DisplayName ?? "";
      state.selectedAdminOrganization.Aliases = values.Aliases ?? "";
      state.selectedAdminOrganization.Description = values.Description ?? "";
      state.selectedAdminOrganization.ContactEmail = values.ContactEmail ?? "";
      state.selectedAdminOrganization.ContactPhone = values.ContactPhone ?? "";
      state.selectedAdminOrganization.Address1 = values.Address1 ?? "";
      state.selectedAdminOrganization.Address2 = values.Address2 ?? "";
      state.selectedAdminOrganization.City = values.City ?? "";
      state.selectedAdminOrganization.State = values.State ?? "";
      state.selectedAdminOrganization.PostalCode = values.PostalCode ?? "";
      state.selectedAdminOrganization.WebsiteUrl = values.WebsiteUrl ?? "";
    },
    _updateSelectedAdminOrganizationProductSelectedStatus(state, updatedOrganizationProduct) {
      let organizationProduct =
        state.selectedAdminOrganization?.OrganizationProducts?.find(
          (organizationProduct) => Number(organizationProduct?.RootId ?? 0) === Number(updatedOrganizationProduct?.RootId ?? 0)
        ) ?? null;
      if (organizationProduct != null) {
        organizationProduct.IsSelected = updatedOrganizationProduct?.IsSelected ?? false;
      }
    },
    _updateSelectedAdminUserProduct(state, updatedUserProduct) {
      if (state.selectedAdminUser?.UserProducts?.length > 0 && updatedUserProduct != null) {
        state.selectedAdminUser.UserProducts.forEach((userProduct) => {
          if (Number(userProduct.RootId) === Number(updatedUserProduct.RootId)) {
            userProduct.IsSelected = updatedUserProduct.Enabled;
            userProduct.Enabled = updatedUserProduct.Enabled;
            userProduct.DefaultProduct = updatedUserProduct.DefaultProduct;
          } else if (updatedUserProduct.DefaultProduct) {
            userProduct.DefaultProduct = false;
          }
        });
      }
    },
    _updateSelectedAdminUserProductCodeBooks(state, userProductCodeBooks) {
      state.selectedAdminUser.UserProductCodeBooks = userProductCodeBooks ?? [];
    },
    _updateSelectedAdminUserProductCodeBook(state, updatedUserProductCodeBook) {
      if (state.selectedAdminUser?.UserProductCodeBooks?.length > 0 && updatedUserProductCodeBook != null) {
        state.selectedAdminUser.UserProductCodeBooks.forEach((userProductCodeBook) => {
          if (Number(userProductCodeBook.RootId) === Number(updatedUserProductCodeBook.RootId)) {
            userProductCodeBook.IsSelected = updatedUserProductCodeBook.Enabled;
            userProductCodeBook.Enabled = updatedUserProductCodeBook.Enabled;
            userProductCodeBook.DefaultProductCodeBook = updatedUserProductCodeBook.DefaultProductCodeBook;
          } else if (updatedUserProductCodeBook.DefaultProductCodeBook) {
            userProductCodeBook.DefaultProductCodeBook = false;
          }
        });
      }
    },
    _updateSelectedAdminUserBranch(state, updatedUserBranch) {
      if (state.selectedAdminUser?.UserBranches?.length > 0 && updatedUserBranch != null) {
        state.selectedAdminUser.UserBranches.forEach((userBranch) => {
          if (Number(userBranch.RootId) === Number(updatedUserBranch.RootId)) {
            userBranch.IsSelected = updatedUserBranch.Enabled;
            userBranch.Enabled = updatedUserBranch.Enabled;
            userBranch.DefaultBranch = updatedUserBranch.DefaultBranch;
          } else if (updatedUserBranch.DefaultBranch) {
            userBranch.DefaultBranch = false;
          }
        });
      }
    },
    _updateSelectedAdminUserGroupMember(state, updatedUserGroupMember) {
      if (state.selectedAdminUser?.GroupMembers?.length > 0 && updatedUserGroupMember != null) {
        state.selectedAdminUser.GroupMembers.forEach((userGroupMember) => {
          if (Number(userGroupMember.RootId) === Number(updatedUserGroupMember.RootId)) {
            userGroupMember.IsSelected = updatedUserGroupMember.IsSelected;
          }
        });
      }
    },
    _updateSelectedAdminUserAccessSetting(state, updatedUserAccessSetting) {
      let userAccessSetting =
        state.selectedAdminUser?.UserAccessSettings?.find((userAccessSetting) => Number(userAccessSetting?.RootId ?? 0) === Number(updatedUserAccessSetting?.RootId ?? 0)) ?? null;
      if (userAccessSetting != null) {
        userAccessSetting.SettingValue = updatedUserAccessSetting?.SettingValue ?? false;
      }
    },
    _updateSelectedAdminUserConfigSetting(state, updatedUserConfigSetting) {
      let userConfigSetting =
        state.selectedAdminUser?.UserConfigSettings?.find((userConfigSetting) => Number(userConfigSetting?.RootId ?? 0) === Number(updatedUserConfigSetting?.RootId ?? 0)) ?? null;
      if (userConfigSetting != null) {
        userConfigSetting.SettingValue = updatedUserConfigSetting?.SettingValue ?? "";
      }
    },
    _updateSelectedAdminGroupGroupMember(state, updateOrganizationGroupMember) {
      if (state.selectedAdminGroup != null && updateOrganizationGroupMember?.RootId > 0) {
        let organizationGroupMembersData = _.cloneDeep(state.selectedAdminGroup?.GroupMembers) ?? [];
        let foundOrganizationGroupMember = organizationGroupMembersData.find(
          (organizationGroupMember) => Number(updateOrganizationGroupMember.RootId ?? 0) === Number(organizationGroupMember?.RootId ?? 0)
        );
        if (foundOrganizationGroupMember?.RootId > 0) {
          organizationGroupMembersData =
            [
              ...organizationGroupMembersData.filter(
                (organizationGroupMember) => Number(updateOrganizationGroupMember.RootId ?? 0) === Number(organizationGroupMember?.RootId ?? 0)
              ),
              _.cloneDeep(updateOrganizationGroupMember),
            ] ?? [];
        } else {
          organizationGroupMembersData.push(updateOrganizationGroupMember);
        }
        state.selectedAdminGroup.GroupMembers = organizationGroupMembersData;
      }
    },
    _updateSelectedAdminGroupAccessSetting(state, updatedGroupAccessSetting) {
      if (updatedGroupAccessSetting?.RootId > 0) {
        if (Number(updatedGroupAccessSetting.GroupRootId ?? 0) === Number(state.selectedAdminGroup?.RootId ?? 0)) {
          let groupAccessSetting =
            state.selectedAdminGroup?.GroupAccessSettings?.find(
              (groupAccessSetting) => Number(groupAccessSetting?.RootId ?? 0) === Number(updatedGroupAccessSetting?.RootId ?? 0)
            ) ?? null;
          if (groupAccessSetting != null) {
            groupAccessSetting.SettingValue = updatedGroupAccessSetting?.SettingValue ?? false;
          }
        } else if (Number(updatedGroupAccessSetting.OrganizationRootId ?? 0) === Number(state.selectedAdminOrganization?.RootId ?? 0)) {
          let organizationGroup =
            state.selectedAdminOrganization?.OrganizationGroups?.find(
              (groupAccessSetting) => Number(groupAccessSetting?.RootId ?? 0) === Number(updatedGroupAccessSetting?.RootId ?? 0)
            ) ?? null;
          if (organizationGroup?.RootId > 0) {
            let groupAccessSetting =
              organizationGroup?.GroupAccessSettings?.find((groupAccessSetting) => Number(groupAccessSetting?.RootId ?? 0) === Number(updatedGroupAccessSetting?.RootId ?? 0)) ??
              null;
            if (groupAccessSetting != null) {
              groupAccessSetting.SettingValue = updatedGroupAccessSetting?.SettingValue ?? false;
            }
          } else {
            let organizationGroup =
              state.organizationGroups?.find((groupAccessSetting) => Number(groupAccessSetting?.RootId ?? 0) === Number(updatedGroupAccessSetting?.RootId ?? 0)) ?? null;
            if (organizationGroup?.RootId > 0) {
              let groupAccessSetting =
                organizationGroup?.GroupAccessSettings?.find((groupAccessSetting) => Number(groupAccessSetting?.RootId ?? 0) === Number(updatedGroupAccessSetting?.RootId ?? 0)) ??
                null;
              if (groupAccessSetting != null) {
                groupAccessSetting.SettingValue = updatedGroupAccessSetting?.SettingValue ?? false;
              }
            }
          }
        }
      }
    },
    _updateUserSearch(state, params) {
      const userRootId = Number(params?.UserRootId ?? 0);
      if (userRootId > 0 && userRootId === Number(state.selectedAdminUser?.RootId ?? 0)) {
        const productRootId = Number(params?.ProductRootId ?? 0);
        const searchRootId = Number(params?.SearchRootId ?? 0);
        if (productRootId > 0 && searchRootId > 0) {
          let userSearchesData = _.cloneDeep(state.selectedAdminUser.UserSearches) ?? [];
          const updateUserSearch =
            userSearchesData.find(
              (userSearch) =>
                Number(userSearch?.UserRootId ?? 0) === userRootId &&
                Number(userSearch?.ProductRootId ?? 0) === productRootId &&
                Number(userSearch?.SearchRootId ?? 0) === searchRootId
            ) ?? null;
          if (updateUserSearch?.RootId > 0) {
            let updateUserSearchCopy = _.cloneDeep(updateUserSearch);
            if (params?.hasOwnProperty("Enabled")) {
              updateUserSearchCopy.Enabled = params?.Enabled ?? false;
            }
            if (params?.hasOwnProperty("Selected")) {
              updateUserSearchCopy.Selected = params?.Selected ?? false;
            }
            userSearchesData =
              [...userSearchesData.filter((userSearch) => Number(userSearch?.RootId ?? 0) !== Number(updateUserSearch.RootId)), updateUserSearchCopy].sort((us1, us2) =>
                us1.SearchName > us2.SearchName ? 1 : -1
              ) ?? [];
            state.selectedAdminUser.UserSearches = _.cloneDeep(userSearchesData);
          }
        }
      }
    },
    _updateGroupSearch(state, params) {
      const groupRootId = Number(params?.GroupRootId ?? 0);
      if (groupRootId > 0 && groupRootId === Number(state.selectedAdminGroup?.RootId ?? 0)) {
        const productRootId = Number(params?.ProductRootId ?? 0);
        const searchRootId = Number(params?.SearchRootId ?? 0);
        if (productRootId > 0 && searchRootId > 0) {
          let groupSearchesData = _.cloneDeep(state.selectedAdminGroup.GroupSearches) ?? [];
          const updateGroupSearch =
            groupSearchesData.find(
              (groupSearch) =>
                Number(groupSearch?.GroupRootId ?? 0) === groupRootId &&
                Number(groupSearch?.ProductRootId ?? 0) === productRootId &&
                Number(groupSearch?.SearchRootId ?? 0) === searchRootId
            ) ?? null;
          if (updateGroupSearch?.RootId > 0) {
            let updateGroupSearchCopy = _.cloneDeep(updateGroupSearch);
            if (params?.hasOwnProperty("Enabled")) {
              updateGroupSearchCopy.Enabled = params?.Enabled ?? false;
            }
            if (params?.hasOwnProperty("Selected")) {
              updateGroupSearchCopy.Selected = params?.Selected ?? false;
            }
            groupSearchesData =
              [...groupSearchesData.filter((groupSearch) => Number(groupSearch?.RootId ?? 0) !== Number(updateGroupSearch.RootId)), updateGroupSearchCopy].sort((gs1, gs2) =>
                gs1.SearchName > gs2.SearchName ? 1 : -1
              ) ?? [];
            state.selectedAdminGroup.GroupSearches = _.cloneDeep(groupSearchesData);
          }
        }
      }
    },
    _updateUserFormLayout(state, params) {
      const userRootId = Number(params?.UserRootId ?? 0);
      if (userRootId > 0 && userRootId === Number(state.selectedAdminUser?.RootId ?? 0)) {
        const productRootId = Number(params?.ProductRootId ?? 0);
        const formLayoutRootId = Number(params?.FormLayoutRootId ?? 0);
        if (productRootId > 0 && formLayoutRootId > 0) {
          let userFormLayoutsData = _.cloneDeep(state.selectedAdminUser.UserFormLayouts) ?? [];
          const updateUserFormLayout =
            userFormLayoutsData.find(
              (userFormLayout) =>
                Number(userFormLayout?.UserRootId ?? 0) === userRootId &&
                Number(userFormLayout?.ProductRootId ?? 0) === productRootId &&
                Number(userFormLayout?.FormLayoutRootId ?? 0) === formLayoutRootId
            ) ?? null;
          if (updateUserFormLayout?.RootId > 0) {
            let updateUserFormLayoutCopy = _.cloneDeep(updateUserFormLayout);
            if (params?.hasOwnProperty("Enabled")) {
              updateUserFormLayoutCopy.Enabled = params?.Enabled ?? false;
            }
            if (params?.hasOwnProperty("Selected")) {
              updateUserFormLayoutCopy.Selected = params?.Selected ?? false;
            }
            userFormLayoutsData =
              [...userFormLayoutsData.filter((userFormLayout) => Number(userFormLayout?.RootId ?? 0) !== Number(updateUserFormLayout.RootId)), updateUserFormLayoutCopy].sort(
                (us1, us2) => (us1.FormLayoutName > us2.FormLayoutName ? 1 : -1)
              ) ?? [];
            state.selectedAdminUser.UserFormLayouts = _.cloneDeep(userFormLayoutsData);
          }
        }
      }
    },
    setOrganizationUserFormLayouts(state, organizationUserFormLayoutsData) {
      state.organizationUserFormLayouts = organizationUserFormLayoutsData?.sort((oufl1, oufl2) => (oufl1.UserDisplayName > oufl2.UserDisplayName ? 1 : -1)) ?? [];
    },
    clearOrganizationUserFormLayouts(state) {
      state.organizationUserFormLayouts = [];
    },
    _updateOrganizationUserFormLayoutData(state, params) {
      const userRootId = Number(params?.UserRootId ?? 0);
      const productRootId = Number(params?.ProductRootId ?? 0);
      const formLayoutRootId = Number(params?.FormLayoutRootId ?? 0);
      if (userRootId > 0 && productRootId > 0 && formLayoutRootId > 0) {
        let userFormLayoutsData = _.cloneDeep(state.organizationUserFormLayouts) ?? [];
        const updateUserFormLayout =
          userFormLayoutsData.find(
            (userFormLayout) =>
              Number(userFormLayout?.UserRootId ?? 0) === userRootId &&
              Number(userFormLayout?.ProductRootId ?? 0) === productRootId &&
              Number(userFormLayout?.FormLayoutRootId ?? 0) === formLayoutRootId
          ) ?? null;
        if (updateUserFormLayout?.RootId > 0) {
          let updateUserFormLayoutCopy = _.cloneDeep(updateUserFormLayout);
          if (params?.hasOwnProperty("Enabled")) {
            updateUserFormLayoutCopy.Enabled = params?.Enabled ?? false;
          }
          if (params?.hasOwnProperty("Selected")) {
            updateUserFormLayoutCopy.Selected = params?.Selected ?? false;
          }
          userFormLayoutsData =
            [...userFormLayoutsData.filter((userFormLayout) => Number(userFormLayout?.RootId ?? 0) !== Number(updateUserFormLayout.RootId)), updateUserFormLayoutCopy].sort(
              (oufl1, oufl2) => (oufl1.UserDisplayName > oufl2.UserDisplayName ? 1 : -1)
            ) ?? [];
          state.organizationUserFormLayouts = _.cloneDeep(userFormLayoutsData);
        }
      }
    },
    setOrganizationGroupSearches(state, organizationGroupSearchesData) {
      state.organizationGroupSearches = organizationGroupSearchesData?.sort((ogs1, ogs2) => (ogs1.SearchName > ogs2.SearchName ? 1 : -1)) ?? [];
    },
    clearOrganizationGroupSearches(state) {
      state.organizationGroupSearches = [];
    },
    _updateOrganizationGroupSearchData(state, params) {
      const groupRootId = Number(params?.GroupRootId ?? 0);
      const productRootId = Number(params?.ProductRootId ?? 0);
      const organizationRootId = Number(params?.OrganizationRootId ?? 0);
      const searchRootId = Number(params?.SearchRootId ?? 0);
      if (groupRootId > 0 && productRootId > 0 && organizationRootId > 0 && searchRootId > 0) {
        let groupSearchesData = _.cloneDeep(state.organizationGroupSearches) ?? [];
        const updateGroupSearch =
          groupSearchesData.find(
            (groupSearch) =>
              Number(groupSearch?.GroupRootId ?? 0) === groupRootId &&
              Number(groupSearch?.ProductRootId ?? 0) === productRootId &&
              Number(groupSearch?.OrganizationRootId ?? 0) === organizationRootId &&
              Number(groupSearch?.SearchRootId ?? 0) === searchRootId
          ) ?? null;
        if (updateGroupSearch?.RootId > 0) {
          let updateGroupSearchCopy = _.cloneDeep(updateGroupSearch);
          const id = Number(params?.Id ?? 0);
          const rootId = Number(params?.RootId ?? 0);
          if (id > 0) {
            updateGroupSearchCopy.Id = id;
          }
          if (rootId > 0) {
            updateGroupSearchCopy.RootId = rootId;
          }
          if (params?.hasOwnProperty("Enabled")) {
            updateGroupSearchCopy.Enabled = params?.Enabled ?? false;
          }
          if (params?.hasOwnProperty("Selected")) {
            updateGroupSearchCopy.Selected = params?.Selected ?? false;
          }
          groupSearchesData =
            [...groupSearchesData.filter((groupSearch) => Number(groupSearch?.RootId ?? 0) !== Number(updateGroupSearch.RootId)), updateGroupSearchCopy].sort((ogs1, ogs2) =>
              ogs1.GroupName > ogs2.GroupName ? 1 : -1
            ) ?? [];
          state.organizationGroupSearches = _.cloneDeep(groupSearchesData);
        }
      }
    },
    setOrganizationGroupFormLayouts(state, organizationGroupFormLayoutsData) {
      state.organizationGroupFormLayouts = organizationGroupFormLayoutsData?.sort((ogfl1, ogfl2) => (ogfl1.GroupName > ogfl2.GroupName ? 1 : -1)) ?? [];
    },
    clearOrganizationGroupFormLayouts(state) {
      state.organizationGroupFormLayouts = [];
    },
    _updateOrganizationGroupFormLayoutData(state, params) {
      const groupRootId = Number(params?.GroupRootId ?? 0);
      const productRootId = Number(params?.ProductRootId ?? 0);
      const organizationRootId = Number(params?.OrganizationRootId ?? 0);
      const formLayoutRootId = Number(params?.FormLayoutRootId ?? 0);
      if (groupRootId > 0 && productRootId > 0 && organizationRootId > 0 && formLayoutRootId > 0) {
        let groupFormLayoutsData = _.cloneDeep(state.organizationGroupFormLayouts) ?? [];
        const updateGroupFormLayout =
          groupFormLayoutsData.find(
            (groupFormLayout) =>
              Number(groupFormLayout?.GroupRootId ?? 0) === groupRootId &&
              Number(groupFormLayout?.ProductRootId ?? 0) === productRootId &&
              Number(groupFormLayout?.OrganizationRootId ?? 0) === organizationRootId &&
              Number(groupFormLayout?.FormLayoutRootId ?? 0) === formLayoutRootId
          ) ?? null;
        if (updateGroupFormLayout?.RootId > 0) {
          let updateGroupFormLayoutCopy = _.cloneDeep(updateGroupFormLayout);
          const id = Number(params?.Id ?? 0);
          const rootId = Number(params?.RootId ?? 0);
          if (id > 0) {
            updateGroupFormLayoutCopy.Id = id;
          }
          if (rootId > 0) {
            updateGroupFormLayoutCopy.RootId = rootId;
          }
          if (params?.hasOwnProperty("Enabled")) {
            updateGroupFormLayoutCopy.Enabled = params?.Enabled ?? false;
          }
          if (params?.hasOwnProperty("Selected")) {
            updateGroupFormLayoutCopy.Selected = params?.Selected ?? false;
          }
          groupFormLayoutsData =
            [...groupFormLayoutsData.filter((groupFormLayout) => Number(groupFormLayout?.RootId ?? 0) !== Number(updateGroupFormLayout.RootId)), updateGroupFormLayoutCopy].sort(
              (ogfl1, ogfl2) => (ogfl1.GroupName > ogfl2.GroupName ? 1 : -1)
            ) ?? [];
          state.organizationGroupFormLayouts = _.cloneDeep(groupFormLayoutsData);
        }
      }
    },
  },
  actions: {
    async getOrganizationsData(
      context,
      {
        canReceiveOrders,
        receiveOrdersFromOrganizationRootId,
        includeProductMetadata,
        includeGroupMetadata,
        includeUserMetadata,
        includeSettingsMetadata,
        includeDocumentMetadata,
        includeDocumentCategoriesMetadata,
        includeDocumentTypesMetadata,
        includeDocumentTemplateTypesMetadata,
      }
    ) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationsUrl}?canreceiveorders=${canReceiveOrders ?? false}&receiveordersfromorganizationrootid=${Number(
          receiveOrdersFromOrganizationRootId ?? 0
        )}&includeproductmetadata=${includeProductMetadata ?? false}&includegroupmetadata=${includeGroupMetadata ?? false}&includeusermetadata=${
          includeUserMetadata ?? false
        }&includesettingsmetadata=${includeSettingsMetadata ?? false}&includedocumentmetadata=${includeDocumentMetadata ?? false}&includedocumentcategoriesmetadata=${
          includeDocumentCategoriesMetadata ?? false
        }&includedocumenttypesmetadata=${includeDocumentTypesMetadata ?? false}&includedocumenttemplatetypesmetadata=${includeDocumentTemplateTypesMetadata ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationData", data);
      }, "organizations - getOrganizationsData");
    },
    async getOrganizationVendorsList(
      context,
      {
        receiveOrdersFromOrganizationRootId,
        includeProductMetadata,
        includeGroupMetadata,
        includeUserMetadata,
        includeSettingsMetadata,
        includeDocumentMetadata,
        includeDocumentCategoriesMetadata,
        includeDocumentTypesMetadata,
        includeDocumentTemplateTypesMetadata,
      }
    ) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationsUrl}?canreceiveorders=${true}&receiveordersfromorganizationrootid=${Number(
          receiveOrdersFromOrganizationRootId ?? 0
        )}&includeproductmetadata=${includeProductMetadata ?? false}&includegroupmetadata=${includeGroupMetadata ?? false}&includeusermetadata=${
          includeUserMetadata ?? false
        }&includesettingsmetadata=${includeSettingsMetadata ?? false}&includedocumentmetadata=${includeDocumentMetadata ?? false}&includedocumentcategoriesmetadata=${
          includeDocumentCategoriesMetadata ?? false
        }&includedocumenttypesmetadata=${includeDocumentTypesMetadata ?? false}&includedocumenttemplatetypesmetadata=${includeDocumentTemplateTypesMetadata ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationVendorsList", data);
      }, "organizations - getOrganizationsData");
    },
    async getSingleOrganizationData(context, params) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationsUrl}/${Number(params?.rootId ?? 0)}?includeproductmetadata=${params?.includeProductMetadata ?? false}&includegroupmetadata=${
          params?.includeGroupMetadata ?? false
        }&includeusermetadata=${params?.includeUserMetadata ?? false}&includesettingsmetadata=${params?.includeSettingsMetadata ?? false}&includedocumentmetadata=${
          params?.includeDocumentMetadata ?? false
        }&includedocumentcategoriesmetadata=${params?.includeDocumentCategoriesMetadata ?? false}&includedocumenttypesmetadata=${
          params?.includeDocumentTypesMetadata ?? false
        }&includedocumenttemplatetypesmetadata=${params?.includeDocumentTemplateTypesMetadata ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_updateOrganization", data);
        return data;
      }, "organizations - getSingleOrganizationData");
    },
    async getOrganizationSimpleSearchData(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationsUrl}?searchtext=${String(
          params?.SearchText ?? ""
        )}&includeproductmetadata=false&includegroupmetadata=false&includeusermetadata=false&includesettingsmetadata=false&includedocumentmetadata=false&includedocumentcategoriesmetadata=false&includedocumenttypesmetadata=false&includedocumenttemplatetypesmetadata=false`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationData", data);
      }, "users - getOrganizationSimpleSearchData");
    },
    async organizationNameSearch(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${validateNamesUrl}?typename=organization&name=${String(params?.Name ?? "")}&displayname=${String(params?.DisplayName ?? "")}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationNameSearchData", data);
      }, "organizations - organizationNameSearch");
    },
    async getOrganizationSettings(context, params) {
      await timeOperation(async () => {
        const useCache = params?.UseCache ?? false;
        const organizationRootId = Number(params?.OrganizationRootId ?? 0);
        const cachedOrganizationSettingsOrganizationRootId = Number(context.state.cachedOrganizationSettingsOrganizationRootId ?? 0);
        const dirtyCache = cachedOrganizationSettingsOrganizationRootId !== organizationRootId;
        if (!useCache || dirtyCache) {
          context.commit("setCachedOrganizationSettingsOrganizationRootId", organizationRootId);
          const formattedUrl = `${organizationSettingsUrl}?organizationrootid=${organizationRootId}&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setOrganizationSettings", data);
        }
      }, "organizations - getOrganizationSettings");
    },
    async getOrganizationAccessSettings(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationAccessSettingsUrl}?organizationrootid=${Number(params?.OrganizationRootId ?? 0)}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationAccessSettings", data);
      }, "organizations - getOrganizationAccessSettings");
    },
    async getOrganizationBranches(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationBranchesUrl}?organizationrootid=${Number(params?.OrganizationRootId ?? 0)}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationBranches", data);
      }, "organizations - getOrganizationBranches");
    },
    async getOrganizationDocumentTemplateTypes(context, params) {
      await timeOperation(async () => {
        const productRootId = Number(params?.ProductRootId ?? 0);
        const organizationRootId = Number(params?.OrganizationRootId ?? 0);
        if (productRootId > 0 && organizationRootId > 0) {
          if (
            !(
              params?.UseCache &&
              context.state.organizationDocumentTemplateTypes.length > 0 &&
              Number(context.state.organizationDocumentTemplateTypes[0]?.ProductRootId ?? 0) === productRootId &&
              Number(context.state.organizationDocumentTemplateTypes[0]?.OrganizationRootId ?? 0) === organizationRootId
            )
          ) {
            const formattedUrl = `${organizationDocumentTemplateTypesUrl}?productrootid=${Number(params?.ProductRootId ?? 0)}&organizationrootid=${Number(
              params?.OrganizationRootId ?? 0
            )}&includemetadata=true`;
            const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
            context.commit("setOrganizationDocumentTemplateTypes", data);
          }
        } else {
          context.commit("setOrganizationDocumentTemplateTypes", []);
        }
      }, "organizations - getOrganizationDocumentTemplateTypes");
    },
    async getOrganizationOrderDocumentTypes(context, { ProductRootId, OrganizationRootId, IncludePossible, IncludeMetadata, NoCache }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        if (ProductRootId > 0) {
          const formattedUrl = `${organizationOrderDocumentTypesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          if (!NoCache) {
            context.commit("setOrganizationOrderDocumentTypes", data);
          }
          return data;
        } else {
          if (!NoCache) {
            context.commit("setOrganizationOrderDocumentTypes", []);
          }
          return [];
        }
      }, "organizations - getOrganizationOrderDocumentTypes");
    },
    async getOrganizationGroups(context, { OrganizationRootId, Type, IncludeMetadata, IncludeMembership, IncludeAccessSettings }) {
      await timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        Type = Number(Type ?? GroupTypeAny);
        IncludeMetadata = IncludeMetadata ?? false;
        IncludeMembership = IncludeMembership ?? false;
        IncludeAccessSettings = IncludeAccessSettings ?? false;
        const formattedUrl = `${groupsUrl}?organizationrootid=${OrganizationRootId}&type=${Type}&includemetadata=${IncludeMetadata}&includemembership=${IncludeMembership}&includeaccesssettings=${IncludeAccessSettings}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationGroups", data);
      }, "organizations - getOrganizationGroups");
    },
    async getOrganizationGroupMembers(context, { OrganizationRootId, GroupRootId, MemberType, IncludeMetadata }) {
      await timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        GroupRootId = Number(GroupRootId ?? 0);
        MemberType = Number(MemberType ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${groupMembersUrl}?organizationrootid=${OrganizationRootId}&grouprootid=${GroupRootId}&membertype=${MemberType}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationGroupMembers", data);
      }, "organizations - getOrganizationGroupMembers");
    },
    async getOrganizationUsers(
      context,
      {
        OrganizationRootId,
        IncludeMetadata,
        IncludeMembership,
        IncludeAccessSettings,
        IncludeConfigSettings,
        IncludeOrganizationSettings,
        IncludeProducts,
        IncludeSearches,
        IncludeFormLayouts,
        IncludeBranches,
        IncludeParties,
        IncludeCodeBooks,
        IncludeAvatar,
        IsUsersOrganization,
      }
    ) {
      await timeOperation(async () => {
        const formattedUrl = `${usersUrl}?organizationrootid=${Number(OrganizationRootId ?? 0)}&includemetadata=${IncludeMetadata ?? false}&includemembership=${
          IncludeMembership ?? false
        }&includeaccesssettings=${IncludeAccessSettings ?? false}&includeconfigsettings=${IncludeConfigSettings ?? false}&includeorganizationsettings=${
          IncludeOrganizationSettings ?? false
        }&includeproducts=${IncludeProducts ?? false}&includesearches=${IncludeSearches ?? false}&includeformlayouts=${IncludeFormLayouts ?? false}&includebranches=${
          IncludeBranches ?? false
        }&includeparties=${IncludeParties ?? false}&includecodebooks=${IncludeCodeBooks ?? false}&includeavatar=${IncludeAvatar ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (IsUsersOrganization) {
          context.commit("setUserOrganizationUsers", data);
        } else {
          context.commit("setOrganizationUsers", data);
        }
      }, "organizations - getOrganizationUsers");
    },
    async getOrganizationUser(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${usersUrl}/${Number(params?.UserRootId ?? 0)}?includemetadata=${params?.IncludeMetadata ?? false}&includemembership=${
          params?.IncludeMembership ?? false
        }&includeaccesssettings=${params?.IncludeAccessSettings ?? false}&includeconfigsettings=${params?.IncludeConfigSettings ?? false}&includeorganizationsettings=${
          params?.IncludeOrganizationSettings ?? false
        }&includeproducts=${params?.IncludeProducts ?? false}&includesearches=${params?.IncludeSearches ?? false}&includeformlayouts=${
          params?.IncludeFormLayouts ?? false
        }&includebranches=${params?.IncludeBranches ?? false}&includeparties=${params?.IncludeParties ?? false}&includecodebooks=${
          params?.IncludeCodeBooks ?? false
        }&includeavatar=${params?.IncludeAvatar ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_updateSelectedAdminOrganizationUser", data);
        context.commit("_updateOrganizationUser", data);
      }, "organizations - getOrganizationUser");
    },
    async getOrganizationUserNoCache(context, params) {
      return timeOperation(async () => {
        const formattedUrl = `${usersUrl}/${Number(params?.UserRootId ?? 0)}?includemetadata=${params?.IncludeMetadata ?? false}&includemembership=${
          params?.IncludeMembership ?? false
        }&includeaccesssettings=${params?.IncludeAccessSettings ?? false}&includeconfigsettings=${params?.IncludeConfigSettings ?? false}&includeorganizationsettings=${
          params?.IncludeOrganizationSettings ?? false
        }&includeproducts=${params?.IncludeProducts ?? false}&includesearches=${params?.IncludeSearches ?? false}&includeformlayouts=${
          params?.IncludeFormLayouts ?? false
        }&includebranches=${params?.IncludeBranches ?? false}&includeparties=${params?.IncludeParties ?? false}&includecodebooks=${
          params?.IncludeCodeBooks ?? false
        }includeavatar=${params?.IncludeAvatar ?? false}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizations - getOrganizationUserNoCache");
    },
    async getOrganizationProducts(context, { OrganizationRootId, IncludePossible, IncludeMetadata, NoCache, CanUseServerCache }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        const formattedUrl = `${organizationProductsUrl}?organizationrootid=${OrganizationRootId}&includemetadata=${IncludeMetadata}&canusecache=${CanUseServerCache}&includepossible=${IncludePossible}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (NoCache) {
          return data;
        }
        context.commit("setOrganizationProducts", data);
      }, "organizations - getOrganizationProducts");
    },
    async getOrganizationUserSearches(context, { OrganizationRootId, ProductRootId, SearchRootId, IncludePossible, IncludeStatistics, IncludeMetadata }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeStatistics = IncludeStatistics ?? true;
        IncludeMetadata = IncludeMetadata ?? false;
        const formattedUrl = `${userSearchesUrl}?organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&searchrootid=${SearchRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizations - getOrganizationUserSearches");
    },
    async getOrganizationUserFormLayouts(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${userFormLayoutsUrl}?organizationrootid=${Number(params?.OrganizationRootId ?? 0)}&productrootid=${Number(
          params?.ProductRootId ?? 0
        )}&formlayoutrootid=${Number(params?.FormLayoutRootId ?? 0)}&includemetadata=${params?.IncludeMetadata ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationUserFormLayouts", data);
      }, "organizations - getOrganizationUserFormLayouts");
    },
    async getOrganizationGroupSearches(context, { GroupRootId, OrganizationRootId, ProductRootId, SearchRootId, IncludePossible, IncludeMetadata }) {
      return timeOperation(async () => {
        GroupRootId = Number(GroupRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${groupSearchesUrl}?grouprootid=${GroupRootId}&organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&searchrootid=${SearchRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationGroupSearches", data);
        return data;
      }, "organizations - getOrganizationGroupSearches");
    },
    async getOrganizationGroupFormLayouts(context, { GroupRootId, OrganizationRootId, ProductRootId, SearchRootId, IncludePossible, IncludeMetadata }) {
      return timeOperation(async () => {
        GroupRootId = Number(GroupRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${groupFormLayoutsUrl}?grouprootid=${GroupRootId}&organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&searchrootid=${SearchRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationGroupFormLayouts", data);
        return data;
      }, "organizations - getOrganizationGroupFormLayouts");
    },
    async addOrganization(context, organization) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationsUrl, Payload: organization });
        context.commit("_addOrganization", data);
        return data;
      }, "organizations - addOrganization");
    },
    async updateOrganization(context, organization) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationsUrl}/${organization.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organization });
        context.commit("_updateOrganization", data);
      }, "organizations - updateOrganization");
    },
    async removeOrganization(context, organization) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationsUrl}/${organization.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizations - removeOrganization");
    },
    async cloneOrganization(
      context,
      {
        RootId,
        IncludeOrganizationProducts,
        IncludeOrganizationDataSources,
        IncludeGroups,
        IncludeGroupMembers,
        IncludeGroupAccessSettings,
        IncludeGroupDataSourceFieldAccessSettings,
        IncludeUsers,
        IncludeOrganizationAccessSettings,
        IncludeOrganizationSettings,
        IncludeOrganizationDocumentCategories,
        IncludeOrganizationDocumentTypes,
        IncludeOrganizationDocumentTemplateTypes,
        IncludeOrganizationOrderDocumentTypes,
        IncludeOrganizationCustomUserFields,
        IncludeOrganizationCustomFields,
        IncludeOrganizationTags,
        IncludeOrganizationBranches,
        IncludeOrganizationSearchGroups,
        IncludeSearches,
        IncludeOrganizationFormGroups,
        IncludeFormLayouts,
        IncludeWorkflowTaskTemplates,
        IncludeWorkflowRuleTemplates,
        IncludeWorkflowNotificationTemplates,
      }
    ) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        IncludeOrganizationProducts = IncludeOrganizationProducts ?? true;
        IncludeOrganizationDataSources = IncludeOrganizationDataSources ?? true;
        IncludeGroups = IncludeGroups ?? true;
        IncludeGroupMembers = IncludeGroupMembers ?? true;
        IncludeGroupAccessSettings = IncludeGroupAccessSettings ?? true;
        IncludeGroupDataSourceFieldAccessSettings = IncludeGroupDataSourceFieldAccessSettings ?? true;
        IncludeUsers = IncludeUsers ?? true;
        IncludeOrganizationAccessSettings = IncludeOrganizationAccessSettings ?? true;
        IncludeOrganizationSettings = IncludeOrganizationSettings ?? true;
        IncludeOrganizationDocumentCategories = IncludeOrganizationDocumentCategories ?? true;
        IncludeOrganizationDocumentTypes = IncludeOrganizationDocumentTypes ?? true;
        IncludeOrganizationDocumentTemplateTypes = IncludeOrganizationDocumentTemplateTypes ?? true;
        IncludeOrganizationOrderDocumentTypes = IncludeOrganizationOrderDocumentTypes ?? true;
        IncludeOrganizationCustomUserFields = IncludeOrganizationCustomUserFields ?? true;
        IncludeOrganizationCustomFields = IncludeOrganizationCustomFields ?? true;
        IncludeOrganizationTags = IncludeOrganizationTags ?? true;
        IncludeOrganizationBranches = IncludeOrganizationBranches ?? true;
        IncludeOrganizationSearchGroups = IncludeOrganizationSearchGroups ?? true;
        IncludeSearches = IncludeSearches ?? true;
        IncludeOrganizationFormGroups = IncludeOrganizationFormGroups ?? true;
        IncludeFormLayouts = IncludeFormLayouts ?? true;
        IncludeWorkflowTaskTemplates = IncludeWorkflowTaskTemplates ?? true;
        IncludeWorkflowRuleTemplates = IncludeWorkflowRuleTemplates ?? true;
        IncludeWorkflowNotificationTemplates = IncludeWorkflowNotificationTemplates ?? true;
        const formattedUrl = `${organizationsUrl}/${RootId}/clone?includeorganizationproducts=${IncludeOrganizationProducts}&includeorganizationdatasources=${IncludeOrganizationDataSources}&includegroups=${IncludeGroups}&includegroupmembers=${IncludeGroupMembers}&includegroupaccesssettings=${IncludeGroupAccessSettings}&includegroupdatasourcefieldaccesssettings=${IncludeGroupDataSourceFieldAccessSettings}&includeusers=${IncludeUsers}&includeorganizationaccesssettings=${IncludeOrganizationAccessSettings}&includeorganizationsettings=${IncludeOrganizationSettings}&includeorganizationdocumentcategories=${IncludeOrganizationDocumentCategories}&includeorganizationdocumenttypes=${IncludeOrganizationDocumentTypes}&includeorganizationdocumenttemplatetypes=${IncludeOrganizationDocumentTemplateTypes}&includeorganizationorderdocumenttypes=${IncludeOrganizationOrderDocumentTypes}&includeorganizationcustomuserfields=${IncludeOrganizationCustomUserFields}&includeorganizationcustomfields=${IncludeOrganizationCustomFields}&includeorganizationtags=${IncludeOrganizationTags}&includeorganizationbranches=${IncludeOrganizationBranches}&includeorganizationsearchgroups=${IncludeOrganizationSearchGroups}&includesearches=${IncludeSearches}&includeorganizationformgroups=${IncludeOrganizationFormGroups}&includeformlayouts=${IncludeFormLayouts}&includeworkflowtasktemplates=${IncludeWorkflowTaskTemplates}&includeworkflowruletemplates=${IncludeWorkflowRuleTemplates}&includeworkflownotificationtemplates=${IncludeWorkflowNotificationTemplates}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_addOrganization", data);
        return data;
      }, "organizations - cloneOrganization");
    },
    async exportOrganization(
      context,
      {
        RootId,
        IncludeOrganizationProducts,
        IncludeOrganizationDataSources,
        IncludeGroups,
        IncludeGroupMembers,
        IncludeGroupAccessSettings,
        IncludeGroupDataSourceFieldAccessSettings,
        IncludeUsers,
        IncludeOrganizationAccessSettings,
        IncludeOrganizationSettings,
        IncludeOrganizationDocumentCategories,
        IncludeOrganizationDocumentTypes,
        IncludeOrganizationDocumentTemplateTypes,
        IncludeOrganizationOrderDocumentTypes,
        IncludeOrganizationCustomUserFields,
        IncludeOrganizationCustomFields,
        IncludeOrganizationTags,
        IncludeOrganizationBranches,
        IncludeOrganizationSearchGroups,
        IncludeSearches,
        IncludeOrganizationFormGroups,
        IncludeFormLayouts,
        IncludeWorkflowTaskTemplates,
        IncludeWorkflowRuleTemplates,
        IncludeWorkflowNotificationTemplates,
      }
    ) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        IncludeOrganizationProducts = IncludeOrganizationProducts ?? true;
        IncludeOrganizationDataSources = IncludeOrganizationDataSources ?? true;
        IncludeGroups = IncludeGroups ?? true;
        IncludeGroupMembers = IncludeGroupMembers ?? true;
        IncludeGroupAccessSettings = IncludeGroupAccessSettings ?? true;
        IncludeGroupDataSourceFieldAccessSettings = IncludeGroupDataSourceFieldAccessSettings ?? true;
        IncludeUsers = IncludeUsers ?? true;
        IncludeOrganizationAccessSettings = IncludeOrganizationAccessSettings ?? true;
        IncludeOrganizationSettings = IncludeOrganizationSettings ?? true;
        IncludeOrganizationDocumentCategories = IncludeOrganizationDocumentCategories ?? true;
        IncludeOrganizationDocumentTypes = IncludeOrganizationDocumentTypes ?? true;
        IncludeOrganizationDocumentTemplateTypes = IncludeOrganizationDocumentTemplateTypes ?? true;
        IncludeOrganizationOrderDocumentTypes = IncludeOrganizationOrderDocumentTypes ?? true;
        IncludeOrganizationCustomUserFields = IncludeOrganizationCustomUserFields ?? true;
        IncludeOrganizationCustomFields = IncludeOrganizationCustomFields ?? true;
        IncludeOrganizationTags = IncludeOrganizationTags ?? true;
        IncludeOrganizationBranches = IncludeOrganizationBranches ?? true;
        IncludeOrganizationSearchGroups = IncludeOrganizationSearchGroups ?? true;
        IncludeSearches = IncludeSearches ?? true;
        IncludeOrganizationFormGroups = IncludeOrganizationFormGroups ?? true;
        IncludeFormLayouts = IncludeFormLayouts ?? true;
        IncludeWorkflowTaskTemplates = IncludeWorkflowTaskTemplates ?? true;
        IncludeWorkflowRuleTemplates = IncludeWorkflowRuleTemplates ?? true;
        IncludeWorkflowNotificationTemplates = IncludeWorkflowNotificationTemplates ?? true;
        const formattedUrl = `${organizationsUrl}/${RootId}/export?includeorganizationproducts=${IncludeOrganizationProducts}&includeorganizationdatasources=${IncludeOrganizationDataSources}&includegroups=${IncludeGroups}&includegroupmembers=${IncludeGroupMembers}&includegroupaccesssettings=${IncludeGroupAccessSettings}&includegroupdatasourcefieldaccesssettings=${IncludeGroupDataSourceFieldAccessSettings}&includeusers=${IncludeUsers}&includeorganizationaccesssettings=${IncludeOrganizationAccessSettings}&includeorganizationsettings=${IncludeOrganizationSettings}&includeorganizationdocumentcategories=${IncludeOrganizationDocumentCategories}&includeorganizationdocumenttypes=${IncludeOrganizationDocumentTypes}&includeorganizationdocumenttemplatetypes=${IncludeOrganizationDocumentTemplateTypes}&includeorganizationorderdocumenttypes=${IncludeOrganizationOrderDocumentTypes}&includeorganizationcustomuserfields=${IncludeOrganizationCustomUserFields}&includeorganizationcustomfields=${IncludeOrganizationCustomFields}&includeorganizationtags=${IncludeOrganizationTags}&includeorganizationbranches=${IncludeOrganizationBranches}&includeorganizationsearchgroups=${IncludeOrganizationSearchGroups}&includesearches=${IncludeSearches}&includeorganizationformgroups=${IncludeOrganizationFormGroups}&includeformlayouts=${IncludeFormLayouts}&includeworkflowtasktemplates=${IncludeWorkflowTaskTemplates}&includeworkflowruletemplates=${IncludeWorkflowRuleTemplates}&includeworkflownotificationtemplates=${IncludeWorkflowNotificationTemplates}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizations - exportOrganization");
    },
    async importOrganization(
      context,
      {
        IncludeOrganizationProducts,
        IncludeOrganizationDataSources,
        IncludeGroups,
        IncludeGroupMembers,
        IncludeGroupAccessSettings,
        IncludeGroupDataSourceFieldAccessSettings,
        IncludeUsers,
        IncludeOrganizationAccessSettings,
        IncludeOrganizationSettings,
        IncludeOrganizationDocumentCategories,
        IncludeOrganizationDocumentTypes,
        IncludeOrganizationDocumentTemplateTypes,
        IncludeOrganizationOrderDocumentTypes,
        IncludeOrganizationCustomUserFields,
        IncludeOrganizationCustomFields,
        IncludeOrganizationTags,
        IncludeOrganizationBranches,
        IncludeOrganizationSearchGroups,
        IncludeSearches,
        IncludeOrganizationFormGroups,
        IncludeFormLayouts,
        IncludeWorkflowTaskTemplates,
        IncludeWorkflowRuleTemplates,
        IncludeWorkflowNotificationTemplates,
        UpdateExisting,
        FileContents,
      }
    ) {
      if (!(FileContents?.length > 0)) {
        console.log("invalid import data found");
        return null;
      }
      return timeOperation(async () => {
        IncludeOrganizationProducts = IncludeOrganizationProducts ?? true;
        IncludeOrganizationDataSources = IncludeOrganizationDataSources ?? true;
        IncludeGroups = IncludeGroups ?? true;
        IncludeGroupMembers = IncludeGroupMembers ?? true;
        IncludeGroupAccessSettings = IncludeGroupAccessSettings ?? true;
        IncludeGroupDataSourceFieldAccessSettings = IncludeGroupDataSourceFieldAccessSettings ?? true;
        IncludeUsers = IncludeUsers ?? true;
        IncludeOrganizationAccessSettings = IncludeOrganizationAccessSettings ?? true;
        IncludeOrganizationSettings = IncludeOrganizationSettings ?? true;
        IncludeOrganizationDocumentCategories = IncludeOrganizationDocumentCategories ?? true;
        IncludeOrganizationDocumentTypes = IncludeOrganizationDocumentTypes ?? true;
        IncludeOrganizationDocumentTemplateTypes = IncludeOrganizationDocumentTemplateTypes ?? true;
        IncludeOrganizationOrderDocumentTypes = IncludeOrganizationOrderDocumentTypes ?? true;
        IncludeOrganizationCustomUserFields = IncludeOrganizationCustomUserFields ?? true;
        IncludeOrganizationCustomFields = IncludeOrganizationCustomFields ?? true;
        IncludeOrganizationTags = IncludeOrganizationTags ?? true;
        IncludeOrganizationBranches = IncludeOrganizationBranches ?? true;
        IncludeOrganizationSearchGroups = IncludeOrganizationSearchGroups ?? true;
        IncludeSearches = IncludeSearches ?? true;
        IncludeOrganizationFormGroups = IncludeOrganizationFormGroups ?? true;
        IncludeFormLayouts = IncludeFormLayouts ?? true;
        IncludeWorkflowTaskTemplates = IncludeWorkflowTaskTemplates ?? true;
        IncludeWorkflowRuleTemplates = IncludeWorkflowRuleTemplates ?? true;
        IncludeWorkflowNotificationTemplates = IncludeWorkflowNotificationTemplates ?? true;
        UpdateExisting = UpdateExisting ?? false;
        let encodedData = { Base64Encoded: btoa(FileContents) };
        const formattedUrl = `${organizationsUrl}/import?includeorganizationproducts=${IncludeOrganizationProducts}&includeorganizationdatasources=${IncludeOrganizationDataSources}&includegroups=${IncludeGroups}&includegroupmembers=${IncludeGroupMembers}&includegroupaccesssettings=${IncludeGroupAccessSettings}&includegroupdatasourcefieldaccesssettings=${IncludeGroupDataSourceFieldAccessSettings}&includeusers=${IncludeUsers}&includeorganizationaccesssettings=${IncludeOrganizationAccessSettings}&includeorganizationsettings=${IncludeOrganizationSettings}&includeorganizationdocumentcategories=${IncludeOrganizationDocumentCategories}&includeorganizationdocumenttypes=${IncludeOrganizationDocumentTypes}&includeorganizationdocumenttemplatetypes=${IncludeOrganizationDocumentTemplateTypes}&includeorganizationorderdocumenttypes=${IncludeOrganizationOrderDocumentTypes}&includeorganizationcustomuserfields=${IncludeOrganizationCustomUserFields}&includeorganizationcustomfields=${IncludeOrganizationCustomFields}&includeorganizationtags=${IncludeOrganizationTags}&includeorganizationbranches=${IncludeOrganizationBranches}&includeorganizationsearchgroups=${IncludeOrganizationSearchGroups}&includesearches=${IncludeSearches}&includeorganizationformgroups=${IncludeOrganizationFormGroups}&includeformlayouts=${IncludeFormLayouts}&includeworkflowtasktemplates=${IncludeWorkflowTaskTemplates}&includeworkflowruletemplates=${IncludeWorkflowRuleTemplates}&includeworkflownotificationtemplates=${IncludeWorkflowNotificationTemplates}&updateexisting=${UpdateExisting}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: encodedData });
        context.commit("_addOrganization", data);
        return data;
      }, "organizations - importOrganization");
    },
    async addOrganizationDocumentTemplateType(context, organizationDocumentTemplateType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentTemplateTypesUrl, Payload: organizationDocumentTemplateType });
        context.commit("_addSelectedAdminOrganizationDocumentTemplateType", data);
        context.commit("_addOrganizationDocumentTemplateType", data);
      }, "organizations - addOrganizationDocumentTemplateType");
    },
    async updateOrganizationDocumentTemplateType(context, organizationDocumentTemplateType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTemplateTypesUrl}/${organizationDocumentTemplateType.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTemplateType });
        context.commit("_updateSelectedAdminOrganizationDocumentTemplateType", data);
        context.commit("_updateOrganizationDocumentTemplateType", data);
      }, "organizations - updateOrganizationDocumentTemplateType");
    },
    async removeOrganizationDocumentTemplateType(context, organizationDocumentTemplateType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTemplateTypesUrl}/${organizationDocumentTemplateType.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSelectedAdminOrganizationDocumentTemplateType", organizationDocumentTemplateType);
        context.commit("_removeOrganizationDocumentTemplateType", organizationDocumentTemplateType);
      }, "organizations - removeOrganizationDocumentTemplateType");
    },
    async updateOrganizationDocumentTemplateTypePositions(context, organizationDocumentTemplateTypes) {
      await timeOperation(async () => {
        if (organizationDocumentTemplateTypes?.length > 0) {
          const formattedUrl = `${organizationDocumentTemplateTypesUrl}/updatepositions`;
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTemplateTypes });
          context.commit("setOrganizationDocumentTemplateTypes", data);
        }
      }, "organizationDocumentTemplateTypes - updateOrganizationDocumentTemplateTypePositions");
    },
    async updateOrganizationDocumentTemplateTypeEnabledStatus(context, organizationDocumentTemplateType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTemplateTypesUrl}/${Number(organizationDocumentTemplateType?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTemplateType });
        context.commit("_updateSelectedAdminOrganizationDocumentTemplateType", data);
        context.commit("_updateOrganizationDocumentTemplateType", data);
      }, "products - updateOrganizationDocumentTemplateType");
    },
    async updateOrganizationDocumentTemplateTypeAlias(context, organizationDocumentTemplateType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTemplateTypesUrl}/${Number(organizationDocumentTemplateType?.RootId ?? 0)}/updatealias`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTemplateType });
        context.commit("_updateSelectedAdminOrganizationDocumentTemplateType", data);
        context.commit("_updateOrganizationDocumentTemplateType", data);
      }, "products - updateOrganizationDocumentTemplateType");
    },
    async addOrganizationOrderDocumentType(context, organizationOrderDocumentType) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationOrderDocumentTypesUrl, Payload: organizationOrderDocumentType });
        context.commit("_addSelectedAdminOrganizationOrderDocumentType", data);
        context.commit("_addOrganizationOrderDocumentType", data);
        return data;
      }, "organizations - addOrganizationOrderDocumentType");
    },
    async updateOrganizationOrderDocumentType(context, organizationOrderDocumentType) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationOrderDocumentTypesUrl}/${organizationOrderDocumentType.RootId}`;
        const Data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationOrderDocumentType });
        context.commit("_updateSelectedAdminOrganizationOrderDocumentType", { RootId: organizationOrderDocumentType.RootId, Data });
        context.commit("_updateOrganizationOrderDocumentType", { RootId: organizationOrderDocumentType.RootId, Data });
        return Data;
      }, "organizations - updateOrganizationOrderDocumentType");
    },
    async removeOrganizationOrderDocumentType(context, organizationOrderDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationOrderDocumentTypesUrl}/${organizationOrderDocumentType.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSelectedAdminOrganizationOrderDocumentType", organizationOrderDocumentType);
        context.commit("_removeOrganizationOrderDocumentType", organizationOrderDocumentType);
      }, "organizations - removeOrganizationOrderDocumentType");
    },
    async updateOrganizationOrderDocumentTypePositions(context, organizationOrderDocumentTypes) {
      await timeOperation(async () => {
        if (organizationOrderDocumentTypes?.length > 0) {
          const formattedUrl = `${organizationOrderDocumentTypesUrl}/updatepositions`;
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationOrderDocumentTypes });
          context.commit("setOrganizationOrderDocumentTypes", data);
        }
      }, "organizationOrderDocumentTypes - updateOrganizationOrderDocumentTypePositions");
    },
    async updateOrganizationOrderDocumentTypeEnabledStatus(context, organizationOrderDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationOrderDocumentTypesUrl}/${Number(organizationOrderDocumentType?.RootId ?? 0)}/updateenabledstatus`;
        const Data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationOrderDocumentType });
        if (organizationOrderDocumentType?.Id > 0) {
          context.commit("_updateSelectedAdminOrganizationOrderDocumentType", { RootId: organizationOrderDocumentType.RootId, Data });
          context.commit("_updateOrganizationOrderDocumentType", { RootId: organizationOrderDocumentType.RootId, Data });
        }
      }, "products - updateOrganizationOrderDocumentType");
    },
    async updateOrganizationOrderDocumentTypeAlias(context, organizationOrderDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationOrderDocumentTypesUrl}/${Number(organizationOrderDocumentType?.RootId ?? 0)}/updatealias`;
        const Data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationOrderDocumentType });
        context.commit("_updateSelectedAdminOrganizationOrderDocumentType", { RootId: organizationOrderDocumentType.RootId, Data });
        context.commit("_updateOrganizationOrderDocumentType", { RootId: organizationOrderDocumentType.RootId, Data });
      }, "products - updateOrganizationOrderDocumentType");
    },
    async addOrganizationSetting(context, organizationSetting) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationSettingsUrl, Payload: organizationSetting });
        context.commit("_addSelectedAdminOrganizationSetting", data);
        context.commit("_addOrganizationSetting", data);
      }, "organizations - addOrganizationSetting");
    },
    async updateOrganizationSetting(context, organizationSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationSettingsUrl}/${organizationSetting.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationSetting });
        context.commit("_updateSelectedAdminOrganizationSetting", data);
        context.commit("_updateOrganizationSetting", data);
      }, "organizations - updateOrganizationSetting");
    },
    async removeOrganizationSetting(context, organizationSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationSettingsUrl}/${organizationSetting.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSelectedAdminOrganizationSetting", organizationSetting);
        context.commit("_removeOrganizationSetting", organizationSetting);
      }, "organizations - removeOrganizationSetting");
    },
    async addOrganizationAccessSetting(context, organizationAccessSetting) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationAccessSettingsUrl, Payload: organizationAccessSetting });
        context.commit("_addSelectedAdminOrganizationAccessSetting", data);
        context.commit("_addOrganizationAccessSetting", data);
      }, "organizations - addOrganizationAccessSetting");
    },
    async updateOrganizationAccessSetting(context, organizationAccessSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationAccessSettingsUrl}/${organizationAccessSetting.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationAccessSetting });
        context.commit("_updateSelectedAdminOrganizationAccessSetting", data);
        context.commit("_updateOrganizationAccessSetting", data);
      }, "organizations - updateOrganizationAccessSetting");
    },
    async removeOrganizationAccessSetting(context, organizationAccessSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationAccessSettingsUrl}/${organizationAccessSetting.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSelectedAdminOrganizationAccessSetting", organizationAccessSetting);
        context.commit("_removeOrganizationAccessSetting", organizationAccessSetting);
      }, "organizations - removeOrganizationAccessSetting");
    },
    async addOrganizationGroup(context, organizationGroup) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: groupsUrl, Payload: organizationGroup });
        context.commit("_addSelectedAdminOrganizationGroup", data);
        context.commit("_addOrganizationGroup", data);
        return Number(data?.RootId ?? 0);
      }, "organizations - addOrganizationGroup");
    },
    async updateOrganizationGroup(context, organizationGroup) {
      await timeOperation(async () => {
        const formattedUrl = `${groupsUrl}/${organizationGroup.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationGroup });
        context.commit("_updateSelectedAdminOrganizationGroup", data);
        context.commit("_updateOrganizationGroup", data);
      }, "organizations - updateOrganizationGroup");
    },
    async removeOrganizationGroup(context, organizationGroup) {
      await timeOperation(async () => {
        const formattedUrl = `${groupsUrl}/${organizationGroup.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSelectedAdminOrganizationGroup", organizationGroup);
        context.commit("_removeOrganizationGroup", organizationGroup);
      }, "organizations - removeOrganizationGroup");
    },
    async addOrganizationUser(context, organizationUser) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: usersUrl, Payload: organizationUser });
        context.commit("_addSelectedAdminOrganizationUser", data);
        context.commit("_addOrganizationUser", data);
        return Number(data?.RootId ?? 0);
      }, "organizations - addOrganizationUser");
    },
    async updateOrganizationUser(context, organizationUser) {
      await timeOperation(async () => {
        const formattedUrl = `${usersUrl}/${organizationUser.RootId}?baserecordonly=false&simplesettingsonly=false`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationUser });
        context.commit("_updateSelectedAdminOrganizationUser", data);
        context.commit("_updateOrganizationUser", data);
      }, "organizations - updateOrganizationUser");
    },
    async updateOrganizationUserSimple(context, organizationUser) {
      await timeOperation(async () => {
        const formattedUrl = `${usersUrl}/${organizationUser.RootId}?baserecordonly=false&simplesettingsonly=true`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationUser });
        context.commit("_updateSelectedAdminOrganizationUser", data);
        context.commit("_updateOrganizationUser", data);
      }, "organizations - updateOrganizationUserSimple");
    },
    async updateOrganizationUserGeneralOnly(context, organizationUser) {
      return timeOperation(async () => {
        const formattedUrl = `${usersUrl}/${organizationUser.RootId}?baserecordonly=true&simplesettingsonly=false`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationUser });
        context.commit("_updateSelectedAdminOrganizationUser", data);
        context.commit("_updateOrganizationUser", data);
        return data;
      }, "organizations - updateOrganizationUserGeneralOnly");
    },
    async updateOrganizationGroupGeneralOnly(context, organizationGroup) {
      return timeOperation(async () => {
        const formattedUrl = `${groupsUrl}/${organizationGroup.RootId}?baserecordonly=true&simplesettingsonly=false`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationGroup });
        context.commit("_updateSelectedAdminOrganizationGroup", data);
        context.commit("_updateOrganizationGroup", data);
        return data;
      }, "organizations - updateOrganizationGroupGeneralOnly");
    },
    async removeOrganizationUser(context, organizationUser) {
      await timeOperation(async () => {
        const formattedUrl = `${usersUrl}/${organizationUser.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSelectedAdminOrganizationUser", organizationUser);
        context.commit("_removeOrganizationUser", organizationUser);
      }, "organizations - removeOrganizationUser");
    },
    async addOrganizationProduct(context, organizationProduct) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationProductsUrl, Payload: organizationProduct });
        context.commit("_addSelectedAdminOrganizationProduct", data);
        context.commit("_addOrganizationProduct", data);
      }, "organizations - addOrganizationProduct");
    },
    async updateOrganizationProduct(context, organizationProduct) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationProductsUrl}/${organizationProduct.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationProduct });
        context.commit("_updateSelectedAdminOrganizationProductSelectedStatus", data);
      }, "organizations - updateOrganizationProduct");
    },
    async removeOrganizationProduct(context, organizationProduct) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationProductsUrl}/${organizationProduct.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSelectedAdminOrganizationProduct", organizationProduct);
        context.commit("_removeOrganizationProduct", organizationProduct);
      }, "organizations - removeOrganizationProduct");
    },
    async addOrganizationBranch(context, organizationBranch) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationBranchesUrl, Payload: organizationBranch });
        context.commit("_addOrganizationBranch", data);
        return data;
      }, "organizations - addOrganizationBranch");
    },
    async updateOrganizationBranch(context, organizationBranch) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationBranchesUrl}/${organizationBranch.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationBranch });
        context.commit("_updateOrganizationBranch", data);
      }, "organizations - updateOrganizationBranch");
    },
    async removeOrganizationBranch(context, organizationBranch) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationBranchesUrl}/${organizationBranch.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationBranch", organizationBranch);
      }, "organizations - removeOrganizationBranch");
    },
    async saveUserProductCodeBook(context, userProductCodeBook) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userProductCodeBooksUrl, Payload: userProductCodeBook });
        context.commit("_updateSelectedAdminUserProductCodeBook", data);
      }, "organizations - saveUserProductCodeBook");
    },
    async saveUserBranch(context, userBranch) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userBranchesUrl, Payload: userBranch });
        context.commit("_updateSelectedAdminUserBranch", data);
      }, "organizations - saveUserBranch");
    },
    async saveUserGroupMembership(context, userGroupMembership) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: groupMembersUrl, Payload: userGroupMembership });
        context.commit("_updateSelectedAdminUserGroupMember", data);
        context.commit("_updateOrganizationGroupMember", data);
        context.commit("_updateUserGroupMember", data);
        return data;
      }, "organizations - saveUserGroupMembership");
    },
    async saveUserAccessSetting(context, userAccessSetting) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userAccessSettingsUrl, Payload: userAccessSetting });
        context.commit("_updateSelectedAdminUserAccessSetting", data);
      }, "organizations - saveUserAccessSetting");
    },
    async saveUserConfigSetting(context, userConfigSetting) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userConfigSettingsUrl, Payload: userConfigSetting });
        context.commit("_updateSelectedAdminUserConfigSetting", data);
      }, "organizations - saveUserConfigSetting");
    },
    async saveGroupGroupMembership(context, groupGroupMembership) {
      return timeOperation(async () => {
        const groupMember = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: groupMembersUrl, Payload: groupGroupMembership });
        context.commit("_updateSelectedAdminGroupGroupMember", groupMember);
        context.commit("_updateOrganizationGroupMember", groupMember);
        return groupMember;
      }, "organizations - saveGroupGroupMembership");
    },
    async saveGroupAccessSetting(context, groupAccessSetting) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: groupAccessSettingsUrl, Payload: groupAccessSetting });
        context.commit("_updateSelectedAdminGroupAccessSetting", data);
        return data;
      }, "organizations - saveGroupAccessSetting");
    },
    async saveOrganizationSetting(context, organizationSetting) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationSettingsUrl, Payload: organizationSetting });
        context.commit("_updateSelectedAdminOrganizationSetting", data);
      }, "organizations - saveOrganizationSetting");
    },
    async saveOrganizationAccessSetting(context, organizationAccessSetting) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationAccessSettingsUrl, Payload: organizationAccessSetting });
        context.commit("_updateSelectedAdminOrganizationAccessSetting", data);
        context.commit("_updateOrganizationAccessSetting", data);
      }, "organizations - saveOrganizationAccessSetting");
    },
    async updateUserSearchEnabledStatus(context, userSearch) {
      return timeOperation(async () => {
        const formattedUrl = `${userSearchesUrl}/${Number(userSearch?.RootId ?? 0)}/updateenabledstatus`;
        const updatedUserSearch = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userSearch });
        context.commit("_updateUserSearch", {
          UserRootId: Number(updatedUserSearch?.UserRootId ?? 0),
          ProductRootId: Number(updatedUserSearch?.ProductRootId ?? 0),
          SearchRootId: Number(updatedUserSearch?.SearchRootId ?? 0),
          Enabled: updatedUserSearch?.Enabled ?? false,
        });
        return updatedUserSearch;
      }, "organizations - updateUserSearchEnabledStatus");
    },
    async updateUserSearchSelectedStatus(context, userSearch) {
      return timeOperation(async () => {
        const formattedUrl = `${userSearchesUrl}/${Number(userSearch?.RootId ?? 0)}/updateselectedstatus`;
        const updatedUserSearch = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userSearch });
        context.commit("_updateUserSearch", {
          UserRootId: Number(updatedUserSearch?.UserRootId ?? 0),
          ProductRootId: Number(updatedUserSearch?.ProductRootId ?? 0),
          SearchRootId: Number(updatedUserSearch?.SearchRootId ?? 0),
          Selected: updatedUserSearch?.Selected ?? false,
        });
        return updatedUserSearch;
      }, "organizations - updateUserSearchSelectedStatus");
    },
    async updateUserSearchAccessSettings(context, userSearch) {
      return timeOperation(async () => {
        const formattedUrl = `${userSearchesUrl}/${Number(userSearch?.RootId ?? 0)}/updateaccesssettings`;
        const updatedUserSearch = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userSearch });
        context.commit("_updateUserSearch", {
          UserRootId: Number(updatedUserSearch?.UserRootId ?? 0),
          ProductRootId: Number(updatedUserSearch?.ProductRootId ?? 0),
          SearchRootId: Number(updatedUserSearch?.SearchRootId ?? 0),
          CanCopy: updatedUserSearch?.CanCopy ?? false,
          CanClose: updatedUserSearch?.CanClose ?? false,
          CanShare: updatedUserSearch?.CanShare ?? false,
        });
        return updatedUserSearch;
      }, "organizations - updateUserSearchAccessSettings");
    },
    async updateUserSearchPosition(context, userSearches) {
      return await timeOperation(async () => {
        const formattedUrl = `${userSearchesUrl}/updatepositions`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: userSearches });
        return data;
      }, "organizations - updateUserSearchPosition");
    },
    async updateUserFormLayoutEnabledStatus(context, userFormLayout) {
      await timeOperation(async () => {
        const formattedUrl = `${userFormLayoutsUrl}/${Number(userFormLayout?.RootId ?? 0)}/updateenabledstatus`;
        const updatedUserFormLayout = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userFormLayout });
        context.commit("_updateUserFormLayout", {
          UserRootId: updatedUserFormLayout.UserRootId,
          ProductRootId: updatedUserFormLayout.ProductRootId,
          FormLayoutRootId: updatedUserFormLayout.FormLayoutRootId,
          Enabled: updatedUserFormLayout.Enabled ?? false,
        });
        context.commit("_updateOrganizationUserFormLayoutData", {
          UserRootId: updatedUserFormLayout.UserRootId,
          ProductRootId: updatedUserFormLayout.ProductRootId,
          FormLayoutRootId: updatedUserFormLayout.FormLayoutRootId,
          Enabled: updatedUserFormLayout.Enabled ?? false,
        });
      }, "organizations - updateUserFormLayoutEnabledStatus");
    },
    async updateUserFormLayoutSelectedStatus(context, userFormLayout) {
      await timeOperation(async () => {
        const formattedUrl = `${userFormLayoutsUrl}/${Number(userFormLayout?.RootId ?? 0)}/updateselectedstatus`;
        const updatedUserFormLayout = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userFormLayout });
        context.commit("_updateUserFormLayout", {
          UserRootId: updatedUserFormLayout.UserRootId,
          ProductRootId: updatedUserFormLayout.ProductRootId,
          FormLayoutRootId: updatedUserFormLayout.FormLayoutRootId,
          Selected: updatedUserFormLayout.Selected ?? false,
        });
        context.commit("_updateOrganizationUserFormLayoutData", {
          UserRootId: updatedUserFormLayout.UserRootId,
          ProductRootId: updatedUserFormLayout.ProductRootId,
          FormLayoutRootId: updatedUserFormLayout.FormLayoutRootId,
          Selected: updatedUserFormLayout.Selected ?? false,
        });
      }, "organizations - updateUserFormLayoutSelectedStatus");
    },
    async updateGroupSearchEnabledStatus(context, groupSearch) {
      return timeOperation(async () => {
        const formattedUrl = `${groupSearchesUrl}/${Number(groupSearch?.RootId ?? 0)}/updateenabledstatus`;
        const updatedGroupSearch = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupSearch });
        context.commit("_updateGroupSearch", {
          GroupRootId: Number(updatedGroupSearch?.GroupRootId ?? 0),
          ProductRootId: Number(updatedGroupSearch?.ProductRootId ?? 0),
          SearchRootId: Number(updatedGroupSearch?.SearchRootId ?? 0),
          Enabled: updatedGroupSearch?.Enabled ?? false,
        });
        return updatedGroupSearch;
      }, "organizations - updateGroupSearchEnabledStatus");
    },
    async updateGroupSearchSelectedStatus(context, groupSearch) {
      return timeOperation(async () => {
        const formattedUrl = `${groupSearchesUrl}/${Number(groupSearch?.RootId ?? 0)}/updateselectedstatus`;
        const updatedGroupSearch = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupSearch });
        context.commit("_updateGroupSearch", {
          GroupRootId: Number(updatedGroupSearch?.GroupRootId ?? 0),
          ProductRootId: Number(updatedGroupSearch?.ProductRootId ?? 0),
          SearchRootId: Number(updatedGroupSearch?.SearchRootId ?? 0),
          Selected: updatedGroupSearch?.Selected ?? false,
        });
        return updatedGroupSearch;
      }, "organizations - updateGroupSearchSelectedStatus");
    },
    async updateGroupSearchAccessSettings(context, groupSearch) {
      return timeOperation(async () => {
        const formattedUrl = `${groupSearchesUrl}/${Number(groupSearch?.RootId ?? 0)}/updateaccesssettings`;
        const updatedGroupSearch = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupSearch });
        context.commit("_updateGroupSearch", {
          GroupRootId: Number(updatedGroupSearch?.GroupRootId ?? 0),
          ProductRootId: Number(updatedGroupSearch?.ProductRootId ?? 0),
          SearchRootId: Number(updatedGroupSearch?.SearchRootId ?? 0),
          CanCopy: updatedGroupSearch?.CanCopy ?? false,
          CanClose: updatedGroupSearch?.CanClose ?? false,
          CanShare: updatedGroupSearch?.CanShare ?? false,
        });
        return updatedGroupSearch;
      }, "organizations - updateGroupSearchAccessSettings");
    },
    async updateGroupFormLayoutEnabledStatus(context, groupFormLayout) {
      await timeOperation(async () => {
        const formattedUrl = `${groupFormLayoutsUrl}/${Number(groupFormLayout?.RootId ?? 0)}/updateenabledstatus`;
        const updatedGroupFormLayout = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupFormLayout });
        context.commit("_updateOrganizationGroupFormLayoutData", {
          GroupRootId: updatedGroupFormLayout.GroupRootId,
          ProductRootId: updatedGroupFormLayout.ProductRootId,
          OrganizationRootId: updatedGroupFormLayout.OrganizationRootId,
          FormLayoutRootId: updatedGroupFormLayout.FormLayoutRootId,
          Enabled: updatedGroupFormLayout.Enabled ?? false,
        });
      }, "organizations - updateGroupFormLayoutEnabledStatus");
    },
    async updateGroupFormLayoutSelectedStatus(context, groupFormLayout) {
      await timeOperation(async () => {
        const formattedUrl = `${groupFormLayoutsUrl}/${Number(groupFormLayout?.RootId ?? 0)}/updateselectedstatus`;
        const updatedGroupFormLayout = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupFormLayout });
        context.commit("_updateOrganizationGroupFormLayoutData", {
          GroupRootId: updatedGroupFormLayout.GroupRootId,
          ProductRootId: updatedGroupFormLayout.ProductRootId,
          OrganizationRootId: updatedGroupFormLayout.OrganizationRootId,
          FormLayoutRootId: updatedGroupFormLayout.FormLayoutRootId,
          Selected: updatedGroupFormLayout.Selected ?? false,
        });
      }, "organizations - updateGroupFormLayoutSelectedStatus");
    },
  },
};
