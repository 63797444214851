import { ApiRequest, GetVerb, mimeTypesUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    extensions: {},
  },
  getters: {},
  mutations: {
    setMimeTypeData(state, mimeTypesData) {
      state.extensions = mimeTypesData?.extensions ?? {};
    },
  },
  actions: {
    async getMimeTypesData(context) {
      await timeOperation(async () => {
        // Only get if the cache is empty. These values do not change dynamically.
        if (!context.state.extensions.hasOwnProperty("pdf")) {
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: mimeTypesUrl, Payload: null });
          context.commit("setMimeTypeData", data);
        }
      }, "mimeTypes - getMimeTypesData");
    },
  },
};
