import {ApiRequest, DeleteVerb, GetVerb, PostVerb, PutVerb, workflowTasksUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { WorkflowTasksRecordType } from "@/lib/settings";
import { getGridData } from "@/store/searchResults.js";

export default {
  actions: {
    async getWorkflowTasksAdvancedSearchData(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        SearchRootId,
        PreviewParameters,
        IncludeMetadata,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        NoCache,
        CanUseServerCache
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeDataSourceFields = false;//IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? false;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? false;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${workflowTasksUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&searchrootid=${SearchRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&excludeblobs=${ExcludeBlobs
          }&includedatasourcefields=${IncludeDataSourceFields
          }&includefieldsettings=${IncludeFieldSettings
          }&includefieldnameregistry=${IncludeFieldNameRegistry
          }&includenamefieldregistry=${IncludeNameFieldRegistry
          }&includegridcolumns=${IncludeGridColumns
          }&includegroupcolumns=${IncludeGroupColumns
          }&includedebugdata=${IncludeDebugData
          }&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: WorkflowTasksRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: WorkflowTasksRecordType, Data });
      }, "workflowTasks - getWorkflowTasksAdvancedSearchData");
    },
    async addWorkflowTask(context, workflowTask) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: workflowTasksUrl, Payload: workflowTask});
      }, "workflowTasks - addWorkflowTask");
    },
    async updateWorkflowTask(context, workflowTask) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTasksUrl}/${Number(workflowTask?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowTask});
      }, "workflowTasks - updateWorkflowTask");
    },
    async removeWorkflowTask(context, workflowTask) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTasksUrl}/${Number(workflowTask?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
      }, "workflowTasks - removeWorkflowTask");
    },
    async removeWorkflowTasks(context, workflowTasks) {
      return timeOperation(async () => {
        if (workflowTasks?.length > 0) {
          let scrubbedWorkflowTasks = [];
          workflowTasks.forEach((workflowTask) => {
            if (workflowTask?.RootId > 0) {
              scrubbedWorkflowTasks.push({
                RootId: Number(workflowTask.WorkflowTaskRootId > 0 ? workflowTask.WorkflowTaskRootId : workflowTask.RootId),
                OrderNumber: String(workflowTask.OrderNumber),
                ProductRootId: Number(workflowTask.ProductRootId ?? 0),
              });
            }
          });
          const formattedUrl = `${workflowTasksUrl}/deletemultiple`;
          return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: formattedUrl, Payload: scrubbedWorkflowTasks});
        }
      }, "workflowTasks - removeWorkflowTasks");
    },
  },
};
