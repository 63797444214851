import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, linksQueuedAttachmentsUrl, reportsUrl } from "@/lib/api";
import { BranchDocumentsReport, DocumentsReport } from "@/lib/settings";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { removeBaseRootId } from "@/utils/getFilteredDataSources";

export default {
  state: {
    linksQueuedAttachments: [],
    linksQueuedAttachmentReportData: [],
    linksQueuedAttachmentsOrderNumbersAndDocumentTypes: [],
    selectedForTaggingLinksQueuedAttachmentRootId: 0,
  },
  getters: {
    getLinksQueuedAttachmentByRootId: (state) => (rootId) => {
      return state.linksQueuedAttachments.find((linksQueuedAttachment) => Number(linksQueuedAttachment?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getCopyOfLinksQueuedAttachments: (state) => {
      return _.cloneDeep(state.linksQueuedAttachments) ?? [];
    },
  },
  mutations: {
    addToLinksQueuedAttachmentsOrderNumbersAndDocumentTypes(state, linksQueuedAttachment) {
      let found = false;
      let documentIndex = 0;
      for (let [currentIndex, currentDocument] of state.linksQueuedAttachmentsOrderNumbersAndDocumentTypes.entries()) {
        if (String(currentDocument.FileName) === String(linksQueuedAttachment.FileName)) {
          documentIndex = currentIndex;
          found = true;
          break;
        }
      }
      if (found) {
        state.linksQueuedAttachmentsOrderNumbersAndDocumentTypes.splice(documentIndex, 1, linksQueuedAttachment);
      } else {
        state.linksQueuedAttachmentsOrderNumbersAndDocumentTypes.push(linksQueuedAttachment);
      }
    },
    clearLinksQueuedAttachmentQuickSearchOrderNumbersAndDocumentTypes(state) {
      state.linksQueuedAttachmentsOrderNumbersAndDocumentTypes = [];
    },
    setSelectedForTaggingLinksQueuedAttachmentRootId(state, rootId) {
      state.selectedForTaggingLinksQueuedAttachmentRootId = rootId;
    },
    setLinksQueuedAttachmentData(state, linksQueuedAttachmentData) {
      state.linksQueuedAttachments = linksQueuedAttachmentData ?? [];
    },
    setLinksQueuedAttachmentReportData(state, linksQueuedAttachmentReportData) {
      state.linksQueuedAttachmentReportData = linksQueuedAttachmentReportData ?? [];
    },
    _updateLinksQueuedAttachment(state, updateLinksQueuedAttachment) {
      const rootId = Number(updateLinksQueuedAttachment?.RootId ?? 0);
      if (rootId > 0) {
        let linksQueuedAttachmentsData = _.cloneDeep(state.linksQueuedAttachments) ?? [];
        linksQueuedAttachmentsData =
          [...linksQueuedAttachmentsData.filter((linksQueuedAttachment) => Number(linksQueuedAttachment?.RootId ?? 0) !== rootId), _.cloneDeep(updateLinksQueuedAttachment)].sort(
            (att1, att2) => (att1.Name > att2.Name ? 1 : -1)
          ) ?? [];
        state.linksQueuedAttachments = linksQueuedAttachmentsData;
      }
    },
    _updateLinksQueuedAttachments(state, updateLinksQueuedAttachments) {
      if (updateLinksQueuedAttachments?.length > 0) {
        let linksQueuedAttachmentsData = _.cloneDeep(state.linksQueuedAttachments) ?? [];
        updateLinksQueuedAttachments.forEach((updateLinksQueuedAttachment) => {
          const rootId = Number(updateLinksQueuedAttachment?.RootId ?? 0);
          if (rootId > 0) {
            linksQueuedAttachmentsData = [
              ...linksQueuedAttachmentsData.filter((linksQueuedAttachment) => Number(linksQueuedAttachment?.RootId ?? 0) !== rootId),
              _.cloneDeep(updateLinksQueuedAttachment),
            ];
          }
        });
        state.linksQueuedAttachments = linksQueuedAttachmentsData.sort((att1, att2) => (att1.Name > att2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeLinksQueuedAttachment(state, deleteLinksQueuedAttachment) {
      const rootId = Number(deleteLinksQueuedAttachment?.RootId ?? 0);
      if (rootId > 0) {
        let linksQueuedAttachmentsData = _.cloneDeep(state.linksQueuedAttachments) ?? [];
        linksQueuedAttachmentsData =
          [...linksQueuedAttachmentsData.filter((linksQueuedAttachment) => Number(linksQueuedAttachment?.RootId ?? 0) !== rootId)].sort((att1, att2) =>
            att1.Name > att2.Name ? 1 : -1
          ) ?? [];
        state.linksQueuedAttachments = linksQueuedAttachmentsData;
      }
    },
  },
  actions: {
    async getSingleLinksQueuedAttachmentData(
      context,
      {
        RootId,
        UserRootId,
        ProductRootId,
        SearchRootId,
        ContentDispositionPrefix,
        ReturnOnlyOrderNumbers,
        IncludeMetadata,
        IncludeDocumentTypeFields,
        IncludePresignedUrl,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        NoCache,
        CanUseServerCache,
      }
    ) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        if (RootId > 0) {
          UserRootId = Number(UserRootId ?? 0);
          ProductRootId = Number(ProductRootId ?? 0);
          SearchRootId = removeBaseRootId(SearchRootId ?? 0);
          ContentDispositionPrefix = String(ContentDispositionPrefix ?? "");
          ReturnOnlyOrderNumbers = ReturnOnlyOrderNumbers ?? false;
          IncludeMetadata = IncludeMetadata ?? true;
          IncludeDocumentTypeFields = IncludeDocumentTypeFields ?? false;
          IncludePresignedUrl = IncludePresignedUrl ?? false;
          ExcludeBlobs = ExcludeBlobs ?? false;
          IncludeDataSourceFields = false;//IncludeDataSourceFields ?? true;
          IncludeFieldSettings = IncludeFieldSettings ?? true;
          IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? false;
          IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? false;
          IncludeGridColumns = IncludeGridColumns ?? true;
          IncludeGroupColumns = IncludeGroupColumns ?? true;
          IncludeDebugData = IncludeDebugData ?? false;
          NoCache = NoCache ?? false;
          CanUseServerCache = CanUseServerCache ?? true;
          const formattedUrl = `${linksQueuedAttachmentsUrl
            }/${RootId
            }?userrootid=${UserRootId
            }&productrootid=${ProductRootId
            }&searchrootid=${SearchRootId
            }&searchtext=&ordernumber=&returnonlyordernumbers=${ReturnOnlyOrderNumbers
            }&timezone=${encodeURIComponent(getLocalTimezone())
            }&contentdispositionprefix=${ContentDispositionPrefix
            }&includemetadata=${IncludeMetadata
            }&includedocumenttypefields=${IncludeDocumentTypeFields
            }&includepresignedurl=${IncludePresignedUrl
            }&excludeblobs=${ExcludeBlobs
            }&includedatasourcefields=${IncludeDataSourceFields
            }&includefieldsettings=${IncludeFieldSettings
            }&includefieldnameregistry=${IncludeFieldNameRegistry
            }&includenamefieldregistry=${IncludeNameFieldRegistry
            }&includegridcolumns=${IncludeGridColumns
            }&includegroupcolumns=${IncludeGroupColumns
            }&includedebugdata=${IncludeDebugData
            }&canusecache=${CanUseServerCache}`;
          const updatedLinksQueuedAttachments = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          const updatedLinksQueuedAttachment = updatedLinksQueuedAttachments?.data?.length > 0 ? updatedLinksQueuedAttachments.data[0] : null;
          if (!NoCache) {
            context.commit("_updateLinksQueuedAttachment", updatedLinksQueuedAttachment);
          }
          return updatedLinksQueuedAttachment;
        }
      }, "linksQueuedAttachments - getSingleLinksQueuedAttachmentData");
    },
    async getLinksQueuedAttachmentSimpleReportData(context) {
      return timeOperation(async () => {
        const formattedUrl = `${reportsUrl}/documents?reporttype=${DocumentsReport}`;
        const linksQueuedAttachmentReportData = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setLinksQueuedAttachmentReportData", linksQueuedAttachmentReportData);
        return linksQueuedAttachmentReportData;
      }, "linksQueuedAttachments - getLinksQueuedAttachmentSimpleReportData");
    },
    async getLinksQueuedAttachmentBranchReportData(context) {
      return timeOperation(async () => {
        const formattedUrl = `${reportsUrl}/documents?reporttype=${BranchDocumentsReport}`;
        const linksQueuedAttachmentReportData = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setLinksQueuedAttachmentReportData", linksQueuedAttachmentReportData);
        return linksQueuedAttachmentReportData;
      }, "linksQueuedAttachments - getLinksQueuedAttachmentBranchReportData");
    },
    async getRecentlyUploadedLinksQueuedAttachmentsByDirectUploadIds(context, directUploadIds) {
      return timeOperation(async () => {
        const formattedUrl = `${linksQueuedAttachmentsUrl}/recentlyuploaded?directuploadids=${directUploadIds}`;
        const linksQueuedAttachmentData = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        linksQueuedAttachmentData.linksCanGeneratePublicLinks = false;
        context.commit("setLinksQueuedAttachmentData", linksQueuedAttachmentData.data);
        return _.cloneDeep(linksQueuedAttachmentData);
      }, "linksQueuedAttachments - getRecentlyUploadedLinksQueuedAttachmentsByDirectUploadIds");
    },
    async getRecentlyUploadedLinksQueuedAttachmentsByDirectUploadIdsList(context, directUploadIds) {
      // TODO: replace the function above with this or redo the controller to not take - separated ids
      await timeOperation(async () => {
        if (!(directUploadIds?.length > 0)) {
          // console.error("no ids given");
        } else {
          try {
            const formattedIds = directUploadIds.join("-");
            const formattedUrl = `${linksQueuedAttachmentsUrl}/recentlyuploaded?directuploadids=${formattedIds}`;
            const linksQueuedAttachmentData = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
            linksQueuedAttachmentData?.forEach?.((linksQueuedAttachment) => {
              if (!linksQueuedAttachment.RecordId || linksQueuedAttachment.RecordId < 1) {
                console.error("no RecordId returned for attachment ", linksQueuedAttachment);
              }
            });
            linksQueuedAttachmentData.linksCanGeneratePublicLinks = false;
            try {
              context.commit("setLinksQueuedAttachmentData", linksQueuedAttachmentData.data);
            } catch (e) {
              console.error("search for recently uploaded queued attachments failed ", e);
            }
          } catch (e) {
            console.error("search for recently uploaded failed ", e);
          }
        }
      }, "linksQueuedAttachments - getRecentlyUploadedLinksQueuedAttachmentsByDirectUploadIdsList");
    },
    async updateLinksQueuedAttachment(context, linksQueuedAttachment) {
      await timeOperation(async () => {
        const formattedUrl = `${linksQueuedAttachmentsUrl}/${Number(linksQueuedAttachment?.RootId ?? 0)}`;
        const updatedLinksQueuedAttachment = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: linksQueuedAttachment });
        context.commit("_updateLinksQueuedAttachment", updatedLinksQueuedAttachment);
      }, "linksQueuedAttachments - updateLinksQueuedAttachment");
    },
    async saveLinksQueuedAttachmentsKeyInformation(context, linksQueuedAttachmentsMetadata) {
      await timeOperation(async () => {
        if (linksQueuedAttachmentsMetadata?.length > 0) {
          const formattedUrl = `${linksQueuedAttachmentsUrl}/savekeyinformation`;
          const updatedLinksQueuedAttachments = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: linksQueuedAttachmentsMetadata });
          context.commit("_updateLinksQueuedAttachments", updatedLinksQueuedAttachments);
          context.commit("_updateRecentUploads", updatedLinksQueuedAttachments);
        }
      }, "linksQueuedAttachments - saveLinksQueuedAttachmentsKeyInformation");
    },
    async copyLinksQueuedAttachments(context, linksQueuedAttachments) {
      await timeOperation(async () => {
        if (linksQueuedAttachments?.length > 0) {
          const formattedUrl = `${linksQueuedAttachmentsUrl}/copy`;
          const copiedLinksQueuedAttachments = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: linksQueuedAttachments });
          context.commit("_updateLinksQueuedAttachments", copiedLinksQueuedAttachments);
          context.commit("_updateRecentUploads", copiedLinksQueuedAttachments);
        }
      }, "linksQueuedAttachments - copyLinksQueuedAttachments");
    },
    async updateLinksQueuedAttachmentsKeyInformation(context, linksQueuedAttachmentsMetadata) {
      await timeOperation(async () => {
        const formattedUrl = `${linksQueuedAttachmentsUrl}/updatekeyinformation`;
        const updatedLinksQueuedAttachments = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: linksQueuedAttachmentsMetadata });
        context.commit("_updateLinksQueuedAttachments", updatedLinksQueuedAttachments);
        context.commit("_updateRecentUploads", updatedLinksQueuedAttachments);
      }, "linksQueuedAttachments - updateLinksQueuedAttachmentsKeyInformation");
    },
    async saveLinksQueuedAttachmentsMetadata(context, linksQueuedAttachments) {
      await timeOperation(async () => {
        const formattedUrl = `${linksQueuedAttachmentsUrl}/savemetadata`;
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: linksQueuedAttachments });
      }, "linksQueuedAttachments - saveLinksQueuedAttachmentsMetadata");
    },
    async updateLinksQueuedAttachmentDocumentType(context, { LinksQueuedAttachmentRootId, OrganizationDocumentTypeRootId, CustomDocumentTypeName }) {
      await timeOperation(async () => {
        LinksQueuedAttachmentRootId = Number(LinksQueuedAttachmentRootId ?? 0);
        OrganizationDocumentTypeRootId = Number(OrganizationDocumentTypeRootId ?? 0);
        CustomDocumentTypeName = encodeURIComponent(String(CustomDocumentTypeName ?? ""));
        const formattedUrl = `${linksQueuedAttachmentsUrl}/updatedocumenttype?rootid=${LinksQueuedAttachmentRootId}&organizationdocumenttyperootid=${OrganizationDocumentTypeRootId}&customdocumenttypename=${CustomDocumentTypeName}`;
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "linksQueuedAttachments - updateLinksQueuedAttachmentDocumentType");
    },
    async updateLinksQueuedAttachmentDocumentTypes(context, linksQueuedAttachments) {
      await timeOperation(async () => {
        if (linksQueuedAttachments?.length > 0) {
          const formattedUrl = `${linksQueuedAttachmentsUrl}/updatedocumenttypes`;
          const updatedLinksQueuedAttachments = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: linksQueuedAttachments });
          context.commit("_updateLinksQueuedAttachments", updatedLinksQueuedAttachments);
        }
      }, "linksQueuedAttachments - updateLinksQueuedAttachmentDocumentTypes");
    },
    async renameLinksQueuedAttachment(context, linksQueuedAttachment) {
      return timeOperation(async () => {
        const formattedUrl = `${linksQueuedAttachmentsUrl}/${Number(linksQueuedAttachment?.RootId ?? 0)}/rename`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: linksQueuedAttachment });
      }, "linksQueuedAttachments - renameLinksQueuedAttachment");
    },
    async removeLinksQueuedAttachment(context, linksQueuedAttachment) {
      await timeOperation(async () => {
        const formattedUrl = `${linksQueuedAttachmentsUrl}/${Number(linksQueuedAttachment?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeLinksQueuedAttachment", linksQueuedAttachment);
        context.commit("_removeAttachmentByLinksQueuedAttachmentRootId", linksQueuedAttachment);
        context.commit("_removeAttachmentByLinksQueuedAttachment", linksQueuedAttachment);
      }, "linksQueuedAttachments - removeLinksQueuedAttachment");
    },
    async removeLinksQueuedAttachments(context, attachments) {
      await timeOperation(async () => {
        if (attachments?.length > 0) {
          let scrubbedLinksQueuedAttachments = [];
          attachments.forEach((attachment) => {
            if (attachment?.RootId > 0) {
              scrubbedLinksQueuedAttachments.push({
                RootId: Number(attachment.LinksQueuedAttachmentRootId > 0 ? attachment.LinksQueuedAttachmentRootId : attachment.RootId),
                OrderNumber: String(attachment.OrderNumber),
                ProductRootId: Number(attachment.ProductRootId ?? 0),
              });
            }
          });
          const formattedUrl = `${linksQueuedAttachmentsUrl}/deletemultiple`;
          const removedLinksQueuedAttachments = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: scrubbedLinksQueuedAttachments });
          if (removedLinksQueuedAttachments?.length > 0)
            removedLinksQueuedAttachments.forEach((linksQueuedAttachment) => {
              context.commit("_removeLinksQueuedAttachment", linksQueuedAttachment);
              context.commit("_removeAttachmentByLinksQueuedAttachmentRootId", linksQueuedAttachment);
              context.commit("_removeAttachmentByLinksQueuedAttachment", linksQueuedAttachment);
            });
        }
      }, "linksQueuedAttachments - removeLinksQueuedAttachments");
    },
  },
};
