import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, documentTypeCategoriesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    documentTypeCategories: [],
  },
  getters: {},
  mutations: {
    setDocumentTypeCategories(state, documentTypeCategoriesData) {
      state.documentTypeCategories = documentTypeCategoriesData?.sort((dtc1, dtc2) => (dtc1.DataCategoryNumber > dtc2.DataCategoryNumber ? 1 : -1)) ?? [];
    },
    _addDocumentTypeCategory(state, documentTypeCategory) {
      const documentTypeCategoriesData = _.cloneDeep(state.documentTypeCategories) ?? [];
      documentTypeCategoriesData.push(documentTypeCategory);
      state.documentTypeCategories = documentTypeCategoriesData.sort((dtc1, dtc2) => (dtc1.DataCategoryNumber > dtc2.DataCategoryNumber ? 1 : -1)) ?? [];
    },
    _updateDocumentTypeCategory(state, updateDocumentTypeCategory) {
      const rootId = Number(updateDocumentTypeCategory?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypeCategoriesData = _.cloneDeep(state.documentTypeCategories) ?? [];
        state.documentTypeCategories = [...documentTypeCategoriesData.filter(documentTypeCategory => Number(documentTypeCategory?.RootId ?? 0) !== rootId), _.cloneDeep(updateDocumentTypeCategory)].sort((dtc1, dtc2) => (dtc1.DataCategoryNumber > dtc2.DataCategoryNumber ? 1 : -1)) ?? [];
      }
    },
    _removeDocumentTypeCategory(state, deleteDocumentTypeCategory) {
      const rootId = Number(deleteDocumentTypeCategory?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypeCategoriesData = _.cloneDeep(state.documentTypeCategories) ?? [];
        state.documentTypeCategories = [...documentTypeCategoriesData.filter(documentTypeCategory => Number(documentTypeCategory?.RootId ?? 0) !== rootId)].sort((dtc1, dtc2) => (dtc1.DataCategoryNumber > dtc2.DataCategoryNumber ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getDocumentTypeCategories(context, {
      IncludeMetadata,
    }) {
      await timeOperation(async () => {
        IncludeMetadata = IncludeMetadata ?? false;
        const formattedUrl = `${documentTypeCategoriesUrl}?includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setDocumentTypeCategories", data);
      }, "documentTypeCategories - getDocumentTypeCategories");
    },
    async addDocumentTypeCategory(context, documentTypeCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypeCategoriesUrl, Payload: documentTypeCategory });
        context.commit("_addDocumentTypeCategory", data);
      }, "documentTypeCategories - addDocumentTypeCategory");
    },
    async updateDocumentTypeCategory(context, documentTypeCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypeCategoriesUrl}/${Number(documentTypeCategory?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentTypeCategory });
        context.commit("_updateDocumentTypeCategory", data);
      }, "documentTypeCategories - updateDocumentTypeCategory");
    },
    async removeDocumentTypeCategory(context, documentTypeCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypeCategoriesUrl}/${Number(documentTypeCategory?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentTypeCategory", documentTypeCategory);
      }, "documentTypeCategories - removeDocumentTypeCategory");
    },
  },
};
