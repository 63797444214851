import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationDocumentTypeFieldTypesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDocumentTypeFieldTypes: [],
    organizationDocumentTypeFieldTypesCachedOrganizationRootId: 0,
    organizationDocumentTypeFieldTypesCachedProductRootId: 0,
  },
  getters: {
    getOrganizationDocumentTypeFieldTypeByName: (state) => (name) => {
      return state.organizationDocumentTypeFieldTypes.find((organizationDocumentTypeFieldType) => String(organizationDocumentTypeFieldType?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextOrganizationDocumentTypeFieldTypeRootId: (state) => {
      let result = 1;
      state.organizationDocumentTypeFieldTypes.forEach((organizationDocumentTypeFieldType) => {
        if (Number(organizationDocumentTypeFieldType.RootId) >= result) {
          result = Number(organizationDocumentTypeFieldType.RootId) + 1;
        }
      });
      return result;
    },
    areOrganizationDocumentTypeFieldTypesLoaded:
      (state) =>
        ({ ProductRootId, OrganizationRootId }) => {
          ProductRootId = Number(ProductRootId ?? 0);
          OrganizationRootId = Number(OrganizationRootId ?? 0);
          return (
            ProductRootId > 0 &&
            OrganizationRootId > 0 &&
            state.organizationDocumentTypeFieldTypes?.find(
              (organizationDocumentTypeFieldType) =>
                Number(organizationDocumentTypeFieldType?.ProductRootId ?? 0) === ProductRootId && Number(organizationDocumentTypeFieldType?.OrganizationRootId ?? 0) === OrganizationRootId
            ) != null
          );
        },
    isOrganizationDocumentTypeFieldTypeNameTaken: (state) => (currentOrganizationDocumentTypeFieldType) => {
      const { organizationDocumentTypeFieldTypes } = state;
      if (organizationDocumentTypeFieldTypes?.length < 1) {
        return false;
      }
      if (!currentOrganizationDocumentTypeFieldType) {
        return true;
      }
      const { Name: currentName, RootId } = currentOrganizationDocumentTypeFieldType;
      if (!(currentName?.length > 0)) {
        return true;
      }
      if (Number(RootId ?? 0) < 1) {
        return true;
      }
      return !!organizationDocumentTypeFieldTypes.find((organizationDocumentTypeFieldType) => String(organizationDocumentTypeFieldType?.Name ?? "") === String(currentName ?? "") && Number(RootId ?? 0) !== Number(organizationDocumentTypeFieldType?.RootId ?? 0));
    },
  },
  mutations: {
    setOrganizationDocumentTypeFieldTypes(state, organizationDocumentTypeFieldTypesData) {
      state.organizationDocumentTypeFieldTypes = organizationDocumentTypeFieldTypesData?.sort((odtft1, odtft2) => (odtft1.Name > odtft2.Name ? 1 : -1)) ?? [];
    },
    setOrganizationDocumentTypeFieldTypesCachedOrganizationRootId(state, organizationDocumentTypeFieldTypesCachedOrganizationRootId) {
      state.organizationDocumentTypeFieldTypesCachedOrganizationRootId = Number(organizationDocumentTypeFieldTypesCachedOrganizationRootId ?? 0);
    },
    setOrganizationDocumentTypeFieldTypesCachedProductRootId(state, organizationDocumentTypeFieldTypesCachedProductRootId) {
      state.organizationDocumentTypeFieldTypesCachedProductRootId = Number(organizationDocumentTypeFieldTypesCachedProductRootId ?? 0);
    },
    _addOrganizationDocumentTypeFieldType(state, organizationDocumentTypeFieldType) {
      const organizationDocumentTypeFieldTypesData = _.cloneDeep(state.organizationDocumentTypeFieldTypes) ?? [];
      organizationDocumentTypeFieldTypesData.push(organizationDocumentTypeFieldType);
      state.organizationDocumentTypeFieldTypes = organizationDocumentTypeFieldTypesData.sort((odtft1, odtft2) => (odtft1.Name > odtft2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationDocumentTypeFieldType(state, updateOrganizationDocumentTypeFieldType) {
      const rootId = Number(updateOrganizationDocumentTypeFieldType?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentTypeFieldTypesData = _.cloneDeep(state.organizationDocumentTypeFieldTypes) ?? [];
        state.organizationDocumentTypeFieldTypes = [...organizationDocumentTypeFieldTypesData.filter(organizationDocumentTypeFieldType => Number(organizationDocumentTypeFieldType?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationDocumentTypeFieldType)].sort((odtft1, odtft2) => (odtft1.Name > odtft2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeOrganizationDocumentTypeFieldType(state, deleteOrganizationDocumentTypeFieldType) {
      const rootId = Number(deleteOrganizationDocumentTypeFieldType?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentTypeFieldTypesData = _.cloneDeep(state.organizationDocumentTypeFieldTypes) ?? [];
        state.organizationDocumentTypeFieldTypes = [...organizationDocumentTypeFieldTypesData.filter(organizationDocumentTypeFieldType => Number(organizationDocumentTypeFieldType?.RootId ?? 0) !== rootId)].sort((odtft1, odtft2) => (odtft1.Name > odtft2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getOrganizationDocumentTypeFieldTypes(context, {
      OrganizationRootId,
      ProductRootId,
      IncludeMetadata,
      IncludeOrganizationDocumentTypes,
      IncludeOrganizationDocumentTypeCategories,
      IncludeOrganizationDocumentTypeTitles,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      IncludeOrganizationLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? false;
        IncludeOrganizationDocumentTypes = IncludeOrganizationDocumentTypes ?? false;
        IncludeOrganizationDocumentTypeCategories = IncludeOrganizationDocumentTypeCategories ?? false;
        IncludeOrganizationDocumentTypeTitles = IncludeOrganizationDocumentTypeTitles ?? false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata ?? false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata ?? false;
        IncludeOrganizationLevelMetadata = IncludeOrganizationLevelMetadata ?? false;
        UseCache = UseCache ?? false;
        if (UseCache &&
          (context.state.organizationDocumentTypeFieldTypesCachedOrganizationRootId === OrganizationRootId) &&
          (context.state.organizationDocumentTypeFieldTypesCachedProductRootId === ProductRootId)) {
            return;
        }
        const formattedUrl = `${organizationDocumentTypeFieldTypesUrl
          }?organizationrootid=${OrganizationRootId
          }&productrootid=${ProductRootId
          }&includemetadata=${IncludeMetadata
          }&includeorganizationdocumenttypes=${IncludeOrganizationDocumentTypes
          }&includeorganizationdocumenttypecategories=${IncludeOrganizationDocumentTypeCategories
          }&includeorganizationdocumenttypetitles=${IncludeOrganizationDocumentTypeTitles
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata
          }&includeorganizationlevelmetadata=${IncludeOrganizationLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setOrganizationDocumentTypeFieldTypes", data);
        context.commit("setOrganizationDocumentTypeFieldTypesCachedOrganizationRootId", OrganizationRootId);
        context.commit("setOrganizationDocumentTypeFieldTypesCachedProductRootId", ProductRootId);
        return data;
      }, "organizationDocumentTypeFieldTypes - getOrganizationDocumentTypeFieldTypes");
    },
    async addOrganizationDocumentTypeFieldTypes(context, organizationDocumentTypeFieldTypes) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeFieldTypesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTypeFieldTypes });
        context.commit("setOrganizationDocumentTypeFieldTypes", data);
      }, "organizations - addOrganizationDocumentTypeFieldType");
    },
    async addOrganizationDocumentTypeFieldType(context, organizationDocumentTypeFieldType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentTypeFieldTypesUrl, Payload: organizationDocumentTypeFieldType });
        context.commit("_addOrganizationDocumentTypeFieldType", data);
      }, "organizationDocumentTypeFieldTypes - addOrganizationDocumentTypeFieldType");
    },
    async updateOrganizationDocumentTypeFieldType(context, organizationDocumentTypeFieldType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeFieldTypesUrl}/${Number(organizationDocumentTypeFieldType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTypeFieldType });
        context.commit("_updateOrganizationDocumentTypeFieldType", data);
      }, "organizationDocumentTypeFieldTypes - updateOrganizationDocumentTypeFieldType");
    },
    async removeOrganizationDocumentTypeFieldType(context, organizationDocumentTypeFieldType) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeFieldTypesUrl}/${Number(organizationDocumentTypeFieldType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationDocumentTypeFieldType", organizationDocumentTypeFieldType);
      }, "organizationDocumentTypeFieldTypes - removeOrganizationDocumentTypeFieldType");
    },
  },
};
