import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, searchesUrl, orderSearchOperatorsUrl, organizationSearchesUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { getSearchTabsAndSearchGroupsTree } from "@/types/searchTypes.js";
import { getGridData } from "@/store/searchResults";

export default {
  state: {
    closeLastActiveOrderTab: false,
    isOnOrderTab: false,
    selectedOrderEditorRootId: 0,
    searchOperators: [],
    tempTabsToSave: {},
  },
  mutations: {
    setTempTabsToSave(state, { TempTabsToSave, ProductRootId }) {
      state.tempTabsToSave[ProductRootId] = TempTabsToSave;
    },
    setSelectedOrderEditorRootId(state, selectedOrderEditorRootId) {
      state.selectedOrderEditorRootId = selectedOrderEditorRootId;
    },
    setSearchOperatorsData(state, searchOperators) {
      state.searchOperators = searchOperators;
    },
    setCloseLastActiveOrderTab(state, closeLastActiveOrderTab) {
      state.closeLastActiveOrderTab = closeLastActiveOrderTab;
    },
  },
  actions: {
    async getSearchOperators(context) {
      if (!(context.state.searchOperators?.length > 0)) {
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: orderSearchOperatorsUrl, Payload: null });
        context.commit("setSearchOperatorsData", data);
      }
    },
    async getProductLevelSearches(
      context,
      {
        ProductRootId,
        InjectReferences,
        InjectSimpleReferences,
        IncludeStatistics,
        CanUseServerCache,
        IncludeMetadata,
        IncludeExtendedMetadata,
        ExcludeBlobs
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        InjectReferences = InjectReferences ?? true;
        InjectSimpleReferences = InjectSimpleReferences ?? false;
        IncludeStatistics = IncludeStatistics ?? true;
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeExtendedMetadata = IncludeExtendedMetadata ?? false;
        ExcludeBlobs = ExcludeBlobs ?? false;
        CanUseServerCache = CanUseServerCache ?? false;
        const formattedUrl = `${searchesUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=0&userrootid=0&isproductlevel=true&injectreferences=${InjectReferences
          }&injectsimplereferences=${InjectSimpleReferences
          }&includestatistics=${IncludeStatistics
          }&includemetadata=${IncludeMetadata
          }&includeextendedmetadata=${IncludeExtendedMetadata
          }&excludeblobs=${ExcludeBlobs
          }&canusecache=${CanUseServerCache
          }&includepossible=false&includeadminonly=false&includeshared=false&isproductlevel=true&isorganizationlevel=false&isuserlevel=false`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "searches - getProductLevelSearches");
    },
    async getOrganizationLevelSearches(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        InjectReferences,
        InjectSimpleReferences,
        IncludePossible,
        IncludeStatistics,
        IncludeShared,
        CanUseServerCache,
        IncludeMetadata,
        IncludeExtendedMetadata,
        ExcludeBlobs
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        InjectReferences = InjectReferences ?? true;
        InjectSimpleReferences = InjectSimpleReferences ?? false;
        IncludePossible = IncludePossible ?? false;
        IncludeStatistics = IncludeStatistics ?? true;
        IncludeShared = IncludeShared ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeExtendedMetadata = IncludeExtendedMetadata ?? false;
        ExcludeBlobs = ExcludeBlobs ?? false;
        CanUseServerCache = CanUseServerCache ?? false;
        const formattedUrl = `${searchesUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&isorganizationlevel=true&injectreferences=${InjectReferences
          }&injectsimplereferences=${InjectSimpleReferences
          }&includepossible=${IncludePossible
          }&includestatistics=${IncludeStatistics
          }&includeshared=${IncludeShared
          }&includemetadata=${IncludeMetadata
          }&includeextendedmetadata=${IncludeExtendedMetadata}&excludeblobs=${ExcludeBlobs}&includeadminonly=false&includeshared=false&isproductlevel=false&isorganizationlevel=true&isuserlevel=false&canusecache=${CanUseServerCache}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "searches - getOrganizationLevelSearches");
    },
    async getUserSearchesAsGroups(
      context,
      {
        ProductRootId,
        UserRootId,
        OrganizationRootId,
        ViewType,
        SearchLocationRootId,
        OrderRootId,
        InjectReferences,
        InjectSimpleReferences,
        IncludePossible,
        IncludeStatistics,
        IncludeMetadata,
        IncludeExtendedMetadata,
        ExcludeBlobs,
        IncludeShared,
        IncludeDocumentPlaceOrderTabs,
        IsLogLevel,
        IsProductLevel,
        IsOrganizationLevel,
        IsUserLevel,
        SearchGroupData,
        CanUseServerCache,
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ViewType = String(ViewType ?? "");
        SearchLocationRootId = Number(SearchLocationRootId ?? 0);
        OrderRootId = Number(OrderRootId ?? 0);
        InjectReferences = InjectReferences ?? false;
        InjectSimpleReferences = InjectSimpleReferences ?? true;
        IncludePossible = IncludePossible ?? false;
        IncludeStatistics = IncludeStatistics ?? true;
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeExtendedMetadata = IncludeExtendedMetadata ?? false;
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeShared = IncludeShared ?? false;
        IncludeDocumentPlaceOrderTabs = IncludeDocumentPlaceOrderTabs ?? false;
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        const formattedUrl = `${searchesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&orderrootid=${OrderRootId}&type=${ViewType}&searchlocationrootid=${SearchLocationRootId}&injectreferences=${InjectReferences}&injectsimplereferences=${InjectSimpleReferences}&includepossible=${IncludePossible}&includestatistics=${IncludeStatistics}&includemetadata=${IncludeMetadata}&includeextendedmetadata=${IncludeExtendedMetadata}&excludeblobs=${ExcludeBlobs}&includeadminonly=false&includeshared=${IncludeShared}&includedocumentplaceordertabs=${IncludeDocumentPlaceOrderTabs}&isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&canusecache=${CanUseServerCache}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (!data) {
          return [];
        }
        const rawSearches = getSearchTabsAndSearchGroupsTree(data, SearchGroupData);
        return { searches: rawSearches, flatSearches: data };
      }, "searches - getUserSearchesAsGroups");
    },
    async getLogLevelSearches(context, {
      InjectReferences,
      InjectSimpleReferences,
      IncludeStatistics,
      CanUseServerCache,
      IncludeMetadata,
      IncludeExtendedMetadata,
      ExcludeBlobs
    }) {
      return timeOperation(async () => {
        InjectReferences = InjectReferences ?? true;
        InjectSimpleReferences = InjectSimpleReferences ?? false;
        IncludeStatistics = IncludeStatistics ?? true;
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeExtendedMetadata = IncludeExtendedMetadata ?? false;
        ExcludeBlobs = ExcludeBlobs ?? false;
        CanUseServerCache = CanUseServerCache ?? false;
        const formattedUrl = `${searchesUrl}?productrootid=0&organizationrootid=0&userrootid=0&injectreferences=${InjectReferences}&includestatistics=${IncludeStatistics}&includemetadata=${IncludeMetadata}&injectsimplereferences=${InjectSimpleReferences}&includeextendedmetadata=${IncludeExtendedMetadata}&excludeblobs=${ExcludeBlobs}&includeadminonly=true&includeshared=false&isloglevel=true&canusecache=${CanUseServerCache}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "searches - getLogLevelSearches");
    },
    async getSingleSearchData(
      context,
      {
        UserRootId,
        OrganizationRootId,
        SearchRootId,
        IncludeStatistics,
        IncludeMetadata,
        IncludeExtendedMetadata,
        ExcludeBlobs,
        IsLogLevel,
        IsProductLevel,
        IsOrganizationLevel,
        IsUserLevel,
        CanUseServerCache,
      }
    ) {
      UserRootId = Number(UserRootId ?? 0);
      OrganizationRootId = Number(OrganizationRootId ?? 0);
      SearchRootId = Number(SearchRootId ?? 0);
      IncludeStatistics = IncludeStatistics ?? true;
      IncludeMetadata = IncludeMetadata ?? true;
      IncludeExtendedMetadata = IncludeExtendedMetadata ?? false;
      ExcludeBlobs = ExcludeBlobs ?? false;
      IsLogLevel = IsLogLevel ?? false;
      IsProductLevel = IsProductLevel ?? false;
      IsOrganizationLevel = IsOrganizationLevel ?? false;
      IsUserLevel = IsUserLevel ?? false;
      CanUseServerCache = CanUseServerCache ?? true;
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${SearchRootId}?organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&includestatistics=${IncludeStatistics}&includemetadata=${IncludeMetadata}&includeextendedmetadata=${IncludeExtendedMetadata}&excludeblobs=${ExcludeBlobs}&isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&canusecache=${CanUseServerCache}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "searches - getSingleSearchData");
    },
    async getSearchTemplate(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        ProductDataSourceRootId,
        OrganizationDataSourceRootId,
        AdditionalProductDataSourceRootIds,
        AdditionalOrganizationDataSourceRootIds,
        IsLogLevel,
        IsProductLevel,
        IsOrganizationLevel,
        IsUserLevel,
        CanUseServerCache,
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductDataSourceRootId = Number(ProductDataSourceRootId ?? 0);
        OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        const formattedAdditionalProductDataSourceRootIds = AdditionalProductDataSourceRootIds?.length > 0 ? AdditionalProductDataSourceRootIds.join("-") : "";
        const formattedAdditionalOrganizationDataSourceRootIds = AdditionalOrganizationDataSourceRootIds?.length > 0 ? AdditionalOrganizationDataSourceRootIds.join("-") : "";
        const formattedUrl = `${searchesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&productdatasourcerootid=${ProductDataSourceRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&additionalproductdatasourcerootids=${formattedAdditionalProductDataSourceRootIds}&additionalorganizationdatasourcerootids=${formattedAdditionalOrganizationDataSourceRootIds}&isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&template=true&injectreferences=true&canusecache=${CanUseServerCache}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "searches - getSearchTemplate");
    },
    async getAdvancedSearchData(
      context,
      {
        SearchRootId,
        SearchLocationRootId,
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        OrderRootId,
        DocumentPlaceOrderRootId,
        SearchText,
        ViewType,
        IncludeMetadata,
        IncludeExtendedMetadata,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        NoCache,
        CanUseServerCache,
      }
    ) {
      return timeOperation(async () => {
        SearchRootId = Number(SearchRootId ?? 0);
        SearchLocationRootId = Number(SearchLocationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrderRootId = Number(OrderRootId ?? 0);
        DocumentPlaceOrderRootId = Number(DocumentPlaceOrderRootId ?? 0);
        SearchText = String(SearchText ?? "");
        ViewType = String(ViewType ?? "");
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeExtendedMetadata = IncludeExtendedMetadata ?? false;
        ExcludeBlobs = ExcludeBlobs ?? false;
        IncludeDataSourceFields = false;//IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? false;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? false;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        const formattedUrl = `${searchesUrl}?searchrootid=${SearchRootId}&searchlocationrootid=${SearchLocationRootId}&productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&orderrootid=${OrderRootId}&documentplaceorderrootid=${DocumentPlaceOrderRootId}&searchtext=${SearchText}&datasourcetype=${ViewType}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&canusecache=${CanUseServerCache}&includemetadata=${IncludeMetadata}&includeextendedmetadata=${IncludeExtendedMetadata
        }&excludeblobs=${ExcludeBlobs
        }&includedatasourcefields=${IncludeDataSourceFields
        }&includefieldsettings=${IncludeFieldSettings
        }&includefieldnameregistry=${IncludeFieldNameRegistry
        }&includenamefieldregistry=${IncludeNameFieldRegistry
        }&includegridcolumns=${IncludeGridColumns
        }&includegroupcolumns=${IncludeGroupColumns
        }&includedebugdata=${IncludeDebugData}&datasearch=true`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType, Data });
      }, "searches - getAdvancedSearchData");
    },
    async getSearchPreviewData(
      context,
      {
        Search,
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        OrderRootId,
        SearchText,
        PreviewParameters,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        IsLogLevel,
        IsProductLevel,
        IsOrganizationLevel,
        IsUserLevel,
        ViewType,
        CanUseServerCache,
      }
    ) {
      if (Search?.IsSearchGroup) {
        console.log("cannot clone search group with this function");
        return null;
      }
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrderRootId = Number(OrderRootId ?? 0);
        SearchText = String(SearchText ?? "");
        IncludeDataSourceFields = false;//IncludeDataSourceFields ?? true;
        IncludeFieldSettings = IncludeFieldSettings ?? true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry ?? false;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry ?? false;
        IncludeGridColumns = IncludeGridColumns ?? true;
        IncludeGroupColumns = IncludeGroupColumns ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        CanUseServerCache = CanUseServerCache ?? true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${searchesUrl}/preview?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&orderrootid=${OrderRootId}&searchtext=${SearchText}&previewparameters=${previewParametersJson}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )
        }&includedatasourcefields=${IncludeDataSourceFields
        }&includefieldsettings=${IncludeFieldSettings
        }&includefieldnameregistry=${IncludeFieldNameRegistry
        }&includenamefieldregistry=${IncludeNameFieldRegistry
        }&includegridcolumns=${IncludeGridColumns
        }&includegroupcolumns=${IncludeGroupColumns
        }&includedebugdata=${IncludeDebugData}&isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&datasearch=true&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: Search }));
        context.commit("setSearchResultsBasicData", { ViewType, Data: Data.relatedData });
        return Data;
      }, "searches - getSearchPreviewData");
    },
    async addSearch(context, { Search, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      if (Search.IsSearchGroup) {
        console.log("cannot clone search group with this function");
        return null;
      }
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${searchesUrl}?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - addSearch");
    },
    async updateSearch(context, { Search, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      if (Search.IsSearchGroup) {
        console.log("cannot update search group with this function");
        return null;
      }
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(
          Search?.RootId ?? 0
        )}?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - updateSearch");
    },
    async cloneSearch(context, { Search, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      if (Search.IsSearchGroup) {
        console.log("cannot clone search group with this function");
        return null;
      }
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${searchesUrl}/${Number(
          Search?.RootId ?? 0
        )}/clone?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - cloneSearch");
    },
    async exportSearch(context, { SearchRootId, ProductRootId, OrganizationRootId, UserRootId, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, CanUseServerCache }) {
      return timeOperation(async () => {
        SearchRootId = Number(SearchRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        CanUseServerCache = CanUseServerCache ?? false;
        const formattedUrl = `${searchesUrl}/${SearchRootId}/export?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&canusecache=${CanUseServerCache}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "searches - exportSearch");
    },
    async importSearch(context, { ProductRootId, OrganizationRootId, UserRootId, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, UpdateExisting, FileContents }) {
      if (!(FileContents?.length > 0)) {
        console.log("invalid import data found");
        return null;
      }
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        UpdateExisting = UpdateExisting ?? false;
        let encodedData = { Base64Encoded: btoa(FileContents) };
        const formattedUrl = `${searchesUrl}/import?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&updateexisting=${UpdateExisting}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: encodedData });
      }, "searches - importSearch");
    },
    async renameSearch(context, search) {
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(search?.RootId ?? 0)}/rename`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: search });
      }, "searches - renameSearch");
    },
    async enableProductSearch(context, search) {
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(search?.RootId ?? 0)}/updateproductsearchenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...search, Enabled: true } });
      }, "searches - enableProductSearch");
    },
    async disableProductSearch(context, search) {
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(search?.RootId ?? 0)}/updateproductsearchenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...search, Enabled: false } });
      }, "searches - disableProductSearch");
    },
    async enableOrganizationSearch(context, search) {
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(search?.RootId ?? 0)}/updateorganizationsearchenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...search, Enabled: true } });
      }, "searches - enableOrganizationSearch");
    },
    async disableOrganizationSearch(context, search) {
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(search?.RootId ?? 0)}/updateorganizationsearchenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...search, Enabled: false } });
      }, "searches - disableOrganizationSearch");
    },
    async updateOrganizationSearchAlias(context, search) {
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(search?.RootId ?? 0)}/updateorganizationsearchalias`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: search });
      }, "searches - updateOrganizationSearchAlias");
    },
    async updateOrganizationSearchAccessSettings(context, organizationSearch) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationSearchesUrl}/${Number(organizationSearch?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationSearch });
      }, "organizations - updateOrganizationSearchAccessSettings");
    },
    async updateSearchAccessSettings(context, search) {
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(search?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: search });
      }, "organizations - updateSearchAccessSettings");
    },

    /*async updateAllSearchColumnFieldStatusSettings(context, { IsViewable, ShowColumn, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, Search }) {
      return timeOperation(async () => {
        IsViewable = IsViewable ?? false;
        ShowColumn = ShowColumn ?? false;
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const SearchRootId = Number(Search?.RootId ?? 0);
        const formattedUrl = `${searchesUrl}/${SearchRootId}/updateallenabledandselected?isviewable=${IsViewable}&showcolumn=${ShowColumn}&isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - updateAllSearchColumnFieldStatusSettings");
    },*/

    async updateSearchColumnFieldSettings(context, { IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, Search }) {
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const SearchRootId = Number(Search?.RootId ?? 0);
        const formattedUrl = `${searchesUrl}/${SearchRootId}/updatecolumnfieldsettings?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - updateSearchColumnFieldSettings");
    },

    async updateSearchSortColumnFieldSettings(context, { IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, Search }) {
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const SearchRootId = Number(Search?.RootId ?? 0);
        const formattedUrl = `${searchesUrl}/${SearchRootId}/updatesortcolumnfieldsettings?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - updateSearchSortColumnFieldSettings");
    },

    async updateSearchPageSizeFieldSettings(context, { IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, Search }) {
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const SearchRootId = Number(Search?.RootId ?? 0);
        const formattedUrl = `${searchesUrl}/${SearchRootId}/updatepagesizefieldsettings?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - updateSearchPageSizeFieldSettings");
    },

    async updateSearchSearchGroup(context, { IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, Search }) {
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const SearchRootId = Number(Search?.RootId ?? 0);
        const formattedUrl = `${searchesUrl}/${SearchRootId}/updatesearchgroup?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - updateSearchSearchGroup");
    },

    async resetSearchFieldSettings(context, { IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, Search }) {
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const SearchRootId = Number(Search?.RootId ?? 0);
        const formattedUrl = `${searchesUrl}/${SearchRootId}/resetfieldsettings?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - resetSearchFieldSettings");
    },

    async getSearchFieldSettings(context, { IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, Search }) {
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel ?? false;
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const SearchRootId = Number(Search?.RootId ?? 0);
        const formattedUrl = `${searchesUrl}/${SearchRootId}/fieldsettings?isloglevel=${IsLogLevel}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Search });
      }, "searches - getSearchFieldSettings");
    },

    async removeSearch(context, search) {
      return timeOperation(async () => {
        const formattedUrl = `${searchesUrl}/${Number(search?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "searches - removeSearch");
    },
  },
};
