import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationPartyRolesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationPartyRoles: [],
  },
  getters: {},
  mutations: {
    setOrganizationPartyRoleData(state, organizationPartyRolesData) {
      state.organizationPartyRoles = organizationPartyRolesData?.sort((organizationPartyRole1, organizationPartyRole2) => (organizationPartyRole1.Name > organizationPartyRole2.Name ? 1 : -1)) ?? [];
    },
    clearOrganizationPartyRoles(state) {
      state.organizationPartyRoles = [];
    },
    _addOrganizationPartyRole(state, organizationPartyRole) {
      const organizationPartyRolesData = _.cloneDeep(state.organizationPartyRoles) ?? [];
      organizationPartyRolesData.push(organizationPartyRole);
      state.organizationPartyRoles = organizationPartyRolesData.sort((organizationPartyRole1, organizationPartyRole2) => (organizationPartyRole1.Name > organizationPartyRole2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationPartyRole(state, updateOrganizationPartyRole) {
      const rootId = Number(updateOrganizationPartyRole?.RootId ?? 0);
      if (rootId > 0) {
        let organizationPartyRolesData = _.cloneDeep(state.organizationPartyRoles) ?? [];
        organizationPartyRolesData =
          [...organizationPartyRolesData.filter((organizationPartyRole) => Number(organizationPartyRole?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationPartyRole)].sort((organizationPartyRole1, organizationPartyRole2) =>
            organizationPartyRole1.Name > organizationPartyRole2.Name ? 1 : -1
          ) ?? [];
        state.organizationPartyRoles = organizationPartyRolesData;
      }
    },
    _removeOrganizationPartyRole(state, deleteOrganizationPartyRole) {
      const rootId = Number(deleteOrganizationPartyRole?.RootId ?? 0);
      if (rootId > 0) {
        let organizationPartyRolesData = _.cloneDeep(state.organizationPartyRoles) ?? [];
        organizationPartyRolesData =
          [...organizationPartyRolesData.filter((organizationPartyRole) => Number(organizationPartyRole?.RootId ?? 0) !== rootId)].sort((organizationPartyRole1, organizationPartyRole2) => (organizationPartyRole1.Name > organizationPartyRole2.Name ? 1 : -1)) ?? [];
        state.organizationPartyRoles = organizationPartyRolesData;
      }
    },
  },
  actions: {
    async getOrganizationPartyRolesData(context, { OrganizationRootId, OrganizationPartyRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        OrganizationPartyRootId = Number(OrganizationPartyRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${organizationPartyRolesUrl}?organizationrootid=${OrganizationRootId}&organizationpartyrootid=${OrganizationPartyRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationPartyRoleData", data);
      }, "organizationPartyRoles - getOrganizationPartyRolesData");
    },
    async addOrganizationPartyRole(context, organizationPartyRole) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationPartyRolesUrl, Payload: organizationPartyRole });
        context.commit("_addOrganizationPartyRole", data);
        return data;
      }, "organizationPartyRoles - addOrganizationPartyRole");
    },
    async updateOrganizationPartyRole(context, organizationPartyRole) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationPartyRolesUrl}/${Number(organizationPartyRole?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationPartyRole });
        context.commit("_updateOrganizationPartyRole", data);
      }, "organizationPartyRoles - updateOrganizationPartyRole");
    },
    async removeOrganizationPartyRole(context, organizationPartyRole) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationPartyRolesUrl}/${Number(organizationPartyRole?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationPartyRole", organizationPartyRole);
      }, "organizationPartyRoles - removeOrganizationPartyRole");
    },
  },
};
