import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, documentTypesUrl, documentTypeCategoriesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    documentTypes: [],
    selectedAdminDocumentType: {},
  },
  getters: {
    getDocumentTypeByRootId: (state) => (rootId) => {
      return state.documentTypes.find((documentType) => Number(documentType?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getDocumentTypeByName: (state) => (name) => {
      return state.documentTypes.find((documentType) => String(documentType?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextDocumentTypeRootId: (state) => {
      let result = 1;
      state.documentTypes.forEach((documentType) => {
        if (Number(documentType.RootId) >= result) {
          result = Number(documentType.RootId) + 1;
        }
      });
      return result;
    },
    areDocumentTypesLoaded: (state) => () => {
      return state.documentTypes?.length > 0;
    },
    isDocumentTypeNameTaken:
      (state) =>
      (currentDocumentType, isNew = false) => {
        const { DocumentTypes } = state;
        if (DocumentTypes?.length < 1) {
          return false;
        }
        if (!currentDocumentType) {
          return true;
        }
        const { Name: currentName, RootId } = currentDocumentType;
        if (!(currentName?.length > 0)) {
          return false;
        }
        if (Number(RootId ?? 0) < 1 && !isNew) {
          return true;
        }
        return !!state.documentTypes.find(
          (documentType) => String(documentType?.Name ?? "") === String(currentName ?? "") && Number(RootId ?? 0) !== Number(documentType?.RootId ?? 0)
        );
      },
  },
  mutations: {
    setSelectedAdminDocumentType(state, newSelectedAdminDocumentType) {
      state.selectedAdminDocumentType = _.cloneDeep(newSelectedAdminDocumentType);
    },
    setCachedDocumentTypesProductRootId(state, cachedDocumentTypesProductRootId) {
      state.cachedDocumentTypesProductRootId = Number(cachedDocumentTypesProductRootId ?? -1);
    },
    setCachedDocumentTypesOrganizationRootId(state, cachedDocumentTypesOrganizationRootId) {
      state.cachedDocumentTypesOrganizationRootId = Number(cachedDocumentTypesOrganizationRootId ?? -1);
    },
    setDocumentTypes(state, documentTypesData) {
      state.documentTypes = documentTypesData?.sort((dt1, dt2) => (dt1.Name > dt2.Name ? 1 : -1)) ?? [];
    },
    _addDocumentType(state, documentType) {
      const documentTypesData = _.cloneDeep(state.documentTypes) ?? [];
      documentTypesData.push(documentType);
      state.documentTypes = documentTypesData.sort((dt1, dt2) => (dt1.Name > dt2.Name ? 1 : -1)) ?? [];
    },
    _updateDocumentType(state, updateDocumentType) {
      const rootId = Number(updateDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypesData = _.cloneDeep(state.documentTypes) ?? [];
        state.documentTypes =
          [...documentTypesData.filter((documentType) => Number(documentType?.RootId ?? 0) !== rootId), _.cloneDeep(updateDocumentType)].sort((dt1, dt2) =>
            dt1.Name > dt2.Name ? 1 : -1
          ) ?? [];
      }
    },
    _removeDocumentType(state, deleteDocumentType) {
      const rootId = Number(deleteDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypesData = _.cloneDeep(state.documentTypes) ?? [];
        state.documentTypes =
          [...documentTypesData.filter((documentType) => Number(documentType?.RootId ?? 0) !== rootId)].sort((dt1, dt2) => (dt1.Name > dt2.Name ? 1 : -1)) ?? [];
      }
    },
    _updatedDocumentTypeCategories(state, { DocumentTypeRootId, DocumentTypeCategories }) {
      const documentTypeRootId = Number(DocumentTypeRootId ?? 0);
      const documentTypeCategories = DocumentTypeCategories ?? [];
      if (documentTypeRootId > 0 && state.selectedAdminDocumentType?.RootId > 0 && documentTypeRootId === Number(state.selectedAdminDocumentType.RootId)) {
        state.selectedAdminDocumentType.DocumentTypeCategories = _.cloneDeep(documentTypeCategories ?? []).sort((dtc1, dtc2) => (dtc1.Name > dtc2.Name ? 1 : -1)) ?? [];
        let updateDocumentType = state.documentTypes.find((documentType) => Number(documentType?.RootId ?? 0) === documentTypeRootId) ?? null;
        if (updateDocumentType?.RootId > 0) {
          updateDocumentType.DocumentTypeCategories = _.cloneDeep(documentTypeCategories ?? []).sort((dtc1, dtc2) => (dtc1.Name > dtc2.Name ? 1 : -1)) ?? [];
          let documentTypesData = _.cloneDeep(state.documentTypes) ?? [];
          state.documentTypes =
            [...documentTypesData.filter((documentType) => Number(documentType?.RootId ?? 0) !== documentTypeRootId), _.cloneDeep(updateDocumentType)].sort((dtt1, dtt2) =>
              dtt1.Name > dtt2.Name ? 1 : -1
            ) ?? [];
        }
      }
    },
  },
  actions: {
    async getDocumentTypes(
      context,
      { UseCache, ProductRootId, OrganizationRootId, IncludeDocumentTypeFields, IncludeMetadata, IncludeDocumentTypeCategories, IncludeDocumentTypeTitles }
    ) {
      await timeOperation(async () => {
        const useCache = UseCache ?? false;
        const productRootId = Number(ProductRootId ?? 0);
        const organizationRootId = Number(OrganizationRootId ?? 0);
        const cachedDocumentTypesProductRootId = Number(context.state.cachedDocumentTypesProductRootId ?? 0);
        const cachedDocumentTypesOrganizationRootId = Number(context.state.cachedDocumentTypesOrganizationRootId ?? 0);
        const dirtyCache = cachedDocumentTypesProductRootId !== productRootId || cachedDocumentTypesOrganizationRootId !== organizationRootId;
        if (!useCache || dirtyCache) {
          context.commit("setCachedDocumentTypesProductRootId", productRootId);
          context.commit("setCachedDocumentTypesOrganizationRootId", organizationRootId);
          const formattedUrl = `${documentTypesUrl}?productrootid=${Number(ProductRootId ?? 0)}&organizationrootid=${Number(OrganizationRootId ?? 0)}&includemetadata=${
            IncludeMetadata ?? false
          }&includedocumenttypefields=${IncludeDocumentTypeFields ?? false}&includedocumenttypecategories=${IncludeDocumentTypeCategories ?? false}&includedocumenttypetitles=${
            IncludeDocumentTypeTitles ?? false
          }`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setDocumentTypes", data);
        }
      }, "documentTypes - getDocumentTypes");
    },
    async addDocumentType(context, documentType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypesUrl, Payload: documentType });
        context.commit("_addDocumentType", data);
      }, "documentTypes - addDocumentType");
    },
    async updateDocumentType(context, documentType) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypesUrl}/${Number(documentType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentType });
        context.commit("_updateDocumentType", data);
      }, "documentTypes - updateDocumentType");
    },
    async removeDocumentType(context, documentType) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypesUrl}/${Number(documentType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentType", documentType);
      }, "documentTypes - removeDocumentType");
    },
    async updateDocumentTypePositions(context, documentTypes) {
      await timeOperation(async () => {
        if (documentTypes?.length > 0) {
          const formattedUrl = `${documentTypesUrl}/updatepositions`;
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: documentTypes });
          context.commit("setDocumentTypes", data);
        }
      }, "documentTypes - updateDocumentTypePositions");
    },
    async saveDocumentTypeCategories(context, { DocumentTypeRootId, DocumentTypeCategories }) {
      await timeOperation(async () => {
        const documentTypeRootId = Number(DocumentTypeRootId ?? 0);
        const documentTypeCategories = DocumentTypeCategories ?? [];
        const formattedUrl = `${documentTypeCategoriesUrl}/${documentTypeRootId}/updatecategories`;
        const updatedDocumentTypeCategories = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentTypeCategories });
        context.commit("_updatedDocumentTypeCategories", { DocumentTypeRootId: documentTypeRootId, DocumentTypeCategories: updatedDocumentTypeCategories ?? [] });
      }, "documentTypes - saveDocumentTypeCategories");
    },
  },
};
